


import { Loader } from "@googlemaps/js-api-loader";
const loader = new Loader({
apiKey: "AIzaSyDVtxjV0MyESJTI86Fk76fpuWF22g-94Cs"
,

version: "weekly",
libraries: ["places"],
});


const useGoogleLoader = () => {
return loader;
};

export default useGoogleLoader;
