import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import { localStorageRemoveItem } from '../App';
export default () => {
  const navigate = useNavigate();


  const logout = () => {
  
    localStorageRemoveItem("userData");
    localStorageRemoveItem("userToken");
    localStorageRemoveItem("totalWishlistItems");
    navigate("/login");

    
  };

  const error = (e, setLoading, showToast, retriesDone = false) => {
    console.log(e.response, "my error response");
    setLoading(false);

    let resMessage = 'An unexpected error occurred'; // Default error message
    if (e.message === 'Network Error') {
      resMessage = 'Unable to connect. Please check your internet connection and try again.';
    } else if (e.message && (e.message.includes('TIMED_OUT') || e.message.includes('ERR_TIMED_OUT'))) {
      resMessage = 'Connection timed out. Please check your internet connection and try again.';
    } else if (e.message && (e.message.includes('CONNECTION_CLOSED') || e.message.includes('ERR_CONNECTION_CLOSED'))) {
      resMessage = 'Connection was closed unexpectedly. Please check your internet connection.';
    } else if (e.response) {
      const { status, data } = e.response;
      if ([400, 401, 405, 404, 409].includes(status)) {
        resMessage = data?.message || e.message || 'An unexpected error occurred';
      } else if (status === 500) {
        resMessage = 'We are experiencing technical issues. Please try again later.';
      } 
    }

    if (e.response && e.response.status === 401) {
      logout();
      showToast({
        toastText: 'Session timeout. Please log in again.',
        isSuccess: false,
      });
    } else if (e.response && [400, 403, 404, 405, 409, 500].includes(e.response.status)) {
      setLoading(false);
      showToast({
        toastText: resMessage,
        isSuccess: false,
      });
    } else if (retriesDone) { 
      setLoading(false);
      showToast({
        toastText: resMessage,
        isSuccess: false,
      });
    }
  

 
  
};
return {
  error,
};
}
