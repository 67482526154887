import React, { useEffect } from 'react'
import GuestNavbar from '../../components/Navbar/Navbar'
import { Card } from 'react-bootstrap'
import arrowLeft from '../../assets/arrow-left.svg'
import {Link, useLocation, useNavigate} from 'react-router-dom'

const PrivacyPolicy = () => {
       
        const navigate = useNavigate();
        const location = useLocation();
        const handleBack = () => {
                navigate(-1);
              }
  return (
        <div style={{ position: 'relative' }} className='py-5 py-sm-0'> 
        <Card className=' terms-card' style={{ border: 'none', borderRadius: '0px', position: 'relative', zIndex: 1 }}>
        
        <div className='pt-5 px-3 px-md-5 mx-lg-5 ' style={{zIndex:'99'}}>
        <div className='d-block d-sm-none mb-4' >
          <div className='d-flex align-items-center gap-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
          <h3 className='my-auto' style={{fontWeight:'600',color:'#469B12'}} >PRIVACY POLICY</h3>
              
           </div>
           </div>
           <div className='d-none d-sm-flex my-3 justify-content-between align-items-center'>
        <h2 className='' style={{fontWeight:'600',color:'#469B12'}} >PRIVACY POLICY</h2>
        <Link className='' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
        </div>
           
        <div className='mb-4'>

      
        <h6 className=''  style={{fontWeight:'400'}}>Virtue's Shop <b>("we", "us", or "our")</b> is committed to protecting your privacy and the confidentiality of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your information when you visit our website, use our mobile application, or interact with us in any other way.
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Information We Collect</h3>
        <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>We collect information from you in various ways, including:
       
            <ol type='1'>
                <li className='mb-1'>Information you provide directly: This includes information you provide when you create an account, place an order, subscribe to our newsletter, or contact us. This information may include your name, email address, phone number, delivery address, billing address, payment information, and any other information you choose to share.</li>
                <li className='mb-1'>Information collected automatically: When you visit our website or use our mobile application, we may automatically collect information about your device and browsing activity. This information may include your IP address, browser type, operating system, device information, pages visited, and time spent on our website or app.</li>
                <li className='mb-1'>Information from third parties: We may collect information about you from third-party sources, such as social media platforms, data providers, and marketing partners. This information may include your name, contact information, demographics, and interests.
</li>

            </ol>
        
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >How We Use Your Information</h3>
        <h6 className=''  style={{fontWeight:'400', lineHeight:'29px'}}>We use your information for the following purposes:
       
            <ol type='1'>
                <li className='mb-2'>To fulfill your orders and provide you with the products and services you request.</li>
                <li className='mb-1'>
To personalize your experience on our website and mobile application.</li>
<li className='mb-1'>To send you marketing and promotional communications, including newsletters and offers.</li>
<li className='mb-1'>To analyse your use of our website and mobile application to improve our services.</li>

<li className='mb-1'>To comply with legal and regulatory requirements.</li>




            </ol>
        
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Sharing Your Information</h3>
        <h6 className=''  style={{fontWeight:'400'}}>We may share your information with third-party service providers who help us operate our business and provide services to you. These service providers are contractually obligated to protect your information and use it only for the purposes we have authorized. We may also share your information with third parties as required by law or to protect our rights and interests.

</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Your Choices</h3>
        <h6 className=''  style={{fontWeight:'400', lineHeight:'29px'}}>You have the following choices regarding your information:
       
            <ol type='1'>
                <li className='mb-1'>You can opt-out of receiving marketing and promotional communications from us by following the unsubscribe instructions in our emails or by contacting us.
</li>
                <li className='mb-1'>
                You can access and update your personal information by logging into your account or contacting us.</li>
<li className='mb-1'>
You can request that we delete your personal information by contacting us.</li>





            </ol>
        
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Data Security</h3>
        <h6 className=''  style={{fontWeight:'400'}}>We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no security measures are perfect, and we cannot guarantee the complete security of your information.

</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Children's Privacy</h3>
        <h6 className=''  style={{fontWeight:'400'}}>Our website and mobile application are not intended for children under the age of 18. We do not knowingly collect information from children under 18.

</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Changes to this Privacy Policy</h3>
        <h6 className=''  style={{fontWeight:'400'}}>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated Privacy Policy on our website and mobile application.

</h6>
        </div>
        <div className='mb-5  pb-5'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Contact Us</h3>
        <h6 className=''  style={{fontWeight:'400'}}>If you have any questions about this Privacy Policy, please contact us at <a className='text-dark text-decoration-underline' href='mailto:support@virtuesshop.co'>support@virtuesshop.co</a>


</h6>
        </div>
      </div>
     
      </Card>
      </div>
     
  
  )
}

export default PrivacyPolicy
