
import { createContext, useContext, useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { getParsedLocalStorageItem } from './components/Navbar/Navbar';
import axios from 'axios'
// import { trackViewContent } from './App';

import { localStorageGetItem,localStorageSetItem } from './App';


export const AppContext = createContext();

const getInitialCart = () => {
  try {
    // Retrieve the cart from local storage
    const storedCart = localStorageGetItem('cartItems');
    return storedCart ? JSON.parse(storedCart) : [];
  } catch (error) {
    console.error("Failed to fetch cart from localStorage:", error);
    return [];
  }
};

const getInitialWishlist = () => {
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!token;
 
  if(isLoggedIn){
    return [];
  }
  else{
    try {
      // Retrieve the cart from local storage
      const storedWishlist = localStorageGetItem
('wishlistItems');
      return storedWishlist ? JSON.parse(storedWishlist) : [];
    } catch (error) {
      console.error("Failed to fetch wishlist from localStorage:", error);
      return [];
    }
  }
 
};

const getInitialFormData = () => {
  const savedFormData = localStorageGetItem
('formData');
  if (savedFormData) {
    // Parse the saved data
    const parsedSavedFormData = JSON.parse(savedFormData);
    // Return a new object merging the loaded data with default values for other form fields
    return {
      streetAddress: parsedSavedFormData.streetAddress || '',
      state: parsedSavedFormData.state || '',
      LGA: parsedSavedFormData.LGA || '',
      phoneNumber: '', 
      deliveryDate: '', 
      deliveryFrequency: '', 
      isDefaultAddress: false, 
      isSchoolOrBusinessAddress: false 
    };
  }
  
  return {
    streetAddress: '',
    state: '',
    LGA: '',
    phoneNumber: '',
    deliveryDate: '',
    deliveryFrequency: '', 
    isDefaultAddress: false,
    isSchoolOrBusinessAddress: false,
  };
};



export const AppContextProvider = (props) => {

  const showToast = ({ toastText, subText, isSuccess, closeToast }) => {
    const content = (
      <div>
        <h5 style={{ fontWeight: '700' }} className='my-auto'>{toastText}</h5>
        {isSuccess && subText && <h6>{subText}</h6>}
      </div>
    );

    const toastOptions = {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      onClose: closeToast,
    };

    if (isSuccess) {
      toast.success(content, toastOptions);
    } else {
      toast.error(content, toastOptions);
    }
  };

  
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
   
    const [showCart, setShowCart] = useState(false);
    const [activeNavItem, setActiveNavItem] = useState('');
    const [showSidebar, setShowSidebar] = useState(false);

  const [showWishlist, setShowWishlist] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountDescription, setDiscountDescription] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountType, setDiscountType] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 20,
    totalPages: 0,
    totalWishlistItems: 0
  });
 
  const [formData, setFormData] = useState(getInitialFormData());
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!token;
  // Effect to fetch user data if logged in

   useEffect(() => {
    if (isLoggedIn) {
      const fetchUserDetails = async () => {
        try {

          const response = await axios.get('https://production-api.virtuesshop.co/user', {

          
            headers: { 'X-Auth-Token': token}, 
          });
        
          localStorageSetItem
("userData", JSON.stringify(response?.data?.data));

          setFormData(prevData => ({
            ...prevData,
            streetAddress:  response?.data?.data?.address?.streetAddress || prevData.streetAddress,
            LGA:  response?.data?.data?.address?.city?.name || prevData.LGA,
            state: response?.data?.data?.address?.state?.name || prevData.state,
          }));

        
           
        } catch (error) {
          console.error('Error fetching user details:', error);
          showToast({ toastText: "An unexpected error occurred", isSuccess: false });
        }
      };
      fetchUserDetails();
    }
  }, [isLoggedIn]);

 
  useEffect(() => {
    localStorageSetItem
('formData', JSON.stringify({
      streetAddress: formData.streetAddress,
      state: formData.state,
      LGA: formData.LGA,
    }));
  }, [formData.streetAddress, formData.state, formData.LGA]);

 
  const updateFormData = (newFormData) => {
    setFormData(prev => ({ ...prev, ...newFormData }));
  };

  const handleShowWishlist = () => {
    setShowWishlist(true)
    // const contentId = userData ? userData?.id : 'Guest'; // Use user ID or a default for guests
    // const contentType = 'View Wishlist';
    // trackViewContent(contentId, contentType);
  }
  
  const handleCloseWishlist = () => setShowWishlist(false);
 

  const handleShowCart = () => {
  
    setShowCart(true);
    
      // const userData = getParsedLocalStorageItem('userData');
      // const contentId = userData ? userData?.id : 'Guest'; // Use user ID or a default for guests
      // const contentType = 'View Cart';
    
      // trackViewContent(contentId, contentType);

  
  };

  const handleCloseCart = () => {

    
    setShowCart(false);
   
   
  };
 
    const [searchQuery, setSearchQuery] = useState('');
    const [searchPerformed, setSearchPerformed] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchVersion, setSearchVersion] = useState(0);
    const [previousActiveNavItem, setPreviousActiveNavItem] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [cartItems, setCartItems] = useState(getInitialCart());
    const [wishlistItems, setWishlistItems] = useState(getInitialWishlist());
    const [apiWishlistItems, setApiWishlistItems] = useState([]);
    const [recentSearches, setRecentSearches] = useState([]);
    const [promoCode, setPromoCode] = useState('');
    const [voucherCode, setVoucherCode] = useState('');
    const [voucherDiscount, setVoucherDiscount] = useState(0);
    const [minOrderAmount, setMinOrderAmount] = useState(0);
    useEffect(() => {
      const savedSearches = JSON.parse(localStorageGetItem
('recentSearches')) || [];
      const lowerCaseMap = new Map();
    
      // Preserve the first occurrence of each search, case-insensitively
      savedSearches.forEach(search => {
        const lowerCaseSearch = search.toLowerCase();
        if (!lowerCaseMap.has(lowerCaseSearch)) {
          lowerCaseMap.set(lowerCaseSearch, search);
        }
      });
    
      const uniqueSearches = Array.from(lowerCaseMap.values());
      setRecentSearches(uniqueSearches);
    }, []);
    

    useEffect(() => {
      localStorageSetItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);
    

    useEffect(() => {
      localStorageSetItem
('wishlistItems', JSON.stringify(wishlistItems));
    }, [wishlistItems]);
    
    
    
  
    const addToWishlist = (productId) => {
      const updatedWishlist = [...wishlistItems, productId];
      localStorageSetItem
('wishlistItems', JSON.stringify(updatedWishlist));
      setWishlistItems(updatedWishlist);
    };
    
    const removeFromWishlist = (productId) => {
      const updatedWishlist = wishlistItems.filter(id => id !== productId);
      localStorageSetItem
('wishlistItems', JSON.stringify(updatedWishlist));
      setWishlistItems(updatedWishlist);
    };
  



    const updatedWishlist = (wishlist) => {
      localStorageSetItem
('wishlistItems', JSON.stringify(wishlist));
     
    };

// Update cart in AppContext and store in local storage
const updateCart = (newCartItems) => {
  // Update cart items in AppContext
  setCartItems(newCartItems);

  // Store updated cart items in local storage
  localStorageSetItem('cartItems', JSON.stringify(newCartItems));
};




const handleRemoveItem = (id) => {
  setCartItems(currentItems => 
    currentItems.filter(item => item.id !== id)
  );
  
};

const calculateTotalAmount = () => {
  return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0);
};



  const contextValue = { showToast,cartItems, setCartItems, updateCart, handleRemoveItem, calculateTotalAmount, showCart, handleCloseCart, handleShowCart, setShowCart, showCart, showWishlist, setShowWishlist, handleCloseWishlist, handleShowWishlist, wishlistItems, setWishlistItems,addToWishlist, removeFromWishlist, updatedWishlist, apiWishlistItems, setApiWishlistItems, pagination, setPagination,setShowSidebar, showSidebar, setShowLogoutModal, showLogoutModal, setShowShareModal, showShareModal, setSearchLoading, searchLoading, searchQuery, setSearchQuery, searchResults, setSearchResults, products, setProducts, pagination, setPagination, searchPerformed, setSearchPerformed, searchVersion, setSearchVersion, recentSearches, setRecentSearches,formData, setFormData,updateFormData, setDiscount, discount, promoCode, setPromoCode, discountDescription, setDiscountDescription, minOrderAmount, setMinOrderAmount, setDiscountType, discountType, setDiscountPercentage, discountPercentage,voucherCode, setVoucherCode,voucherDiscount, setVoucherDiscount
   
  };

  return (
    <AppContext.Provider value={contextValue}>{props.children}
   
    </AppContext.Provider>
  );
}


export const useAppContext = () => {
  return useContext(AppContext);
};




