import {useState, useEffect} from 'react'
import { Nav, Button, Image, Row, Col, Container } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { getParsedLocalStorageItem } from "./Navbar";
import { trackEvent } from "../../mixpanel";
import { TbUserEdit } from "react-icons/tb";
import { GoShieldLock } from "react-icons/go";
import { FaBasketShopping } from "react-icons/fa6";
import { IoMdNotificationsOutline } from "react-icons/io";
// import { trackViewContent, transformPath } from "../../App";
import { useAppContext } from "../../AppContext";
import { LuFileQuestion } from "react-icons/lu";
import arrowDown from '../../assets/arrow-down.svg'
import arrowLeft from '../../assets/arrow-left.svg'
import arrowRight from '../../assets/arrow-right.svg'
import { MdContactPhone } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import userRoute from "../../services/userRoute";
import { localStorageGetItem, localStorageSetItem , transformPath} from '../../App';
import { BsHandbag } from 'react-icons/bs';
import { AiOutlineDollar } from "react-icons/ai";

const SidebarNavbar = () => {
  const {cartItems, handleCloseCart, handleShowCart, handleShowWishlist, wishlistItems,showLogoutModal, setShowLogoutModal, showToast}= useAppContext()
  
  const [loading, setLoading] = useState(false);
const navigate = useNavigate();
    const userData = getParsedLocalStorageItem('userData');
    const profileImage = userData?.profilePicture ;
    const fullName = `${userData?.firstName} ${userData?.lastName}`;
    const token = localStorageGetItem
('userToken');
    const isLoggedIn = !!userData && !!token;
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [details, setDetails] = useState({});
    const { getUser, updateUser } = userRoute();
    // useEffect(()=>{
    //   if(isLoggedIn){
    //     getUser(setLoading, showToast, setDetails);
    //   }
    // },[details,isLoggedIn])
      const toggleAccordion = () => {
          setIsOpen(!isOpen);
      };
  
      const toggleAccordion2 = () => {
        setIsOpen2(!isOpen2);
    };
    const toggleAccordion3 = () => {
      setIsOpen3(!isOpen3);
  };

    const handleLogout = () => {

        setShowLogoutModal(true);
      };
    
    return (

        <Nav className="gap-3 sidebar py-5 py-sm-3 my-5 my-sm-0 "  style={{backgroundColor: '#469B1208',
      }}>

        {!isLoggedIn && (
          <>
          <div className="d-flex justify-content-center gap-3 align-items-center my-3 px-3">
          <Button className='btn-primary' onClick={() => { navigate('/login');  trackEvent('Sign in Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Sign in'
  });}}>Sign in</Button> 
   <Button className='btn-outline-primary'  onClick={() => {  navigate('/signup');  
  //  const contentId = 'Guest'; 
  //  const contentType = 'View Signup';
  //  trackViewContent(contentId, contentType);
   trackEvent('Create an Account Click', {
    clicked_at: new Date().toISOString(),
    location_clicked: transformPath(window.location.pathname),
    button_clicked: 'Create an account'
  });}}>Create an account</Button>
          </div>
          </>
        )}

        {isLoggedIn && ( <>
      
        <div className="d-none d-sm-flex gap-3 align-items-center mb-2 px-3">
        {profileImage ? (
    <Image
      roundedCircle
      alt='profile icon'
      src={profileImage}
      style={{
        width: "35px",
        height: "35px", // Adjusted to match the avatar size
        objectFit: "cover",
      }}
    />
  ) : (
        <div
      style={{
        width: '35px', 
        height: '35px', 
        borderRadius: '50%', 
        backgroundColor: 'rgba(70, 155, 18, 0.4)',
        color: '#000000', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight:'600',
        fontSize: '12px', 
      }}
    >
      {
        userData?.firstName ? userData.firstName[0].toUpperCase() : userData.email[0].toUpperCase()
      }
    </div>)}
    {fullName.length > 20 ? <h5 style={{color:'#0C0C0C', fontWeight:'600'}} className="my-auto">{userData?.firstName} <br/>{userData?.lastName}</h5>:  <h5 style={{color:'#0C0C0C', fontWeight:'600'}} className="my-auto text-wrap">{userData?.firstName}&nbsp;{userData?.lastName}</h5>}

  
    
        </div>
        <div className="d-flex flex-column d-sm-none gap-3 align-items-center text-center justify-content-center mb-4 px-3">
          {profileImage ? (
        <Image
        alt='circle icon'
      roundedCircle
      src={profileImage}
      style={{
        width: "70px",
        height: "70px", // Adjusted to match the avatar size
        objectFit: "cover",
      }}
    />
  ) : (
        <div
      style={{
        width: '70px', 
        height: '70px', 
        borderRadius: '50%', 
        backgroundColor: 'rgba(70, 155, 18, 0.4)',
        color: '#000000', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight:'600',
        fontSize: '28px', 
      }}
    >
      {
        userData?.firstName ? userData.firstName[0].toUpperCase() : userData.email[0].toUpperCase()
      }
    </div>)}
    <h5 style={{color:'#333333', fontWeight:'600'}} className="my-auto ">{userData?.firstName}&nbsp;{userData?.lastName}</h5>
        </div>
         
          <NavLink to='/profile/personal-data' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><TbUserEdit style={{fontSize:'1.5rem'}}/>Personal Data</NavLink>
          <NavLink to='/profile/orders' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'}  > <FaBasketShopping style={{fontSize:'1.3rem'}}/>Orders</NavLink>
          <NavLink to='/profile/subscriptions' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><BsHandbag style={{fontSize:'1.3rem'}}/>My Subscriptions</NavLink>
          <NavLink to='/profile/wishlist' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><i className="bi bi-heart" style={{fontSize:'1.3rem'}}></i>Wishlist</NavLink>
          <NavLink to='/profile/password-update' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><GoShieldLock style={{fontSize:'1.3rem'}}/>Password</NavLink>
          <NavLink to='/profile/refer' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} ><AiOutlineDollar style={{fontSize:'1.3rem'}}/>Refer & Earn</NavLink>
          </>
          )}
<div className='d-block d-sm-none'>
<NavLink to='/faq' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'}  > <LuFileQuestion  style={{fontSize:'1.5rem'}}/>FAQs</NavLink>
<div className='pt-2'>
<NavLink className={({ isActive }) => isActive ? 'd-flex justify-content-between align-items-center nav-link ': 'd-flex justify-content-between align-items-center nav-link active ' } onClick={()=>  toggleAccordion()} >
           
                <div className='d-flex gap-0 align-items-center'>
                <i className="bi bi-shield-check" style={{fontSize:'1.3rem', color:''}}></i>
                    <p className='nav-link my-auto'>Compliance</p>
                </div>
                <Button variant='outline-secondary' style={{border:'none', backgroundColor:'transparent'}}>
                <img src={isOpen ? arrowDown : arrowRight} alt='toggle'/>
                </Button>
          
           
        </NavLink>
       
        {isOpen && (
                <div className='pe-3 ps-5 pe-sm-2 ps-sm-1' style={{ borderBottom: '1px solid #EAECF0', fontSize:'14px' }}  >
                <NavLink to='/privacy-policy' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'} style={{fontSize:'14px' }}   > Privacy Policy</NavLink>
                <NavLink to='/terms' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'}  style={{fontSize:'14px' }} > Terms of Use</NavLink>
                <NavLink to='/shipping-policy' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'}  style={{fontSize:'14px' }}  > Shipping & Delivery Policy</NavLink>
                <NavLink to='/refund-policy' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'}  style={{fontSize:'14px' }} > Return & Refund Policy</NavLink>
                </div>
            )}
</div>
<div className='pt-1'>
<NavLink className={({ isActive }) => isActive ? 'd-flex justify-content-between align-items-center nav-link ': 'd-flex justify-content-between align-items-center nav-link active ' } onClick={()=>  toggleAccordion2()} >
           
                <div className='d-flex gap-0 align-items-center'>
                <i className="bi bi-file-earmark" style={{fontSize:'1.3rem', color:''}}></i>
                    <p className=' nav-link my-auto'>Company</p>
                </div>
                <Button variant='outline-secondary' style={{border:'none', backgroundColor:'transparent'}}>
                <img src={isOpen2 ? arrowDown : arrowRight} alt='toggle'/>
                </Button>
          
           
        </NavLink>
       
        {isOpen2 && (
                <div className='pe-3 ps-5 pe-sm-2 ps-sm-1' style={{ borderBottom: '1px solid #EAECF0',fontSize:'14px' }}  >
               <NavLink to='/' className={({ isActive }) => isActive ? 'd-flex gap-3 align-items-center nav-link active' : 'd-flex gap-3 align-items-center nav-link'}  style={{fontSize:'14px' }} > About Us</NavLink>
              <h6  style={{ cursor: "pointer", fontWeight:'400', fontSize:'14px' }}
           className='nav-link' ><a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
            rel="noopener noreferrer" className='text-decoration-none text-dark'>Join our Community</a></h6>
                </div>
            )}
</div>
<div className='pt-1 pb-2'>
<NavLink className={({ isActive }) => isActive ? 'd-flex justify-content-between align-items-center nav-link ': 'd-flex justify-content-between align-items-center nav-link active ' } onClick={()=>  toggleAccordion3()} >
           
                <div className='d-flex gap-0 align-items-center'>
                <MdContactPhone style={{fontSize:'1.3rem', color:''}}/>
                    <p className=' nav-link my-auto'>Contact</p>
                </div>
                <Button variant='outline-secondary' style={{border:'none', backgroundColor:'transparent'}}>
                <img src={isOpen3 ? arrowDown : arrowRight} alt='toggle'/>
                </Button>
          
           
        </NavLink>
       
        {isOpen3 && (
                <div className='d-flex flex-column text-center gap-2 gap-sm-2 pe-3 ps-5 pe-sm-2 ps-sm-1 py-2' style={{ borderBottom: '1px solid #EAECF0' }}  >
                 <div className='d-flex gap-2 align-items-center py-0 my-0' >
            <i className='bi bi-telephone' style={{fontSize:'1rem'}}></i>
            
            <h6 className='my-auto py-0' style={{ cursor: "pointer", fontWeight:'400', }}
            ><a href="tel:+2348166641019" className="text-dark text-decoration-none">
            +2348166641019
          </a>, <a href="tel:+2349045557838" className="text-dark text-decoration-none">
          +2349045557838
          </a></h6>
            </div>
            <div className='d-flex gap-3 align-items-center py-0 my-0'>
           <i className='bi bi-envelope' style={{fontSize:'1rem'}}></i>
           <h6  style={{ cursor: "pointer", fontWeight:'400' }} className='my-auto'
            > <a
            href="mailto:support@virtuesshop.co"
            className="text-dark text-decoration-none"
          >support@virtuesshop.co
          </a></h6>
           </div>
           <div className='d-flex gap-3 align-items-center'>
           
           <a href='https://web.facebook.com/people/VirtuesShop/61556578874811/?mibextid=LQQJ4d' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none', color:'#000000'}}><i className='bi bi-facebook' style={{fontSize:'1rem'}}></i></a>
           
           <a href='https://www.instagram.com/virtuesshop_?igsh=cmcxazFqbTQwZmxx' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none', color:'#000000'}}><i className='bi bi-instagram' style={{fontSize:'1rem'}}></i></a>
           
           <a href='https://x.com/VirtuesShop?t=pVoMBdmEVgRNA6LEvm4A-Q&s=08' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none', color:'#000000'}}><i className='bi bi-twitter-x' style={{fontSize:'1rem'}}></i></a>
           
           <a href='https://www.linkedin.com/company/virtuesshop/' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none', color:'#000000'}}><i className='bi bi-linkedin' style={{fontSize:'1rem'}}></i></a>
         
         
           <a href='https://chat.whatsapp.com/LQMW9mgRWN2L3LlO8UXFAo' target="_blank"  
    rel="noopener noreferrer"  style={{textDecoration:'none', color:'#000000'}}><i className='bi bi-whatsapp' style={{fontSize:'1rem'}}></i></a>
           </div>
                </div>
            )}
</div>
<Nav.Link  href='https://wa.me/message/NLN66QPP6NX3L1' target="_blank"  
            rel="noopener noreferrer" className={({ isActive }) => isActive ? 'd-flex gap-5 align-items-center nav-link active' : 'd-flex gap-5 align-items-center nav-link'}  > <FaWhatsapp style={{fontSize:'1.5rem'}}/> &nbsp; &nbsp;Chat with us</Nav.Link>
            </div>
{isLoggedIn && ( <>
       
          <Nav.Link onClick={handleLogout} style={{color:'#C91433'}} className="d-flex gap-3 align-items-center"><i className="bi bi-box-arrow-left" style={{fontSize:'1.5rem'}}></i>Logout</Nav.Link>
          </>
          )}
      </Nav>
  
     
    
    );
  };
  
  export default SidebarNavbar;