import React,{useState,useEffect} from 'react'
import search from '../../assets/search.svg'
import { Card, Row, Col, Button , Form} from 'react-bootstrap'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useAppContext } from '../../AppContext'
import orderRoute from '../../services/orderRoute'
import productRoute from '../../services/productRoute'
import { trackEvent } from '../../mixpanel'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import { localStorageGetItem, localStorageSetItem,localStorageRemoveItem } from '../../App';
import { sanitizeProductName } from './Home'

const Search = () => {
    const {setSearchLoading, searchLoading, searchQuery, setSearchQuery, products, setProducts,setSearchResults, searchResults, cartItems, setCartItems, apiWishlistItems,setApiWishlistItems,updateCart, showToast, setWishlistItems, pagination, setPagination, setSearchPerformed, searchPerformed, recentSearches, setRecentSearches}= useAppContext();
    const {getWishlist, updateWishlist}= orderRoute();
    const {getProductswithSearch, getMoreProducts, }= productRoute();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isFocused, setIsFocused] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [searchTimeout, setSearchTimeout] = useState(null);
      const [filledHearts, setFilledHearts] = useState({});
      const [selectedProduct, setSelectedProduct] = useState(null);
    const userData = getParsedLocalStorageItem('userData');
  const userToken = localStorageGetItem
('userToken');
  
  const isLoggedIn = !!userData && !!userToken;
  const location = useLocation();
 

useEffect(() => {
  // This function will be called when the component unmounts
  return () => {
    // If there's an outstanding timeout when the component unmounts, clear it
    if (searchTimeout) clearTimeout(searchTimeout);
  };
}, [searchTimeout]); // Dependency on searchTimeout ensures this effect runs when searchTimeout changes


useEffect(() => {
    if (isLoggedIn) {
      // Fetch the wishlist if logged in
      getWishlist(setLoading, showToast, setApiWishlistItems);
    }
  }, [isLoggedIn]);

useEffect(() => {
    let wishlist = [];
  
    if (isLoggedIn) {
      // If logged in, use wishlist items fetched from API
      wishlist = apiWishlistItems;
      const updatedWishlistStatus = {};
  products.forEach(product => {
    const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product.id);
    updatedWishlistStatus[product.id] = isInWishlist;
  });
  setFilledHearts(updatedWishlistStatus);
    } else {
      // If not logged in, retrieve wishlist items from local storage
      const wishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
    
      const updatedWishlistStatus = {};
  products.forEach(product => {
    const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product.id);
    updatedWishlistStatus[product.id] = isInWishlist;
  });
  setFilledHearts(updatedWishlistStatus);
    }
  
   
  
  }, [products, apiWishlistItems]);
  

    useEffect(() => {
        if (selectedProduct) {
          const updatedProduct = products.find(product => product.id === selectedProduct.id);
          if (updatedProduct && updatedProduct.quantity !== selectedProduct.quantity) {
            setSelectedProduct(updatedProduct);
          }
        }
      }, [products, selectedProduct]);

      const fetchMoreProducts = () => {
        if (pagination.currentPage < pagination.totalPages) {
          
            // Start loading more products
            getMoreProducts(
               setLoadingMore,
                showToast,
                setProducts,
                { ...pagination, currentPage: pagination.currentPage + 1 },
                setPagination,
                // Pass setLoadingMore to stop loading more products
            );
        } else {
            setHasMore(false); // No more products to fetch
        }
    };
    const handleFocus = () => {
        setIsFocused(true)
       
      };
      const handleBlur = () => {
        setIsFocused(true);
      };

      const MAX_RECENT_SEARCHES = 10; // For example, limit to 5 recent searches

      const updateRecentSearches = (query) => {
        // This assumes recentSearches is an array of strings
        const normalizedQuery = query.toLowerCase();
      
        // Filter out any existing entry that matches the new query case-insensitively
        const filteredSearches = recentSearches.filter(q => q.toLowerCase() !== normalizedQuery);
      
        // Prepend the new query while preserving its original case
        const updatedSearches = [query, ...filteredSearches].slice(0, MAX_RECENT_SEARCHES);
      
        setRecentSearches(updatedSearches);
        localStorageSetItem('recentSearches', JSON.stringify(updatedSearches));
      };
      

    const handleSearchChange =  (event) => {
        setIsFocused(true)
        const searchQuery = event.target.value;
    
        const firstSpaceIndex = searchQuery.indexOf(' ');
        const firstParenthesisIndex = searchQuery.indexOf('(');
        
        let cutIndex = -1;
        if (firstSpaceIndex === -1 && firstParenthesisIndex === -1) {
          cutIndex = searchQuery.length; // No space or parenthesis found, use the whole string
        } else if (firstSpaceIndex === -1) {
          cutIndex = firstParenthesisIndex; // No space found, cut at the parenthesis
        } else if (firstParenthesisIndex === -1) {
          cutIndex = firstSpaceIndex; // No parenthesis found, cut at the space
        } else {
          // Both space and parenthesis found, cut at the earliest one
          cutIndex = Math.min(firstSpaceIndex, firstParenthesisIndex);
        }
      
        const query = searchQuery.substring(0, cutIndex);

        setSearchQuery(searchQuery);
      
        if (searchQuery.trim()) {
            setIsFocused(true)
            
          getProductswithSearch(query,setSearchLoading,showToast,setProducts, setSearchResults, setSearchPerformed, pagination, setPagination)
        }else {
           
            setProducts([]); 
            setSearchResults([]); 
            setSearchPerformed(false);
            setSearchLoading(false);
            
        }
        if (searchTimeout) clearTimeout(searchTimeout);
  
        setSearchTimeout(setTimeout(() => {
         
          if (query) {
            updateRecentSearches(searchQuery);
          }
        }, 3000));
      
      };

      const handleSearchResultClick = (result) => {
        // Find the index of the first space or opening parenthesis, whichever comes first
        const firstSpaceIndex = result.indexOf(' ');
        const firstParenthesisIndex = result.indexOf('(');
        
        let cutIndex = -1;
        if (firstSpaceIndex === -1 && firstParenthesisIndex === -1) {
          cutIndex = result.length; // No space or parenthesis found, use the whole string
        } else if (firstSpaceIndex === -1) {
          cutIndex = firstParenthesisIndex; // No space found, cut at the parenthesis
        } else if (firstParenthesisIndex === -1) {
          cutIndex = firstSpaceIndex; // No parenthesis found, cut at the space
        } else {
          // Both space and parenthesis found, cut at the earliest one
          cutIndex = Math.min(firstSpaceIndex, firstParenthesisIndex);
        }
      
        const query = result.substring(0, cutIndex);
        setSearchQuery(result);
        // Perform the search with the newly extracted query
        getProductswithSearch(query, setSearchLoading, showToast, setProducts, setSearchResults, setSearchPerformed, pagination, setPagination);

        updateRecentSearches(result);
      };
      

    const handleIncreaseQuantity = (id, increment = 1, replace) => {
        let newCartItems = [...cartItems];
        const cartIndex = newCartItems.findIndex(item => item.id === id);

        let initialQuantity = 0;
        if (cartIndex !== -1) {
          initialQuantity = newCartItems[cartIndex].quantity;
        }
      
      
        if (cartIndex !== -1) {
         
          const updatedQuantity = replace ? increment : newCartItems[cartIndex].quantity + increment;
          newCartItems[cartIndex] = { ...newCartItems[cartIndex], quantity: updatedQuantity };
        } else {
         
          const productToAdd = products.find(p => p.id === id);
          newCartItems.unshift({ ...productToAdd, quantity: increment }); // Adds at the beginning
        }
      
        setCartItems(newCartItems);
      
        // Reflect the cart change elsewhere as needed, like persisting the cart
        updateCart(newCartItems); 
        const newProducts = products.map(product => {
               if (product.id === id) {
                 return { ...product, quantity: (product.quantity || 0) + 1 };
               }
               return product;
             });
             setProducts(newProducts);
             const product = newProducts.find(p => p.id === id);
            //  trackAddToCart(product.id, product.productName, product.price);
            if (initialQuantity === 0) {
              trackEvent(`${product.productName} Added to Cart`, {
                'Product ID': product.id,
                'Product Name': product.productName,
                'Category Name': product.category,
                'Quantity Added': increment,
                'User': userData?.email || 'Anonymous',
                'Location': 'Search page'
              });
            } else {
              trackEvent(`${product.productName} Increased in Cart`, {
                'Product ID': product.id,
                'Product Name': product.productName,
                'Category Name': product.category,
                'New Quantity': newCartItems[cartIndex].quantity,
                'User': userData?.email || 'Anonymous',
                'Location': 'Search page'
              });
            }
          
      };
      
     
      
      const handleDecreaseQuantity = (id) => {
        let newCartItems = [...cartItems];
        const cartIndex = newCartItems.findIndex(item => item.id === id);
      
        // Check if the product is in the cart
        if (cartIndex !== -1) {
          // Decrease quantity or remove item if quantity is 1
          if (newCartItems[cartIndex].quantity > 1) {
            newCartItems[cartIndex] = { ...newCartItems[cartIndex], quantity: newCartItems[cartIndex].quantity - 1 };
          } else {
            // Remove item from cart if quantity is 1
            newCartItems.splice(cartIndex, 1);
          }
        }
      
        setCartItems(newCartItems);
      
        // Update product quantity in the products state
        const updatedProducts = products.map(product => {
          if (product.id === id) {
            // Decrease the quantity and allow it to be 0
            let newQuantity = (product.quantity || 0) - 1;
            return { ...product, quantity: newQuantity < 0 ? 0 : newQuantity };
          }
          return product;
        });
      
        setProducts(updatedProducts);
      
        // Update cart storage or state
        updateCart(newCartItems); 
      };

      const handleShowProductModal = (product) => {

        /*Ad tag */
        // const contentId = product ? product?.id : 'Unknown product';
        // const contentType = `View ${product?.productName} Product Information`
        // trackViewContent(contentId, contentType);
    
        // setShowProductModal(true);
        trackEvent(`${product?.productName} Viewed`, {
          'Product ID': product?.id,
          'Product Name': product?.productName,
          'Category Name': product?.category,
          'User': userData?.email || 'Anonymous',
          // Any other relevant properties
        });
        const sanitizedProductName = sanitizeProductName(product?.productName);

        // Navigate to the product page with the sanitized product name in the URL
           const productId = product?.sku;

navigate(`/products/${sanitizedProductName}/${productId}`);
      };

  // const handleCloseProductModal = () => {
  //   setShowProductModal(false);
  // };
  const toggleFill = (productId) => {



    if (isLoggedIn) {
    
      updateWishlist(
        productId,   
        showToast, 
        filledHearts,
        setFilledHearts,
        apiWishlistItems, 
        setApiWishlistItems, 
        products
      );
    }
     else {
      const newState = !filledHearts[productId];
      setFilledHearts(prevState => ({
        ...prevState,
        [productId]: newState,
      }));
  
      let wishlist = getParsedLocalStorageItem('wishlistItems') || [];
      const index = wishlist.findIndex(item => item?.product?.id === productId);
      let toastText = '';
      
      if (newState) {
        // Add to local wishlist if not already present
        if (index === -1) {
          const productToAdd = products.find(product => product.id === productId);
          wishlist = [{product:productToAdd}, ...wishlist]; // Prepend the new product
          toastText = 'Item added to Wishlist!';
          // trackAddToWishlist(productId, productToAdd.productName);
        }
      } else {
        // Remove from local wishlist if present
        if (index !== -1) {
          wishlist = wishlist.filter(item => item?.product?.id !== productId);
          toastText = 'Item removed from Wishlist!';
        }
      }
  
      // Update local storage and state
      localStorageSetItem('wishlistItems', JSON.stringify(wishlist));
      setWishlistItems(wishlist);
  
      // Show the appropriate toast message
      showToast({
        toastText: toastText,
        isSuccess: true
      });
      const product = products.find(p => p.id === productId);
      trackEvent(newState ? `${product?.productName} Added to Wishlist` : `${product?.productName} Removed from Wishlist`, {
        'Product ID': productId,
        'Product Name': product?.productName,
        'Category Name': product?.category,
        'User': userData?.email || 'Anonymous',
     
      });
    }
  
  
    
  };

  const handleRecentSearchClear = ()=> {
    setRecentSearches([]);
    localStorageRemoveItem('recentSearches')
  }

  return (
    <div>
    <div className='px-3 px-md-5 py-4 py-sm-4 my-5 my-sm-0 '>
<div className='d-none d-md-flex justify-content-end mb-3 mt-3 mt-md-0'>
<Link className='' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
</div>
<div className='d-flex d-sm-none align-items-center search-bar position-relative my-4'>
    {!isFocused && searchQuery === '' && (<>
        <img src={search} alt='search' style={{top: '24%', left: '1.5%'}} className='position-absolute'/>
    
      </>)}
      {searchQuery !== '' && <i className='bi bi-x' style={{top: '5%', right: '1.5%', position:'absolute', fontSize:'1.5rem', color:'#98A2B3'}} onClick={() => setSearchQuery('')}></i>}
      <Form.Control
        style={{border: 'none', backgroundColor: 'transparent'}}
        placeholder='Search..'
        className='w-100'
        value={searchQuery}
        onFocus={handleFocus}
        onBlur={handleBlur} 
        onChange={handleSearchChange}
      />
    </div>
{searchQuery === '' && recentSearches.length > 0 && (<div>
<div className='d-flex justify-content-between align-items-center mb-2 mb-md-3 me-lg-5'><h4 style={{fontWeight:'600', fontSize:'18px'}} className=''><i className='bi bi-clock'></i> &nbsp; Recent searches</h4>
<Button style={{fontWeight:'500', cursor:'pointer'}} onClick={handleRecentSearchClear} variant='danger'className='me-lg-5  py-1'>&nbsp; Clear</Button>
</div>

<div className='d-flex gap-3 flex-wrap mb-5'>
      {recentSearches.map((result, index) => (
        <Button key={index} variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'600', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}} onClick={() => handleSearchResultClick(result)}>
          {result}
        </Button>
      ))}
    </div>
</div>)
   }
    

          {searchLoading ? (
  <div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
    <div className="spinner-border text-success" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
) : products.length > 0  && searchQuery !== '' ? (
  <div>
   
    <div className='d-flex gap-3 flex-wrap mb-5'>
      {searchResults.map((result) => (
        <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'600', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}} onClick={() => handleSearchResultClick(result)}>
          {result}
        </Button>
      ))}
    </div>
    <h4 style={{fontWeight:'600'}} className='mt-2 mb-4'>Showing results for "{searchQuery}"</h4>
    <InfiniteScroll
      dataLength={products.length}
      next={fetchMoreProducts}
      hasMore={hasMore}
      style={{overflowX:'hidden'}}
      loader={loadingMore && <div className='justify-content-center align-items-center text-center' >
        <div className="spinner-border text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>}
    >
         
       <Row>
        {products.map((product) => {
          const foundInCart = cartItems.find(item => item.id === product.id);
          const productQuantity = foundInCart ? foundInCart.quantity : 0; 
          product.quantity = productQuantity;
          
          return(
          <Col xs={6} md={3} className='mb-3 mb-md-4'  key={product?.id} >
            {product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0 ?  
<Card className='border-0 h-100' style={{boxShadow: '0px 4px 4px 0px #00000040'
}} >
        <div className='position-relative mb-1 h-100'>
        <Card.Img src={product?.productImage} style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', height:'100%',maxHeight:'250px',objectFit:'cover', width:'true'}}/>
        
        <Card.ImgOverlay style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', height:'100%',maxHeight:'250px',}} onClick={() => handleShowProductModal(product)} > <div className=' bg-danger text-center px-2 position-absolute' style={{ padding:'', borderRadius:'100px',boxShadow:'0px 1px 2px 0px #0000001A', top:'5%', right:'5%'}} >

<span  style={{ color:'#ffffff', fontSize:"11px", fontWeight:'400', }}>
OUT OF STOCK
</span>

                             
</div></Card.ImgOverlay>
                   
        </div>
       
        
        < Card.Body className='px-2 pt-1 pb-2'>

<h5 style={{
color: 'rgba(53, 53, 66, 0.6)',
fontWeight: '700',
minHeight: '2.2rem', 
display: 'flex',
alignItems: 'start',
justifyContent: 'start'
}} className='my-0 py-0'>
{product?.productName}
</h5>

    
  
   <div className='d-flex justify-content-between align-items-center'>
   <div className='d-flex gap-2 align-items-center'>
   <h5 style={{ fontFamily: 'Outfit', color: '#353542', fontWeight: '600' }} className='my-auto'>
{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.price)}
</h5>


           {product?.OldPrice && (<h5 className='my-auto' style={{color:'#dfdcdc',textDecoration:'line-through', fontSize:'12px', fontFamily:'Outfit', fontWeight:'500',}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.OldPrice)}</h5>)}
           

       </div>
        
       <i
                 
                  className={`bi ${filledHearts[product?.id] ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1rem',
                    color: filledHearts[product?.id] ? '#469B12' : 'black', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }}
                  onClick={() => toggleFill(product?.id)}></i>
      
       
   </div>
</Card.Body>
 
         
         
       
      </Card>: 
           <Card className='border-0 h-100' style={{boxShadow: '0px 4px 4px 0px #00000040'
    }} >
            <div className='position-relative h-100'>
            <Card.Img src={product?.productImage} onClick={() => handleShowProductModal(product)} style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', height:'100%',maxHeight:'250px',objectFit:'cover', width:'true'}}/>
            {product?.inSeason && <div className='bg-success position-absolute my-1 my-md-0 text-center px-2' style={{top:'5%', left:'5%', padding:'', borderRadius:'100px',boxShadow:'0px 1px 2px 0px #0000001A'}} >

<span  style={{ color:'#ffffff', fontSize:"11px", fontWeight:'400', }}>
{product?.inSeason ? 'IN SEASON': 'OFF SEASON' }
</span>

                             
</div>}
            <div className='position-absolute d-flex flex-column gap-2' style={{top:'5%', right:'5%'}}>
            <div className=' pt-1 d-flex text-center align-items-center justify-content-center px-1  bg-white' style={{borderRadius:'15px', color:'#344054', boxShadow: "0px 1px 2px 0px #0000001A"}}  onClick={() => toggleFill(product?.id)}>  
          
            <i
                     
                      className={`bi ${filledHearts[product?.id] ? 'bi-heart-fill' : 'bi-heart'}`}
    
                      style={{
                        fontSize: '1rem',
                        color: filledHearts[product?.id] ? '#469B12' : 'black', // Ensures the filled heart is green
                        cursor: 'pointer',
                      }}
                    ></i>
    </div>
            </div>
           
                                          
            </div>
           
            
            < Card.Body className='px-2 pt-1 pb-2'>
    
            <h5 style={{
        color: 'rgba(53, 53, 66, 0.6)',
        fontWeight: '700',
        minHeight: '2.2rem', 
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'start'
    }} className='my-0 py-0'>
        {product?.productName}
    </h5>
              
               <div className='d-flex justify-content-between align-items-center'>
               <div className='d-flex gap-2 align-items-center'>
               <h5 style={{ fontFamily: 'Outfit', color: '#353542', fontWeight: '600' }} className='my-auto'>
      {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.price)}
    </h5>
    
    
                       {product?.OldPrice && (<h5 className='my-auto' style={{color:'#dfdcdc',textDecoration:'line-through', fontSize:'12px', fontFamily:'Outfit', fontWeight:'500',}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(product?.OldPrice)}</h5>)}
                       
    
                   </div>
                    
                   {product.quantity === 0 ? (
           <Button  onClick={() => handleIncreaseQuantity(product?.id, 1, true)} className='d-flex text-center justify-content-center align-items-center px-2' style={{height:'30px'}}>ADD</Button>
         ) : (
           <div className="btn-group btn-group-sm" >
             <Button onClick={() => handleDecreaseQuantity(product.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'1.5rem', height:'30px'}}>-</Button>
             <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'1.5rem', height:'30px', fontWeight:'600'}}>{product.quantity}</Button>
             <Button onClick={() => handleIncreaseQuantity(product.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'1.5rem', height:'30px'}}>+</Button>
           </div>
         )}
             
                  
                   
               </div>
    </Card.Body>
             
             
           
          </Card>}
          </Col>
         )})}
     </Row>
    </InfiniteScroll>
  </div>
) : 

<section className='justify-content-center text-center' style={{padding:'150px 0'}}>
  {searchPerformed && searchQuery !== '' &&  <h3>No products match your search</h3>}
  {recentSearches.length === 0 &&   <h3>Search for products here</h3>}
</section>
}



  
    </div>
   
    </div>
  )
}

export default Search