import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import arrowLeft from '../../assets/arrow-left.svg'
// 

const ShippingPolicy = () => {

  const navigate = useNavigate();
 
  const handleBack = () => {
      // setShowSidebar(true)
      // setActiveNavItem('profile');
      navigate(-1);
    }
  return (
    <div style={{ position: 'relative' }} className='py-5 py-sm-0'> 
      <Card className=' terms-card' style={{ border: 'none', borderRadius: '0px', position: 'relative', zIndex: 1 }}>
      
      <div className='pt-5 px-3 px-md-5 mx-lg-5 ' style={{zIndex:'99'}}>
      <div className='d-block d-sm-none mb-4'>
          <div className='d-flex align-items-center gap-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
          <h3 className='my-auto' style={{fontWeight:'600',color:'#469B12'}} >SHIPPING AND DELIVERY POLICY</h3>
              
           </div>
           </div>
           <div className='d-none d-sm-flex my-3 justify-content-between align-items-center'>
        <h2 className='' style={{fontWeight:'600',color:'#469B12'}} >SHIPPING AND DELIVERY POLICY</h2>
        <Link className='' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
        </div>
           
        <div className='mb-4'>
       
      <h6 className=''  style={{fontWeight:'400'}}>Thank you for choosing Virtue's Shop for your fresh groceries! We're committed to delivering high-quality produce straight to your door. This policy outlines the conditions for shipping and delivery of your order(s).
</h6>
      </div>
      <div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Delivery Areas</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>We currently deliver to select areas within Lagos and Ogun State. Please enter your LGA at checkout to confirm if your location is currently serviced. Unfortunately, if your location falls outside of our delivery zones, we cannot offer delivery at this time. Don't Worry, We Have Options!
     
      
</h6>
<h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>If you reside outside our delivery area, you can still enjoy our fresh produce. Simply choose the "Local Pickup" option during checkout. We'll notify you once your order is ready for pickup at our store located at <b>No. 2, Ileri-Oluwa Crescent, Ifako-Ijaiye, via College Road, Oyemekun Bus-Stop. However, we charge a N2,000 processing fee for in-store pickup</b>
     
 
</h6>
      </div>
      <div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Delivery Schedule</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>Currently, we deliver fresh groceries twice a month and on each of these days, please expect your delivery between 1pm and 7pm. Our drivers will contact you in case of a delay.
     
      
</h6>
</div>
<div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Delivery Fees</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>Delivery fees vary depending on your location. Enter your address at the point of checkout to know the exact delivery amount. However, there's a N2000 processing fee for In-store pickup.
     
      
</h6>
</div>
<div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Delivery Process</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>Once you place your order, you will receive a confirmation email with your order details. Shortly before your delivery, you will receive another email notification with an estimated delivery window. Our delivery partners will deliver your order fresh to your doorstep.
     
      
</h6>
</div>
<div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Order Tracking</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>You can track the status of your order by logging into your Virtue's Shop account and visiting the "<b>Orders</b>" section. The tracking information will provide details on the estimated delivery time frame and any updates on your order's progress.
      
</h6>
</div>
      <div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Undelivered Packages</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>If your package is marked as delivered but you haven't received it, please contact our customer service team within 24 hours of the delivery notification. We'll do our best to investigate the issue and locate your package.
      </h6>
      </div>
      <div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Changes to Delivery Policy</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>We reserve the right to modify this Delivery Policy at any time without prior notice. We encourage you to review this policy periodically for any updates.

      </h6>
      </div>
      <div className='mb-4 pb-5'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Stay Informed</h3>
      <h6 className='mb-3'  style={{fontWeight:'400', lineHeight:'21px'}}>Interested in delivery to your area in the future? <a onClick={()=> navigate('/signup')} style={{color:'#469B12', fontWeight:'700', textDecoration:'underline'}} >Sign up </a>for updates below to be notified when we expand our delivery zones.

      </h6>
      <h6 style={{fontWeight:'400', lineHeight:'21px'}} className='mb-3'>If you have any questions about our shipping and delivery policy, please don't hesitate to contact our customer service team by email at 
         &nbsp; <a className='text-decoration-underline fw-medium' href='mailto:support@virtuesshop.co'>support@virtuesshop.co</a> or by phone at <a className="text-dark text-decoration-none fw-medium" style={{cursor:'pointer'}} href="tel:+2348166641019">
            +2348166641019
          </a>
          </h6>
          <h6>We appreciate your understanding and thank you for shopping with us!</h6>
          </div>
      
    </div>
   
    </Card>
    </div>
  )
}

export default ShippingPolicy
