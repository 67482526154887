import React, {useEffect} from 'react'
import {useNavigate, useLocation, useParams} from 'react-router-dom'
import { useAppContext } from '../../AppContext';
import { trackEvent } from '../../mixpanel';
import { transformPath } from '../../App';
import {jwtDecode} from 'jwt-decode'
import axios from "axios";
import { localStorageGetItem, localStorageSetItem,localStorageRemoveItem } from '../../App';
const Loading = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token: urlToken } = useParams(); // Renamed to avoid shadowing


  useEffect(() => {
    const preAuthPath = localStorageGetItem
('preAuthPath') || '/';
    

    if (urlToken) {

      const fetchUserDetails = async () => {
        try {

          const response = await axios.get('https://production-api.virtuesshop.co/user', {

            headers: {
              'X-Auth-Token': urlToken,
            },
            
          });
        
          const gotResponse = response?.data?.data;
        
          localStorageSetItem('userToken', urlToken);
                localStorageSetItem("userData", JSON.stringify(gotResponse));
               
          navigate(preAuthPath);
          localStorageRemoveItem('preAuthPath');
  
          // Assuming you want to track successful login after fetching user details
          trackEvent('Login', {
            login_type: 'Google',
            button_clicked: 'Sign in with Google',
            login_at: new Date().toISOString(),
            login_location: transformPath(preAuthPath),
            device_type: navigator.userAgent
          });
  
        } catch (error) {
          console.error('There was an error!', error);
  
          navigate('/login');
  
          trackEvent('Failed Sign-in with Google', {
            failure_reason: error.message || 'An unexpected error occurred',
            login_location: transformPath(preAuthPath),
            attempted_at: new Date().toISOString(),
            button_clicked: 'Sign in with Google'
          });
        }
      };
       // Presuming you want to save the token from URL

      fetchUserDetails();
    } else {
      // If there's no token in URL, show login modal
      navigate('/login');

      trackEvent('Failed Sign-in with Google', {
        failure_reason: 'No token provided in URL',
        login_location: transformPath(preAuthPath),
        attempted_at: new Date().toISOString(),
        button_clicked: 'Sign in with Google'
      });
    }
  }, [urlToken, navigate]);

 
  return (
    <div className="justify-content-center align-items-center text-center" style={{ padding: "300px 0" }}>
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;

