import React,{useState} from 'react'
import { Modal, Button, Form, Row, Col} from 'react-bootstrap'
 
import {useNavigate} from 'react-router-dom'
import { useAppContext } from '../../AppContext'

const Waitlist = ({showWaitlist, setShowWaitlist}) => {
    const {showToast}= useAppContext()
    const navigate=useNavigate();
    const [formWaitlist, setFormWaitlist] = useState({
        firstName: "",
        lastName: "",
        email: "",
       });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormWaitlist((prev) => ({ ...prev, [name]: value }));
      };
    const handleJoinWailist = ()=> {
        setShowWaitlist(false)
        showToast({
            toastText:'Thanks for joining our waitlist',
            isSuccess:true
        })
        navigate('/')
    }

  return (
    <Modal show={showWaitlist} onHide={() => setShowWaitlist(false)} centered>
    <Modal.Header closeButton>
      <Modal.Title>
         
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className='justify-content-center text-center pb-5'>
      <div className=' d-flex flex-column gap-3 justify-content-center text-center'>

          
        
          <h3 className='mb-3' style={{fontWeight:'600', color:'#469B12 '}}>Join Waitlist</h3>
          <Form className='text-center'>
          <Row>
                  <Col xs={6} md={6}>
                  <Form.Group className='mb-2'>
                  
                  <Form.Control
                  type="text"
                  name="firstName"
                  id="firstName"
                  className=""
                  placeholder="First name"
                  value={formWaitlist.firstName}
                  onChange={handleChange}
                  required
                />
               
                  </Form.Group>

                  </Col>
                  <Col xs={6} md={6}>
                  <Form.Group className='mb-2'>
                  
                  <Form.Control
                  type="text"
                  name="lastName"
                  id="lastName"
                  className=""
                  placeholder="Last name"
                  value={formWaitlist.lastName}
                  onChange={handleChange}
                  required
                />
               
                  </Form.Group>

                  </Col>
                  <Col xs={12}>
                  <Form.Group className='mb-2'>
                 
                  <Form.Control
                  type="email"
                  name="email"
                  id="email"
                  className=""
                  placeholder="Email address"
                  value={formWaitlist.email}
                  onChange={handleChange}
                  required
                />
              
                  </Form.Group>
                  </Col>
                  
                </Row>
          <Button className='w-50 my-3' onClick={handleJoinWailist}>Join waitlist</Button>
          </Form>
         
      
     
          </div>
    
    
    </Modal.Body>
  
  </Modal>
  )
}

export default Waitlist
