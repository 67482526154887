import React, { useEffect, useState } from 'react';
import { useNavigate , useLocation} from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap'
import successIcon from '../../assets/successful-icon.svg'
import errorIcon from '../../assets/icon-error.png'

import { trackEvent } from '../../mixpanel';
import axios from "axios";
import { getParsedLocalStorageItem } from '../Navbar/Navbar';
import { localStorageGetItem, localStorageSetItem } from '../../App';

const VerifySuccessModal = () => {
  const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [showModal, setShowModal] = useState(true);
    const [message, setMessage] = useState('')
    const [subText, setSubText] = useState('')
    const [isSuccessful, setIsSuccessful] = useState(false)
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    
    const preAuthPath = localStorageGetItem
('preAuthPath') || '/';

    useEffect(() => {
    
    
  
      if (token) {
      
        const verifyEmail = async () => {
          setLoading(true);
          try {
            const response = await axios.patch(
              `https://production-api.virtuesshop.co/auth/verify-email?token=${token}`);
          
            setLoading(false)
            const gotResponse = response?.data?.data;
           
              
        
             
              localStorageSetItem('userToken', gotResponse?.accessToken);
              localStorageSetItem("userData", JSON.stringify(gotResponse?.user));
             
        setIsSuccessful(true)
        setMessage('Email Verified!')
        setSubText('Congratulations, your account has been verified successfully!')
        
  
        trackEvent('Successful Email Verification', {
          userid: gotResponse?.user?.id, 
email_address: gotResponse?.user?.email,
phone_number: gotResponse?.user?.phoneNumber || '',
attempted_at: new Date().toISOString(),

        })


  
        
      } 
       catch (error) {
        console.log(error);
        setLoading(false);
        setIsSuccessful(false)
        setMessage('Email verification failed')
        setSubText('Please check your email and click on the link provided')
        const userData = getParsedLocalStorageItem('userData');
  
        trackEvent('Failed Email Verification', {
          email_address: userData?.email || 'Unknown',
          failure_reason: error?.response?.data?.message || error?.message || 'An unexpected error occurred',
          attempted_at: new Date().toISOString()
        });
      }
    }
    verifyEmail();
  }else {
    console.error("No token found.");
    setLoading(false); 
    setIsSuccessful(false)
    setMessage('Email verification failed')
    setSubText('Please check your email and click on the link provided')
    trackEvent('Failed Email Verification', {
      failure_reason: 'No token found',
      attempted_at: new Date().toISOString()
    });
  }
  
    }, [token]);

    const closeModal = () => {
      setShowModal(false);
      navigate('/'); 
    };
    const hanndleContinue = () => {
      setShowModal(false);
      navigate(preAuthPath); 
    };
  return (
    <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton ></Modal.Header>
          <Modal.Body className='justify-content-center text-center p-5'>
          {loading ? (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
<div className="spinner-border text-success" role="status">
<span className="visually-hidden">Loading...</span>
</div>
  </div>):(<div className=' d-flex flex-column gap-5'>
    <div>
            <img src={isSuccessful ? successIcon : errorIcon} style={{width:'50px', height:'50px'}} alt='success icon'/>
            </div>
            
            <div className='mb-4'>
            <h2 className='mb-3'>{message}</h2>
            <p style={{color:'#5D5D5D', fontWeight:'500'}}>{subText}</p>
            </div>
            <div className=''>
              {isSuccessful && <Button className='btn-primary w-100' onClick={hanndleContinue}>Continue</Button>}
            
            </div></div>)}
           
           

          </Modal.Body>
          
            </Modal>
  )
}

export default VerifySuccessModal
