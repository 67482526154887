import React from 'react'
import { Card } from 'react-bootstrap'
import arrowLeft from '../../assets/arrow-left.svg'
import {Link, useNavigate} from 'react-router-dom'
// 


const Terms = () => {
        const navigate = useNavigate();
       
        const handleBack = () => {
                // setShowSidebar(true)
                // setActiveNavItem('profile');
                navigate(-1);
              }
  return (
        <div style={{ position: 'relative' }} className='py-5 py-sm-0'> 
        <Card className=' terms-card' style={{ border: 'none', borderRadius: '0px', position: 'relative', zIndex: 1 }}> 
          
       
    
      <div className='pt-5 px-3 px-md-5 mx-lg-5' style={{zIndex:'99'}}>
      <div className='d-block d-sm-none mb-4' >
          <div className='d-flex align-items-center gap-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
          <h3 className='my-auto' style={{fontWeight:'600',color:'#469B12'}} >TERMS OF USE</h3>
              
           </div>
           </div>
           <div className='d-none d-sm-flex my-3 justify-content-between align-items-center'>
        <h2 className='' style={{fontWeight:'600',color:'#469B12'}} >TERMS OF USE</h2>
        <Link className='' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
        </div>
           
        <div className='mb-4'>
        <h6 className=''  style={{fontWeight:'400'}}>These Terms of Use (<b>"Terms"</b>)govern your access to and use of the Virtue's Shop website, mobile application, and any other services provided by Virtue's Shop (collectively, the <b>"Services"</b>). By accessing or using the Services, you agree to be bound by these Terms. If you do not agree to all of these Terms, you may not access or use the Services.
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Use of the Services </h3>
        <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>
       
            <ol type='a'>
                <li className='mb-1'>You must be at least 18 years old to use the Services.</li>
                <li className='mb-1'>You are responsible for maintaining the confidentiality of your account information, including your login credentials, and for restricting access to your device. You are solely responsible for all activities that occur under your account.</li>
                <li className='mb-1'>You agree to use the Services only for lawful purposes and in accordance with these Terms. You agree not to use the Services: 
                    <ol type='i'>
                        <li className='mb-1'>In a manner that violates any applicable law or regulation.</li>
                        <li className='mb-1'>For any purpose that is harmful, abusive, obscene, or threatening. </li>
                        <li className='mb-1'>To impersonate any person or entity. </li><li className='mb-1'>To interfere with or disrupt the Services or any servers or networks connected to the Services.</li>
                        <li className='mb-1'> To upload or transmit any viruses or other malicious code.</li>
                        <li> To collect or store personal information about other users without their consent.</li>
                    </ol>  </li>
              

            </ol>
        
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Orders and Payment </h3>
        <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>
       
            <ol type='a'>
                <li className='mb-1'>You are responsible for ensuring that all information you provide when placing an order is accurate and complete.</li>
                <li className='mb-1'>Prices of products and services may change without notice.</li>
            <li className='mb-1'>We use a third-party payment processor to process your payments. You agree to comply with the terms of service of the payment processor. </li>
            <li>You agree to cancel or modify your order at any time for any reason.
                    </li>
              

            </ol>
        
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Delivery</h3>
        <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>
       
            <ol type='a'>
                <li className='mb-1'>We will deliver your order to the address you specify when placing your order.</li>
                <li className='mb-1'>Delivery times are estimates and may vary.</li>
            <li className='mb-1'>You are responsible for ensuring that someone is present at the delivery address to receive your order.
                    </li>
                    <li className='mb-1'> We are not responsible for any loss or damage to your order after it has been delivered.
                    </li>
              

            </ol>
        
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Content</h3>
        <h6 className=''  style={{fontWeight:'400'}}>The Services may contain content ("Content") that is owned by us or third parties. This Content is protected by copyright, trademark, and other intellectual property laws. You agree not to copy, modify, distribute, transmit, display, perform, sell, or create derivative works of any Content without our prior written consent.
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Disclaimer</h3>
        <h6 className=''  style={{fontWeight:'400'}}>THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, SECURE, OR ERROR-FREE. WE DO NOT WARRANT THE ACCURACY, COMPLETENESS, OR RELIABILITY OF ANY INFORMATION PROVIDED THROUGH THE SERVICES.
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Limitation of Liability</h3>
        <h6 className=''  style={{fontWeight:'400'}}>TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATING TO YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Termination</h3>
        <h6 className=''  style={{fontWeight:'400'}}>We may terminate your access to the Services at any time for any reason, with or without notice.</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Governing Law and Dispute Resolution</h3>
        <h6 className=''  style={{fontWeight:'400'}}>These Terms shall be governed by and construed in accordance with the laws of “The Nigerian Judiciary” without regard to its conflict of law provisions. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in Nigeria.</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Entire Agreement</h3>
        <h6 className=''  style={{fontWeight:'400'}}>These Terms constitute the entire agreement between you and us with respect to the subject matter hereof and supersede all prior or contemporaneous communications, representations, or agreements, whether oral or written.</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Severability</h3>
        <h6 className=''  style={{fontWeight:'400'}}>If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.</h6>
        </div>
        <div className='mb-4'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Amendment</h3>
        <h6 className=''  style={{fontWeight:'400'}}>We may amend these Terms at any time by posting the amended Terms on the Services. You are responsible for checking the Terms periodically for updates. Your continued use of the Services after the posting of amended Terms constitutes your acceptance of the amended Terms.</h6>
        </div>
        <div className='mb-5 pb-5'>
        <h3 className='mb-2' style={{fontWeight:'500'}} >Contact Us</h3>
        <h6 className=''  style={{fontWeight:'400'}}>If you have any questions about this Terms of Use, please contact us at <a className='text-dark text-decoration-underline' href='mailto:support@virtuesshop.co'>support@virtuesshop.co</a>


</h6>
        </div>
      </div>
      </Card>
    
    </div>
  
       
    
  )
}

export default Terms
