import React, {useState, useEffect} from 'react';
import { Button, Offcanvas, Card, Form, InputGroup } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import arrowLeft from '../../assets/arrow-left.svg'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { trackEvent } from '../../mixpanel';
import {transformPath } from '../../App';
import { useNavigate } from 'react-router-dom';
import trash from '../../assets/trash.svg'
import orderRoute from '../../services/orderRoute';
import { constant } from 'lodash';
import productRoute from '../../services/productRoute'
import greenTag from '../../assets/green-tag.svg'
import redTag from '../../assets/red-tag.svg'
import { localStorageGetItem, localStorageSetItem } from '../../App';



const CartOffCanvas = () => {
const navigate = useNavigate();
  const {cartItems, setCartItems, calculateTotalAmount, setShowLoginModal, setShowSignupModal, handleShowCart, handleCloseCart, setShowCart, showCart, setWishlistItems, wishlistItems,setApiWishlistItems, apiWishlistItems,showToast,previousActiveNavItem, setPreviousActiveNavItem}= useAppContext();
  const [globalProducts, setGlobalProducts] = useState([]);
  const [filledHearts, setFilledHearts] = useState({});

  const [additionalAmountNeeded, setAdditionalAmountNeeded] = useState(0);
  const [showAuthButtons, setShowAuthButtons] = useState(false);
  const {updateWishlist, getWishlist, applyPromotion}= orderRoute();
  const {getGlobalProducts}= productRoute();
  const totalAmount = calculateTotalAmount();
  const calculateTotalItems = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };
  const [loading, setLoading] = useState(false);
  const [couponMessage, setCouponMessage] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(null);
  const totalItems = calculateTotalItems()
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!token;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  useEffect(() => {
    // Function to fetch products
    const fetchProducts = () => {
      getGlobalProducts(setLoading, showToast, setGlobalProducts);
    };
  
    // Fetch products initially
    fetchProducts();
  
    // Set up the interval for fetching products every hour (3,600,000 milliseconds)
    const interval = setInterval(fetchProducts, 3600000);
  
    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };
 
    window.addEventListener('resize', handleResize);
 
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const minimumAmountRequired = 12000;
 
    if ( totalAmount < minimumAmountRequired) {
      const amountNeeded = minimumAmountRequired - totalAmount;
      setAdditionalAmountNeeded(Math.max(0, amountNeeded));
    } else{
        setAdditionalAmountNeeded(0)
    }
    
   
  }, [totalAmount]); 

 


  useEffect(() => {
    const updatedCartItems = cartItems.map(item => {
      const updatedProduct = globalProducts.find(product => product?.id === item?.id);
      return updatedProduct ? {
        ...item,
        price: updatedProduct.price,
        stockLevel: updatedProduct.stockLevel,
        quantityAvailable: updatedProduct.quantityAvailable,
        shoppingInstructions:updatedProduct.shoppingInstructions,
        productImage: updatedProduct.productImage,
        productName: updatedProduct.productName
      } : item;
    });
   
    setCartItems(updatedCartItems);
  
    localStorageSetItem('cartItems', JSON.stringify(updatedCartItems));
  }, [globalProducts]);
  
  useEffect(() => {
    let wishlist = [];
  
    if (isLoggedIn) {
      
      // If logged in, use wishlist items fetched from API
      wishlist = apiWishlistItems;
    
      const updatedWishlistStatus = {};
  cartItems.forEach(product => {
    const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product?.id);
    updatedWishlistStatus[product?.id] = isInWishlist;
  });
  setFilledHearts(updatedWishlistStatus);
    } else {
      // If not logged in, retrieve wishlist items from local storage
      const wishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
      
      const updatedWishlistStatus = {};
  cartItems.forEach(product => {
    const isInWishlist = wishlist.some(wishlistItem => wishlistItem?.product?.id === product?.id);
    updatedWishlistStatus[product?.id] = isInWishlist;
  });
  setFilledHearts(updatedWishlistStatus);
    }
  
   
  
  }, [cartItems, apiWishlistItems]);
  



  const toggleFill = (itemId) => {
const productId = itemId;
const products = cartItems;
 

    const item = cartItems.find(p => p?.id === itemId);
   
    if (isLoggedIn) {
      updateWishlist( productId,
        showToast,
        filledHearts,
        setFilledHearts,
        apiWishlistItems,
        setApiWishlistItems,
        products);
    } else {
      const newState = !filledHearts[productId];
      setFilledHearts(prevState => ({
        ...prevState,
        [productId]: newState,
      }));
  
      let wishlist = getParsedLocalStorageItem('wishlistItems') || [];
      const index = wishlist.findIndex(item => item?.produt?.id === productId);
      let toastText = '';
  
      if (newState) {
        // Add to local wishlist if not already present
        if (index === -1) {
          const productToAdd = cartItems.find(product => product?.id === productId);
          wishlist = [{product:productToAdd}, ...wishlist]; // Prepend the new product
          toastText = 'Item added to Wishlist!';
          // trackAddToWishlist(productId, productToAdd.productName);
        }
      } else {
        // Remove from local wishlist if present
        if (index !== -1) {
          wishlist = wishlist.filter(item => item?.product?.id !== productId);
          toastText = 'Item removed from Wishlist!';
        }
      }
  
      // Update local storage and state
      localStorageSetItem('wishlistItems', JSON.stringify(wishlist));
      setWishlistItems(wishlist);
  
      
      showToast({
        toastText: toastText,
        isSuccess: true
      });
      trackEvent(newState ? `${item?.productName} Added to Wishlist` : `${item?.productName} Removed from Wishlist`, {
        'Product ID': itemId,
        'Product Name': item?.productName,
        'Category Name': item?.category,
        'User': userData?.email || 'Anonymous',
     
      });
    }
    
  };

  const handleIncreaseQuantityInCart = (productId) => {
    const updatedCartItems = cartItems.map(item => {
        if (item?.id === productId) {
            // Find the corresponding product in the global products array
            const product = globalProducts.find(p => p?.id === productId);

            if (product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0) {
              showToast({ toastText: 'This product is out of stock!', isSuccess: false });
              return item; 
          }else if(item?.quantity + 1 > product?.quantityAvailable) {
                showToast({ toastText: 'This product has limited stock!', isSuccess: false });
                return item; 
            }

           
            return { ...item, quantity: item.quantity + 1 };
        }
        return item;
    });

    setCartItems(updatedCartItems);

    const updatedProduct = updatedCartItems.find(p => p.id === productId);
    if (updatedProduct) {
      // trackAddToCart(updatedProduct.id, updatedProduct.productName, updatedProduct.price);
        trackEvent(`${updatedProduct.productName} Increased in Cart`, {
            'Product ID': updatedProduct.id,
            'Product Name': updatedProduct.productName,
            'Category Name': updatedProduct.category,
            'Quantity Added': updatedProduct.quantity,
            'User': userData?.email || 'Anonymous',
        });
    }
};
  const handleDecreaseQuantityInCart = (productId) => {
    // Store the product details before any modification, for potential removal tracking
    const productBeforeUpdate = cartItems.find(item => item?.id === productId);
  
    // Decrease quantity and filter out items with quantity 0
    const updatedCartItems = cartItems.reduce((acc, item) => {
      if (item?.id === productId) {
        if (item?.quantity > 1) {
          acc.push({ ...item, quantity: item?.quantity - 1 }); // Decrease quantity
        } // Items with quantity <= 1 are not re-added, effectively removing them
      } else {
        acc.push(item); // Keep other items as they are
      }
      return acc;
    }, []);
  
    setCartItems(updatedCartItems);
  
    // Try to find the product in the updated cart
    const updatedProduct = updatedCartItems.find(p => p?.id === productId);
  
    if (updatedProduct) {
      // If the product is found, its quantity was successfully decreased
      trackEvent(`${updatedProduct.productName} Decreased in Cart`, {
        'Product ID': updatedProduct.id,
        'Product Name': updatedProduct.productName,
        'Category Name': updatedProduct.category,
        'Quantity': updatedProduct.quantity, // Reflects the updated quantity
        'User': userData?.email || 'Anonymous',
      });
    } else if (productBeforeUpdate) {
      // If the product is not found in the updated cart, it was removed
      // Use the stored details to track the removal
      trackEvent(`${productBeforeUpdate.productName} Removed from Cart`, {
        'Product ID': productBeforeUpdate.id,
        'Product Name': productBeforeUpdate.productName,
        'Category Name': productBeforeUpdate.category,
        // Here, we know the quantity reached 0, leading to its removal
        'User': userData?.email || 'Anonymous',
      });
    }
  };
  
  

const handleRemoveItem = (id) => {
  
  const removedProduct = cartItems.find(p => p?.id === id);
  
  const updatedCartItems = cartItems.filter(item => item?.id !== id);
  setCartItems(updatedCartItems);
 
  trackEvent(`${removedProduct.productName} Removed from Cart`, {
    'Product ID': removedProduct.id,
    'Product Name': removedProduct.productName,
    'Category Name': removedProduct.category,
    'Quantity': removedProduct.quantity,
    'User': userData?.email || 'Anonymous',
  });
};
const minimumAmountRequired = 12000;
const isCheckoutDisabled = totalAmount < minimumAmountRequired

const handleCheckout = () => {
  const invalidItems = cartItems?.filter(item => {
    const product = globalProducts?.find(p => p?.id === item?.id);
    return !product || product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0 || item?.quantity > product?.quantityAvailable;
  });

  if (invalidItems?.length > 0) {
    let outOfStockMessage = '';
    let lowQuantityMessage = '';

    invalidItems?.forEach(item => {
      const product = globalProducts?.find(p => p?.id === item?.id);
      if (product?.stockLevel === 'out_of_stock' || product?.quantityAvailable === 0) {
        outOfStockMessage += `${item.productName}, `;
      } else {
        lowQuantityMessage += `We only have ${product?.quantityAvailable} items in stock for ${item.productName}. `;
      }
    });

    let toastText = '';
    if (outOfStockMessage) {
      toastText += `${outOfStockMessage?.trim()} ${outOfStockMessage?.length > 1 ? 'are' : 'is'} out of stock. `;
    }
    if (lowQuantityMessage) {
      toastText += `${lowQuantityMessage?.trim()} `;
    }

    showToast({ toastText: toastText?.trim(), isSuccess: false });
    return;
  }

  const minimumAmountRequired = 12000;
 

 if (totalAmount < minimumAmountRequired) {
    setAdditionalAmountNeeded(minimumAmountRequired - totalAmount);
  } else {
    if (isLoggedIn) {
      handleCloseCart();
      setAdditionalAmountNeeded(0);
      navigate('/order-summary');
    } else {
      setAdditionalAmountNeeded(0);
      setShowAuthButtons(true);
    }
  }
};


const handleBack = () => {
 
  navigate(-1);
}


  return (
    <>
    {isMobile ?  <div className='px-lg-5 mx-lg-5 py-5'>
      <div className='d-flex gap-3 align-items-center px-2 px-md-0 pt-5 pb-3'> <img src={arrowLeft} onClick={handleBack}/>
       <h5 className='my-auto'style={{color:'#0D111B', fontWeight:'600'}}>Cart</h5>
      </div>
      <div>
        {cartItems.length > 0 ? (
          <div className='mb-5 pb-3'>
            {cartItems.map((item) => (
              <Card key={item?.id} style={{border:'none'}} className='mb-4'>
                <div className='d-flex gap-3 align-items-center py-2' style={{borderBottom:'1px solid #fcfcfc'}}>
                  <img src={item?.productImage} style={{width:'70px', height:'70px',borderRadius:'20px'}}className='ms-3'/>
                  <div className='me-3 mt-3'>
                    <p className='mb-0 pb-1' style={{fontWeight:'500', color:'#344054'}}>{item?.productName}</p>
                  <div className='m-0 p-0 d-flex gap-2 align-items-center '>
            <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'500'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.price)}</p>

          {item?.OldPrice && (<p className='' style={{color:'#dfdcdc',textDecoration:'line-through', fontFamily:'Outfit', fontWeight:'500'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.OldPrice)}</p>)}
             

         </div>
                  </div>
                </div>
                 <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
                  <div className='d-flex gap-3 align-items-center'>
                  <div onClick={() => handleRemoveItem(item?.id)} className='d-flex gap-1 align-items-center'>  <img src={trash} alt='trash'/><p  style={{color:'#394357'}}className='my-auto'> Delete</p></div>
                  <div className='d-flex gap-1 align-items-center'>  
                  <i
                  onClick={() => toggleFill(item?.id)}
                  className={`bi ${filledHearts[item?.id] ? 'bi-heart-fill' : 'bi-heart'}`}
                  style={{
                    fontSize: '0.85rem',
                    color: filledHearts[item?.id] ? '#469B12' : '#394357', 
                    cursor: 'pointer',
                  }}
                ></i> 
                 <p className='my-auto' style={{color: filledHearts[item?.id] ? '#469B12' : '#394357'}}> {/* Green if in wishlist, black otherwise */}
    {filledHearts[item?.id] ? 'Added to Wishlist' : 'Add to Wishlist'}
  </p>
                   </div>
                   </div>
                  
       <div className="btn-group btn-group-sm gap-1" >
         <Button variant='outline-secondary' onClick={() => handleDecreaseQuantityInCart(item?.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'24px', height:'24px'}}>-</Button>
         <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'24px', height:'24px', fontWeight:'500'}}>{item.quantity}</Button>
         <Button variant='outline-secondary' onClick={() => handleIncreaseQuantityInCart(item?.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'24px', height:'24px'}}>+</Button>
       </div>
    
     </div>
                
                 
                
              </Card>
            ))}
  {loading ? <div className='justify-content-center align-items-center text-center' style={{ padding: '50px' }}>
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div> : <>
             <Card style={{border:'none'}} className=''>
             
              <div className='p-3' style={{borderBottom:'1px solid #F8F8F9'}}>
              <p style={{color:'#0D111B'}} className='mb-2'>Summary</p>
              <div className='d-flex justify-content-between'>
                <p style={{color:'#667085'}}>Subtotal <span style={{color:'#B3BCCD'}}>({totalItems} {totalItems === 1 ? 'item' : 'items'})</span></p>
                <p style={{color:'#0D111B', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</p>
              </div>
            
              </div>
              <div className='py-2 px-3'>
                <div className='d-flex justify-content-between'>
                  <p style={{color:'#394357', fontWeight:'500'}}>Total</p>
                  <p style={{color:'#0D111B', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</p>
                </div>
              </div>
            </Card>
            {additionalAmountNeeded > 0 && (
              <div style={{border:'1px solid #469B12', borderLeft:'2px solid #469B12', backgroundColor:'rgba(70, 155, 18, 0.1)'}} className='p-2 mt-3'>
    <p className="my-auto" style={{color:'#469B12'}}>
      Add items worth at least <span style={{fontWeight:'700'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(additionalAmountNeeded)}</span> to reach the  <span style={{fontWeight:'700'}}>₦12,000</span> minimum basket amount.
    </p>
    </div>
  )}

  <div className='mt-4 pt-3 mb-5'>
    { showAuthButtons && !isLoggedIn ? (
      <div className='d-flex gap-3 '>
         <Button variant='outline-secondary p-md-3 w-100' style={{backgroundColor:"#ffffff",color:'#000000',fontWeight:'500', borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}}  onClick={() => { handleCloseCart();navigate('/login');  trackEvent('Sign in Click', {
  clicked_at: new Date().toISOString(),
  location_clicked: transformPath(window.location.pathname),
  button_clicked: 'Sign in'
});}}>Sign in</Button>
    <Button style={{borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'
}} className='p-3 w-100' onClick={() => {handleCloseCart();navigate('/signup');  trackEvent('Create an Account Click', {
  clicked_at: new Date().toISOString(),
  location_clicked: transformPath(window.location.pathname),
  button_clicked: 'Create an account'
});}}>Create Account</Button>
      </div>
    ):  
    <div className='d-flex gap-3 '>
    <Button variant='outline-secondary p-md-3 w-100' style={{backgroundColor:"#ffffff",color:'#000000',fontWeight:'500', borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'

}}  onClick={()=> {navigate('/'); handleCloseCart()}}>Continue Shopping</Button>
<Button style={{backgroundColor: isCheckoutDisabled ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} className='p-md-3 w-100' onClick={()=> {handleCheckout(); trackEvent('Checkout click', {
  clicked_at: new Date().toISOString(),
  location_clicked: transformPath(window.location.pathname),
  button_clicked: 'Checkout',
  user:userData?.email
});}} disabled={isCheckoutDisabled}>Checkout</Button>
 </div>}
  
  </div> </>}
          </div>
        ) : (
          <p className='mt-3'>Your cart is empty.</p>
        )}
      </div>
    </div> :  
    
    <Offcanvas size='lg' show={showCart}  placement="end" className='py-4 px-1 px-md-3'style={{backgroundColor:'#FCFCFC'}} onHide={handleCloseCart}>
      <div className='d-flex gap-3 align-items-center px-2 px-md-0'> <img src={arrowLeft} onClick={handleCloseCart}/>
       <h5 className='my-auto'style={{color:'#0D111B', fontWeight:'600'}}>Cart</h5>
      </div>
      <Offcanvas.Body>
        {cartItems.length > 0 ? (
          <div className='mb-5 pb-3'>
            {cartItems.map((item) => (
              <Card key={item?.id} style={{border:'none'}} className='mb-4'>
                <div className='d-flex gap-3 align-items-center py-2' style={{borderBottom:'1px solid #fcfcfc'}}>
                  <img src={item?.productImage} style={{width:'70px', height:'70px',borderRadius:'20px'}}className='ms-3'/>
                  <div className='me-3 mt-3'>
                    <p className='mb-0 pb-1' style={{fontWeight:'500', color:'#344054'}}>{item?.productName}</p>
                  <div className='m-0 p-0 d-flex gap-2 align-items-center '>
            <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'500'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.price)}</p>

          {item?.OldPrice && (<p className='' style={{color:'#dfdcdc',textDecoration:'line-through', fontFamily:'Outfit', fontWeight:'500'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.OldPrice)}</p>)}
             

         </div>
                  </div>
                </div>
                 <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
                  <div className='d-flex gap-3 align-items-center'>
                  <div onClick={() => handleRemoveItem(item?.id)} className='d-flex gap-1 align-items-center'>  <img src={trash} alt='trash'/><p  style={{color:'#394357'}}className='my-auto'> Delete</p></div>
                  <div className='d-flex gap-1 align-items-center'>  
                  <i
                  onClick={() => toggleFill(item?.id)}
                  className={`bi ${filledHearts[item?.id] ? 'bi-heart-fill' : 'bi-heart'}`}
                  style={{
                    fontSize: '0.85rem',
                    color: filledHearts[item?.id] ? '#469B12' : '#394357', 
                    cursor: 'pointer',
                  }}
                ></i> 
                 <p className='my-auto' style={{color: filledHearts[item?.id] ? '#469B12' : '#394357'}}> {/* Green if in wishlist, black otherwise */}
    {filledHearts[item?.id] ? 'Added to Wishlist' : 'Add to Wishlist'}
  </p>
                   </div>
                   </div>
                  
       <div className="btn-group btn-group-sm gap-1" >
         <Button variant='outline-secondary' onClick={() => handleDecreaseQuantityInCart(item?.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'24px', height:'24px'}}>-</Button>
         <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'24px', height:'24px', fontWeight:'500'}}>{item.quantity}</Button>
         <Button variant='outline-secondary' onClick={() => handleIncreaseQuantityInCart(item?.id)} className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'24px', height:'24px'}}>+</Button>
       </div>
    
     </div>
                
                 
                
              </Card>
            ))}
 {loading ? <div className='justify-content-center align-items-center text-center' style={{ padding: '50px' }}>
      <div className="spinner-border text-success" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div> : <>
            <Card style={{border:'none'}} className=''>
             
              <div className='p-3' style={{borderBottom:'1px solid #F8F8F9'}}>
              <p style={{color:'#0D111B'}} className='mb-2'>Summary</p>
              <div className='d-flex justify-content-between'>
                <p style={{color:'#667085'}}>Subtotal <span style={{color:'#B3BCCD'}}>({totalItems} {totalItems === 1 ? 'item' : 'items'})</span></p>
                <p style={{color:'#0D111B', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</p>
              </div>
            
              </div>
              <div className='py-2 px-3'>
                <div className='d-flex justify-content-between'>
                  <p style={{color:'#394357', fontWeight:'500'}}>Total</p>
                  <p style={{color:'#0D111B', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</p>
                </div>
              </div>
            </Card>
          
            {additionalAmountNeeded > 0 && (
              <div style={{border:'1px solid #469B12', borderLeft:'2px solid #469B12', backgroundColor:'rgba(70, 155, 18, 0.1)'}} className='p-2 mt-3'>
   <p className="my-auto" style={{color:'#469B12'}}>
   Add items worth at least <span style={{fontWeight:'700'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(additionalAmountNeeded)}</span> to reach the  <span style={{fontWeight:'700'}}>₦12,000</span> minimum basket amount.
    </p>
    </div>
  )}

  <div className='mt-4 pt-3 mb-5'>
    { showAuthButtons && !isLoggedIn ? (
      <div className='d-flex gap-3 '>
         <Button variant='outline-secondary p-md-3 w-100' style={{backgroundColor:"#ffffff",color:'#000000',fontWeight:'500', borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'

}}  onClick={() => { handleCloseCart();navigate('/login');  trackEvent('Sign in Click', {
  clicked_at: new Date().toISOString(),
  location_clicked: transformPath(window.location.pathname),
  button_clicked: 'Sign in'
});}}>Sign in</Button>
    <Button style={{borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'
}} className='p-3 w-100' onClick={() => {handleCloseCart();navigate('/signup');  trackEvent('Create an Account Click', {
  clicked_at: new Date().toISOString(),
  location_clicked: transformPath(window.location.pathname),
  button_clicked: 'Create an account'
});}}>Create Account</Button>
      </div>
    ):  
    <div className='d-flex gap-3 '>
    <Button variant='outline-secondary p-md-3 w-100' style={{backgroundColor:"#ffffff",color:'#000000',fontWeight:'500', borderRadius:'8px', border:'none', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'

}}  onClick={()=> {navigate('/'); handleCloseCart()}}>Continue Shopping</Button>
<Button style={{backgroundColor: isCheckoutDisabled ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} className='p-md-3 w-100' onClick={()=> {handleCheckout(); trackEvent('Checkout click', {
  clicked_at: new Date().toISOString(),
  location_clicked: transformPath(window.location.pathname),
  button_clicked: 'Checkout',
  user:userData?.email
});}} disabled={isCheckoutDisabled}>Checkout</Button>
 </div>}
  
  </div></>}
          </div>
        ) : (
          <p className='mt-3'>Your cart is empty.</p>
        )}
      </Offcanvas.Body>
    </Offcanvas>}
     
    </>
   
  );
};

export default CartOffCanvas;
