import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import fb from '../../assets/fb-share.svg'
import twitter from '../../assets/twitter-share.svg'
import instagram from '../../assets/instagram-share.svg'
import whatsapp from '../../assets/whatsapp-share.svg'
import copy from '../../assets/copy-link.svg'
import { useAppContext } from '../../AppContext';
import { CloseButton } from 'react-bootstrap';


const ShareModal = ({ show, handleClose, url }) => {
    const {showToast}=useAppContext();

    const shareToSocialMedia = (platform, url) => {
        let shareUrl = "";
        const encodedUrl = encodeURIComponent(url);
      
        switch (platform) {
          case 'twitter':
            shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}`;
            break;
          case 'facebook':
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
            break;
          case 'whatsapp':
            shareUrl = `https://api.whatsapp.com/send?text=${encodedUrl}`;
            break;
          case 'instagram':
            navigator.clipboard.writeText(url).then(() => {
                showToast({
                    toastText:'URL copied to clipboard! You will be redirected to Instagram where you can paste in your story or post.',
                    isSuccess:true
                  })
              // Direct users to Instagram after copying the URL.
              window.open('https://www.instagram.com', '_blank');
            }).catch(err => {
             
              console.error('Failed to copy the URL: ', err);
             
              showToast({
                toastText:'Failed to copy URL. Please copy it manually',
                isSuccess:false
              })
            });
           
            return;
          default:
            console.error("Unsupported platform: ", platform);
            return; 
        }
      
        if (shareUrl) window.open(shareUrl, '_blank');
      };


  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
     
     
      showToast({
        toastText:'URL copied to clipboard!',
        isSuccess:true
      })
    });
  };

  return (
    <Modal show={show} onHide={handleClose} centered >
      {/* <Modal.Header closeButton > </Modal.Header> */}

      <Modal.Body>
        <div style={{borderBottom:'1px solid #EAECF0'}} className='mb-3 d-flex justify-content-between align-items-center'>
    <h4 style={{color:'#344054', fontWeight:'600'}}>Share via</h4>
    <CloseButton onClick={handleClose}/>
        </div>
        <div className='d-flex align-items-center gap-0 gap-md-3' style={{}}>

        <div onClick={() => shareToSocialMedia('instagram')} className="d-flex flex-column text-center text-align-center align-items-center justify-content-center me-2" style={{width:''}}><img src={instagram} className='share-icon'  alt='social icon'/><p style={{color:'#667085'}}></p>Instagram</div>

        <div onClick={() => shareToSocialMedia('twitter')} className="d-flex flex-column text-center justify-content-center text-align-center align-items-center me-2" style={{width:''}}><img src={twitter} className='share-icon'alt='social icon'/><p style={{color:'#667085'}}></p>Twitter</div>

        <div onClick={() => shareToSocialMedia('whatsapp')} className="d-flex flex-column text-center justify-content-center text-align-center align-items-center me-1" style={{width:''}}><img src={whatsapp} className='share-icon' alt='social icon'/><p style={{color:'#667085'}}></p>WhatsApp</div>

        <div onClick={() => shareToSocialMedia('facebook')} className="d-flex flex-column text-center justify-content-center text-align-center align-items-center me-1" style={{width:''}}><img src={fb} className='share-icon' alt='social icon'/><p style={{color:'#667085'}}></p>Facebook</div>
        
        <div onClick={copyToClipboard} className="d-flex flex-column text-center justify-content-center text-align-center align-items-center "><img src={copy} className='share-icon' alt='social icon'/><p style={{color:'#667085'}}></p>Copy Link</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal
