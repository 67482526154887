import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useSavePreviousPath = () => {
  const location = useLocation();
  const prevLocation = useRef(location.pathname + location.search); // Include query parameters

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.setItem('preAuthPath', prevLocation.current);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Update previous location only when location changes
    const currentPath = location.pathname + location.search;
    if (currentPath !== prevLocation.current) {
      localStorage.setItem('preAuthPath', prevLocation.current);
      prevLocation.current = currentPath;
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location]);

  // Optionally return the previous path for immediate access
  return prevLocation.current;
};

export default useSavePreviousPath;
