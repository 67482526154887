import {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet';

import { useParams, Link, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import GuestNavbar, { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { localStorageGetItem, localStorageSetItem } from '../../App';
import { Row, Col, Card, Button,Modal,Image } from 'react-bootstrap';
import arrow from '../../assets/typcn_arrow-back-outline.svg'
import { useAppContext } from '../../AppContext';
import love from '../../assets/love.svg'
import { trackEvent } from '../../mixpanel';
import foodBox from '../../assets/food-box.png'
import productRoute from '../../services/productRoute';
import yam from '../../assets/yam.png'
import SubscriptionModal from '../../components/Modal/SubscriptionModal';
import arrowLeft from '../../assets/arrow-left.svg'
import SidebarNavbar from '../../components/Navbar/SideNavbar';
import bag from '../../assets/bag-outline.svg'

const UserSubscriptions = () => {
    const {showToast} = useAppContext()
    const [subscriptionBoxes, setSubscriptionBoxes] = useState([]);
    const [activeSubscriptions, setActiveSubscriptions] = useState('All');
    const [showSubscriptionModal, setShowSubscriptionModal] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const location = useLocation();
    const [userBoxes, setUserBoxes] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
const {getAllBoxes,getUserBoxes,getSingleBoxes}= productRoute()
    const userData = getParsedLocalStorageItem('userData');
    const userToken = localStorageGetItem
('userToken');;
    const isLoggedIn = !!userData && !!userToken;
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

    
   
    useEffect(() => {
      let timeoutId = null;
      const handleResize = () => {
        clearTimeout(timeoutId); // Clear any previously set timeout
        timeoutId = setTimeout(() => {
          setIsMobile(window.innerWidth < 576);
        }, 150); // Adjust debounce time as needed
      };
    
      window.addEventListener('resize', handleResize);
    
      // Cleanup the event listener on component unmount
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('resize', handleResize);
      };
    }, []);

      useEffect(()=>{
   
       if(isLoggedIn){
        getUserBoxes(
            setLoading, showToast, setUserBoxes, 
         
          )
       }
      
    },[isLoggedIn])

  const handleBack = () => {
   
    navigate(-1);
  }
 
  const handleCancel = (subscription) => {

 
    trackEvent('Cancel Subscription Clicked', {
      'Subscription ID': subscription?.id,
      'Box Name': subscription?.boxes?.box?.name,
     
      'User': userData?.email || 'Anonymous',
      // Any other relevant properties
    });
  
  navigate(`/profile/subscriptions/cancel/${subscription?.id}`,{ state: { subscription } });
  };



const handleShowBoxModal = (subscription) => {


    setSelectedSubscription(subscription)
        setShowSubscriptionModal(true);
        trackEvent(`${subscription?.name} Viewed`, {
          'User': userData?.email || 'Anonymous',
        
        });
      
      };
    
      const handleCloseSubscriptionModal = () => {
        setShowSubscriptionModal(false);
      };
  return (
    <div>
      {isMobile ? ( <div className='py-5 my-5 my-sm-0 '>

      <div className='mb-4' style={{borderBottom:'1px solid #DADADAB2'}}>
          <div className='d-flex d-sm-none align-items-center gap-3 px-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
               <h5 style={{color:'#344054', fontWeight:'500', fontSize:'18px'}} className='my-auto'>My Subscriptions</h5>
              
           </div>
           </div>

           {loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>

      { userBoxes?.length > 0 ? ( 
      
      <Row className='mt-5 px-3'>

        {userBoxes?.map((subscription)=> (
          <Col xs={12} md={3} className='mb-5 mb-md-4 px-2'  key={subscription?.id} >
         
            <Row>
              <Col xs={8}>
             
          <h5 style={{color:'#344054', fontWeight:'600'
          }} className='mb-0 pb-1'>
          {subscription?.boxes?.box?.name}
          </h5>
         
       
          <p style={{color:'#98A2B3'}} className='mb-0 pb-2'>{subscription?.boxes?.box?.description}</p>
          <p style={{ fontFamily: 'Outfit', color: '#4F4F4F', fontWeight: '600'}} className='mb-0 p b-1'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subscription?.boxes?.box?.amount)}
          </p>
            
           
          <div className='d-flex gap-3 align-items-center mt-2'>
            
          {subscription?.status === 'cancelled' || subscription?.status === 'inactive' ? <a className='text-warning' style={{textDecoration:'none'}}>Inactive</a> : <a className='text-danger text-decoration-underline' onClick={() => handleCancel(subscription)}>Cancel Subscription</a>} 
                     
          
                
                 
             </div>
              </Col>
              <Col xs={4}>
              <div className='position-relative mb-3 h-100'>
                  <Card.Img src={subscription?.boxes?.box?.boxImage}  style={{objectFit:'cover'}} alt='food box' className='h-100'/>
                  <Card.ImgOverlay style={{}} className='d-flex justify-content-center align-items-start mt-4 pt-5' > 
<p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'6px', width:'auto', cursor:'pointer'}} onClick={()=>handleShowBoxModal(subscription?.boxes?.box)} className='px-2'><img src={arrow} alt='arrow'/> Look inside</p>
 </Card.ImgOverlay>
                 
                                           
                  </div>
              </Col>
            </Row>
             
          
           </Col>
        ))}

</Row>
     ): <div className='py-5 px-3 px-md-5'>
       
       <div className="d-flex flex-column gap-3 justify-content-center justify-items-center text-center fw-medium mt-5 pt-5">
<div className='justify-content-center'>
     <Image fluid src={bag} alt='' style={{width:'120px', height:'120px'}} />
</div>
       
        <h3 style={{fontWeight:'600', color:'#1D2939'}}>You have no subscriptions</h3>
      </div>
     </div>
     }
     </>
      )}

 
</div>): ( 
        <div className='py-5 px-3 px-xxl-5 mx-lg-5 mb-5 mb-sm-0'>

<div className="d-flex gap-2 align-items-center mb-3" >  
<Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-left" style={{color:'#1D2939'}} ></i></p></Link>

<p className='' style={{color:'#1D2939', fontWeight:'600'}} > Subscriptions </p>
</div>
<Row>
<Col xs={12} sm={3} className='d-none d-sm-block'>
    <SidebarNavbar/>
</Col>
<Col xs={12} sm={9} className='ps-lg-4 pe-lg-5'>
          <div>
         

           
          
        {loading ? (
        <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>

      { userBoxes?.length > 0 ? ( 
      
      <Row className='mt-5'>

        {userBoxes?.map((subscription)=> (
          <Col xs={6} md={4} className='mb-3 mb-md-4'  key={subscription?.id} >
           <Card className='border-0 h-100' style={{boxShadow: '0px 4px 4px 0px #00000040'
          }} >
                  <Card className='position-relativeh-100 border-0'>
                  <Card.Img src={subscription?.boxes?.box?.boxImage}  style={{borderBottomLeftRadius:'0px', borderBottomRightRadius:'0px', height:'100%',maxHeight:'250px',objectFit:'cover', width:'100%'}} alt='food box'/>
                  <Card.ImgOverlay style={{}} className='d-flex justify-content-center align-items-center mt-4' > 
 <p  style={{color:'#469B12', backgroundColor:'#ffffff', border:'1px solid #469B12', borderRadius:'8px', fontWeight:'500', fontSize:'18px', width:'auto', cursor:'pointer'}} onClick={()=>handleShowBoxModal(subscription?.boxes?.box)} className='px-2'><img src={arrow} alt='arrow'/> Look inside</p>
  </Card.ImgOverlay>
                 
                                           
                  </Card>
                 
                  
                  < Card.Body className='px-3 pt-2 mt-0 pb-4'>
                  <div className='d-flex justify-content-between align-items-center mb-2'>
          <h5 style={{color:'#344054', fontWeight:'600'
          }} className='my-0 py-0'>
          {subscription?.boxes?.box?.name}
          </h5>
          <p style={{ fontFamily: 'Outfit', color: '#4F4F4F', fontWeight: '600' }} className='my-auto'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subscription?.boxes?.box?.amount)}
          </p>
          </div>
          <p style={{color:'#4F4F4F', fontSize:'12px'}}>{subscription?.boxes?.box?.description}</p>
              
            
           
             <div className='d-flex gap-3 align-items-center'>
            
           {subscription?.status === 'cancelled' || subscription?.status === 'inactive' ? <a className='text-warning' style={{textDecoration:'none'}}>Inactive</a> : <a className='text-danger text-decoration-underline' onClick={() => handleCancel(subscription)}>Cancel Subscription</a>} 
                     
          
                
                 
             </div>
          </Card.Body>
           
                   
                   
                 
                </Card>
          
           </Col>
        ))}

</Row>
     ): <div className='py-5 px-3 px-md-5'>
       
       <div className="d-flex flex-column gap-4 justify-content-center justify-items-center text-center fw-medium">
<div className='justify-content-center'>
<Image fluid src={bag} alt='' style={{width:'200px', height:'200px'}} />
</div>
       
        <h2 style={{fontWeight:'600', color:'#1D2939'}}>You have no subscriptions</h2>
      </div>
     
     </div>
     }
     </>
      )}
  </div>
</Col>
</Row>
 
</div>)}
      
<SubscriptionModal showModal={showSubscriptionModal} handleCloseModal={handleCloseSubscriptionModal} selectedSubscription={selectedSubscription}/>
    </div>
  )
}

export default UserSubscriptions