


import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Modal, Form, Image } from "react-bootstrap";
import eye from "../../assets/eye.svg";
import arrowLeft from "../../assets/arrow-left.svg";

import logo from "../../assets/virtue-logo-2.png";

import { useAppContext } from "../../AppContext";
import authRoute from "../../services/authRoute";

const ResetPasswordModal = () => {


  const { showToast } = useAppContext();
  const { resetForgotPassword } = authRoute();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(true);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  
  const closeModal = () => {
    setShowModal(false);
    navigate("/");
  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const checkPasswordStrength = (password) => {
    const passwordRegex = /^.{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!formData.confirmPassword) {
      validationErrors.confirmPassword = "Please confirm password";
    } else if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
    }

    if (!checkPasswordStrength(formData.password)) {
      validationErrors.password = "Password must have at least 8 characters";
    }
   
    const body ={
      password:formData.password,
      token:token
    }

    resetForgotPassword(setLoading, body, showToast);
  
  };

  return (
    <Modal show={showModal} onHide={closeModal} centered>
      <Modal.Header closeButton className="d-none d-md-flex"></Modal.Header>
      <Modal.Body>
        <section className="login-form pt-3 pb-4 pt-lg-2  px-4">
          <button
            className="p-2 bg-white d-flex d-md-none border-0 align-items-center"
            style={{
              borderRadius: "20px",
              fontWeight: "700",
              boxShadow: "0px 1px 2px 0px #0000001A",
            }}
            onClick={closeModal}
          >
            {" "}
            <img src={arrowLeft} />
          </button>
          <div className="text-center">
            <Image fluid src={logo} style={{ width: "100px" }}  alt='logo' />
            <h2 className="mt-1 mb-3">Reset Password</h2>
          </div>

          <Form onSubmit={handleSubmit} className="mb-3">
            <Form.Group className="mb-2">
              <Form.Label>Password</Form.Label>
              <div className="position-relative">
                <button
                  type="button"
                  className=" border-0 position-absolute bg-white"
                  onClick={handleTogglePassword}
                  style={{ top: "20%", right: "3%" }}
                >
                  {showPassword ? (
                    <i
                      className="bi bi-eye-slash"
                      style={{ color: "#B0B0B0" }}
                    ></i>
                  ) : (
                    <img src={eye} style={{ width: "16px", height: "16px" }}  alt='eye icon'/>
                  )}
                </button>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Min. of 8 characters"
                  className=""
                  value={formData.password}
                  onChange={handleChange}
                />
                {error.password && (
                  <div className="text-danger" style={{ fontSize: "12px" }}>
                    {error.password}
                  </div>
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label>Confirm Password</Form.Label>
              <div className="position-relative">
                <button
                  type="button"
                  className=" border-0 position-absolute bg-white"
                  onClick={handleTogglePassword}
                  style={{ top: "20%", right: "3%" }}
                >
                  {showPassword ? (
                    <i
                      className="bi bi-eye-slash"
                      style={{ color: "#B0B0B0" }}
                    ></i>
                  ) : (
                    <img src={eye} style={{ width: "16px", height: "16px" }}  alt='eye icon' />
                  )}
                </button>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="••••••••"
                  className=""
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                {error.confirmPassword && (
                  <div className="text-danger" style={{ fontSize: "12px" }}>
                    {error.confirmPassword}
                  </div>
                )}
              </div>
            </Form.Group>

            <div className="mt-4 text-center">
              <Button type="submit" className="btn-primary w-50">
                Reset Password
              </Button>
            </div>
          </Form>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPasswordModal;