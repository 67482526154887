import React, {useState, useEffect, useCallback} from 'react';
import { Button, Card, Form, Row, Col, Dropdown, ListGroup, Popover, OverlayTrigger, Overlay } from 'react-bootstrap';
import { useAppContext } from '../../AppContext';
import arrowLeft from '../../assets/arrow-left.svg'
import  { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';
import { trackEvent } from '../../mixpanel';
// import { trackAddToCart, trackAddToWishlist, transformPath } from '../../App';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa'
import orderRoute from '../../services/orderRoute';
import trash from '../../assets/trash.svg'
import Select from 'react-select';
import Waitlist from '../../components/Modal/Waitlist';
import productRoute from '../../services/productRoute'
import useGoogleLoader from '../../hooks/useGoogleLoader';
import { localStorageGetItem, localStorageSetItem } from '../../App';




const SubscriptionSummary = () => {
  const {setWishlistItems, showToast, apiWishlistItems, setApiWishlistItems, setFormData, formData,updatedWishlist }= useAppContext();
  const {checkout, subscribe}= orderRoute();
  const [loading, setLoading] = useState(false);
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!token;

  // const [userBoxes, setUserBoxes] = useState([]);
  const {getGlobalProducts,getAllBoxes, getUserBoxes}= productRoute();
  const [isHeartFilled, setIsHeartFilled] = useState(false)
  const {updateWishlist, getWishlist, getDeliveryStates, getDeliveryLGAs,updateSingleWishlist}= orderRoute();
  const [target, setTarget] = useState(null);
  const [streetData, setStreetData]= useState({streetAddress:""});
  const googleLoader = useGoogleLoader();
  const [inputElement, setInputElement] = useState(null);
  const streetAddressAutoCompleteInput = useCallback(node => {
    if (node !== null) {
      setInputElement(node);
    }
  }, []);
  const [deliveryStates, setDeliveryStates] = useState([]);
  const navigate = useNavigate()
  const [checked, setChecked] = useState(false);
  const [globalProducts, setGlobalProducts] = useState([]);
  const [deliveryOption, setDeliveryOption] = useState(''); // 'in-store' or 'home'
  const [additionalFee, setAdditionalFee] = useState({amount: 0, type: 'Shipping'}); // Object to hold fee amount and type
  const [showLGAModal, setShowLGAModal] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const [showDeliveryForm, setShowDeliveryForm] = useState(false)
  const [isEditing, setIsEditing] = useState(true);
  const [selectOptions, setSelectOptions] = useState([]);
const [isSuccessful, setIsSuccessful] = useState(false)
  const [error, setError] = useState("");
  const [comment, setComment] = useState("");
const [filledHearts, setFilledHearts] = useState({});
  const location = useLocation();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showDropdown, setShowDropdown] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [showAlert, setShowAlert] = useState({});
  const [selectedInstructions, setSelectedInstructions] = useState([]);
  const [subscription, setSubscription] = useState(location?.state?.subscription);
  const [quantity, setQuantity] = useState(1);
  const [totalAmount, setTotalAmount] = useState(subscription?.amount);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  const [isPaymentDisabled, setIsPaymentDisabled] = useState(true);
  const [subscriptionBoxes, setSubscriptionBoxes] = useState([]);
  const [userBoxes, setUserBoxes] = useState([]);
const isFormValid = () => {
 
  return formData.streetAddress && formData.state && formData.LGA && formData.phoneNumber && formData.deliveryDate && formData.deliveryFrequency
 
};
const [originalLgaOptions, setOriginalLgaOptions] = useState([]);

const [lgaOptions, setLgaOptions] = useState([]);
const [showWaitlist, setShowWaitlist] = useState(false);


const formatDate = (date) => {
  return date.toLocaleDateString('en-CA');
};

// Generate readable date string
const readableDate = (date) => {
  return date.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' });
};

const calculateTuesdays = (year, month) => {
  let tuesdays = [];
  let firstDay = new Date(year, month, 1);
  // Find the first Tuesday
  let dayOfWeek = firstDay.getDay();
  let daysUntilFirstTuesday = (dayOfWeek <= 2) ? (2 - dayOfWeek) : (9 - dayOfWeek);
  let firstTuesday = new Date(year, month, 1 + daysUntilFirstTuesday);

  // Add up to the first 4 or 5 Tuesdays of the month
  for (let i = 0; i < 5; i++) {
    let nextTuesday = new Date(firstTuesday);
    nextTuesday.setDate(firstTuesday.getDate() + (i * 7));
    if (nextTuesday.getMonth() === month) {
      tuesdays.push(nextTuesday);
    }
  }

  return tuesdays;
};


const collectInstructions = () => {
  const instructions = {};
  subscription?.items?.forEach(item => {
    item?.productId?.shoppingInstructions?.forEach(instruction => {
      if (!instructions[instruction.name]) {
        instructions[instruction.name] = {
          fee: instruction.amount,
          id: instruction.id,
          items: []
        };
      }
      instructions[instruction.name].items.push({
        productName: item?.productId?.productName,
        productId:item?.productId?.id,
        instructionId: instruction.id
      });
    });
  });
  return instructions;
};



const instructionFees = collectInstructions();
const calculateServiceCosts = () => {
  return selectedCategories.reduce((total, category) => total + instructionFees[category].fee, 0);
};

const [lastActivity, setLastActivity] = useState(Date.now());

const handleUserActivity = useCallback(() => {
  setLastActivity(Date.now());
}, []);

useEffect(() => {
  // Event listeners to track user activity
  window.addEventListener('mousemove', handleUserActivity);
  window.addEventListener('keydown', handleUserActivity);
  window.addEventListener('scroll', handleUserActivity);
  window.addEventListener('click', handleUserActivity);

  // Function to check for inactivity
  const checkForInactivity = () => {
    const currentTime = Date.now();
    const timeDifference = currentTime - lastActivity;
    
    if (timeDifference >= 3600000) { // 1 hour in milliseconds
      showToast({toastText:'Session timeout', isSuccess:false})
      navigate('/');
    }
  };

  // Set interval to check for inactivity
  const interval = setInterval(checkForInactivity, 60000); // Check every minute

  // Cleanup event listeners and interval on component unmount
  return () => {
    window.removeEventListener('mousemove', handleUserActivity);
    window.removeEventListener('keydown', handleUserActivity);
    window.removeEventListener('scroll', handleUserActivity);
    window.removeEventListener('click', handleUserActivity);
    clearInterval(interval);
  };
}, [lastActivity, handleUserActivity, navigate]);

useEffect(() => {

    setTotalAmount(subscription?.amount * quantity);
  }, [quantity, subscription?.amount]);

useEffect(() => {
 
  if (formData.state) {
    const selectedState = deliveryStates.find(state => state.name === formData.state);
    if (selectedState) {
      getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
    }
  }
}, [formData.state, deliveryStates]);
useEffect(()=>{

  getDeliveryStates(
    showToast, setDeliveryStates 
  )
},[])



useEffect(() => {



  setIsPaymentDisabled(isEditing || !checked);
}, [checked,isEditing]);


useEffect(() => {
  if (isLoggedIn) {
    getUserBoxes(
      setLoading, showToast, setUserBoxes, 
   
    )
  }
}, [isLoggedIn]); 

useEffect(() => {
  let shippingFee = 0;
  let feeType = '';

 
    const selectedLGA = lgaOptions.find(lga => lga.value === formData.LGA);
    shippingFee = selectedLGA ? selectedLGA.deliveryFee : 0;
    feeType = 'Shipping Fee';
    setAdditionalFee({ amount: shippingFee, type: feeType });
 

  


}, [formData.LGA, lgaOptions]);


useEffect(() => {

    getGlobalProducts(setLoading, showToast, setGlobalProducts);

}, []);




 useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };
 
    window.addEventListener('resize', handleResize);
 
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let newGrandTotal;
    const serviceCostTotal = selectedCategories.length > 0 ? calculateServiceCosts() : 0;
   
    
  
    if (!isEditing) {
      const subtotal = totalAmount + additionalFee.amount + serviceCostTotal;
     
        newGrandTotal =subtotal;
      
    } else {
    
        newGrandTotal = totalAmount + serviceCostTotal;
     
    }
  
    setGrandTotal(newGrandTotal);
  }, [isEditing, totalAmount, additionalFee, selectedCategories]);
  
  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDay = today.getDay();
  
    let tuesdays = calculateTuesdays(currentYear, currentMonth);
  
    const eightHoursLater = new Date(today.getTime() + 8 * 3600 * 1000);
    tuesdays = tuesdays.filter(tuesday => tuesday >= eightHoursLater);
  
    if (tuesdays.length < 4) {
      const nextMonth = (currentMonth + 1) % 12;
      const nextYear = (currentMonth + 1 > 11) ? currentYear + 1 : currentYear;
      const nextMonthTuesdays = calculateTuesdays(nextYear, nextMonth);
      tuesdays = tuesdays.concat(nextMonthTuesdays.slice(0, 4 - tuesdays.length));
    }
  
    const options = tuesdays.slice(0, 4).map(date => ({
      value: formatDate(date),
      label: readableDate(date),
    }));
  
 
      setSelectOptions(options);
    
  }, []);
  
  
  useEffect(()=>{
   
    getAllBoxes(
      setLoading, showToast, setSubscriptionBoxes, 
     
    )
  
},[])
useEffect(() => {
  let autocompleteStreetAddress;

  if (isEditing && inputElement) {
    const handlePlaceSelect = (autocomplete) => {
      const place = autocomplete.getPlace();
      const formattedAddress = place.formatted_address || "";
      const addressComponents = place.address_components;

      const normalizeStateName = (stateName) => {
        const stateMap = {
          'Lagos': ['Lagos', 'Ìpínlẹ̀ Èkó', 'Lagos State'],
          'Ogun': ['Ogun', 'Ìpínlẹ̀ Ògùn', 'Ogun State']
        };

        for (const [key, values] of Object.entries(stateMap)) {
          if (values.includes(stateName)) {
            return key;
          }
        }
        return stateName;
      };

      const stateLongName = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name;
      const normalizedStateName = normalizeStateName(stateLongName);

      if (formData.state === normalizedStateName) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          streetAddress: formattedAddress,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          streetAddress: '',
        }));
        showToast({ toastText: `Selected place is not in ${formData.state}.`, isSuccess: false });
      }
    };

    const setAutocompleteBounds = (google, autocomplete) => {
      const lagosBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(6.3933, 3.1473),
        new google.maps.LatLng(6.7009, 3.4860)
      );

      const ogunBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(6.3445, 2.5993),
        new google.maps.LatLng(7.2948, 3.7181)
      );

      if (formData.state === 'Lagos') {
        autocomplete.setBounds(lagosBounds);
      } else if (formData.state === 'Ogun') {
        autocomplete.setBounds(ogunBounds);
      } else {
        autocomplete.setBounds(null);
      }
    };

    googleLoader.load().then((google) => {
      const options = {
        componentRestrictions: { country: "NG" },
      };

      autocompleteStreetAddress = new google.maps.places.Autocomplete(
        inputElement,
        options
      );

    

      autocompleteStreetAddress.addListener("place_changed", () => {
      
        handlePlaceSelect(autocompleteStreetAddress);
      });

      setAutocompleteBounds(google, autocompleteStreetAddress);
    }).catch((error) => {
      console.error('Error loading Google Maps API:', error);
    });

    return () => {
      if (autocompleteStreetAddress) {
        window.google.maps.event.clearInstanceListeners(autocompleteStreetAddress);
      }
    };
  }
}, [googleLoader, formData.state, setFormData, isEditing, inputElement]);

  
useEffect(()=> {
  trackEvent('Subscription Reviewed', {
    'User': userData?.email,
    attempted_at: new Date().toISOString(),
  })

},[])

 
useEffect(() => {
  if (isLoggedIn) {
    // Fetch the wishlist if logged in
    getWishlist(setLoading, showToast, setApiWishlistItems);
  }
}, [isLoggedIn]);

useEffect(() => {
  const checkWishlistStatus = () => {

    if(isLoggedIn){
   
      const isProductInWishlist = apiWishlistItems?.some(item => item?.box?.id === subscription?.id);
      setIsHeartFilled(isProductInWishlist);
    

    }else{
      const wishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
      // Determine if the selected product is in the wishlist
      const isProductInWishlist = wishlist?.some(item => item?.box?.id === subscription?.id);
      setIsHeartFilled(isProductInWishlist);
    
    };
    }
   
   

  // Call the function if subscription is defined
  if (subscription) {
    checkWishlistStatus();
  }
}, [subscription, apiWishlistItems]);
const toggleFill = () => {
  const productId= subscription?.id
  if(isLoggedIn){
    updateSingleWishlist (
      productId, 
      showToast, 
      isHeartFilled,
      setIsHeartFilled, apiWishlistItems,
      setApiWishlistItems, 
      subscriptionBoxes
    )
  } else {
    const wishlist = JSON.parse(localStorageGetItem
('wishlistItems')) || [];
    let toastText = '';
  if (isHeartFilled) {
    
    const newWishlist = wishlist.filter(item => item?.box?.id !== subscription.id);
    localStorageSetItem
('wishlistItems', JSON.stringify(updatedWishlist));
    setIsHeartFilled(false);
    updatedWishlist(newWishlist)
    setWishlistItems(newWishlist); 
    toastText = 'Item removed from Wishlist!';
  } else {
   
    const newWishlist = [{box:subscription},...wishlist ];
    localStorageSetItem
('wishlistItems', JSON.stringify(updatedWishlist));
    setIsHeartFilled(true);
    updatedWishlist(newWishlist)
    setWishlistItems(newWishlist);
    toastText = 'Item added to Wishlist!';
    const product = subscriptionBoxes.find(p => p.id === productId);
    // trackAddToWishlist(productId, product.productName);
  }
  showToast({
    toastText: toastText,
    isSuccess: true
  });
  const product = subscriptionBoxes.find(p => p.id === productId);
  trackEvent(!isHeartFilled ? `${product?.name} Added to Wishlist` : `${product?.name} Removed from Wishlist`, {
    'Product ID': productId,
    'Product Name': product?.name,
    'User': userData?.email || 'Anonymous',
 
  });
  }
  
 
};


  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    const isCheckbox = type === 'checkbox';
    setFormData({ ...formData, [name]: isCheckbox ? checked : value });
  
    if (name === "state") {
      const selectedState = deliveryStates.find(state => state.name === value);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
      }else{setLgaOptions([]); }
      setFormData((prevFormData) => ({
             ...prevFormData,
             streetAddress: ''
           }));
    }
  };
  

  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      const filteredLgas = originalLgaOptions.filter(lga => lga.label.toLowerCase().includes(inputValue.toLowerCase()));
      if (filteredLgas.length === 0 && inputValue.trim() !== "") {
        setLgaOptions([{ value: "comingSoon", label: `We are coming to ${inputValue} soon! Join our waitlist and be the first to know when we do.` }]);
      } else {
        setLgaOptions(filteredLgas);
      }
    }
  };
  


  const formatOptionLabel = ({ label, value }) => {
    if (value === "comingSoon") {
      // Split the label to insert styling on a specific part
      const firstPart = "We are coming to ";
      const secondPart = label.split('soon! ')[0].replace(firstPart, '');
      const thirdPart = "soon! ";
      const actionPart = "Join our waitlist and be the first to know when we do.";
     
  
      return (
        <div>
          {firstPart}
          <span>{secondPart}</span>
          {thirdPart}
          <span style={{ textDecoration: "underline", color:'#469B12 ' }}>{actionPart}</span>
        
        </div>
      );
    }
    // Return the label as is for other options
    return label;
  };
  
  const handleSelectChange = (selectedOption) => {
    if (!selectedOption) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        LGA: '' 
      }));
      setLgaOptions(originalLgaOptions)
      setAdditionalFee({ amount: 0, type: '' }); 
    } else if (selectedOption.value === "comingSoon") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        LGA: ''
      }));
      setShowWaitlist(true); 
      const selectedState = deliveryStates.find(state => state.name === formData.state);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
      }
    } else {
      const selectedLGA = lgaOptions.find(lga => lga.value === selectedOption.value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        LGA: selectedOption.value
      }));
      if (selectedLGA) {
        setAdditionalFee({ amount: selectedLGA.deliveryFee, type: 'Shipping Fee' });
      }
    }
  };
 


  const handleCategoryCheckboxChange = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
    );
    const subscriptions = selectedItems[category] || [];
    const eventName = selectedCategories.includes(category)
      ? 'Shopping Instructions Removed'
      : 'Shopping Instructions Added';

    trackEvent(`${category} ${eventName}`, {
      'User': userData?.email || 'Anonymous',
      'Shopping Instruction': category,
      'Selected Products': subscriptions.join(', '),
    });
  
  };

  const handleToggleDropdown = (category, targetElement) => {
    setTarget(targetElement);
    setShowDropdown(prevShowDropdown => ({
      ...prevShowDropdown,
      [category]: !prevShowDropdown[category]
    }));
  };

  const handleSelectItem = (item, category) => {
    setSelectedItems(prev => {
      const items = prev[category] || [];
      const newItems = items.includes(item?.productName)
        ? items.filter(i => i !== item?.productName)
        : [...items, item?.productName];

      // Automatically check the category if an item is selected
      if (newItems.length > 0 && !selectedCategories.includes(category)) {
        setSelectedCategories([...selectedCategories, category]);
        trackEvent(`${category} Shopping Instructions Added`, {
          'User': userData?.email || 'Anonymous',
          'Shopping Instruction': category,
          'Selected Products': newItems.join(', '),
        });
      } else if (newItems.length === 0) {
        setSelectedCategories(selectedCategories.filter(c => c !== category));
      }

      return {
        ...prev,
        [category]: newItems
      };
    });
    setShowAlert(prev => ({ ...prev, [category]: '' }));
  };

  const handleRemoveShoppingItem = (itemName, category) => {
    setSelectedItems(prev => {
      const newItems = prev[category].filter(i => i !== itemName);

      // Uncheck the category if no items remain selected
      if (newItems.length === 0) {
        setSelectedCategories(selectedCategories.filter(c => c !== category));
      }

      return {
        ...prev,
        [category]: newItems
      };
    });
  };

  const hasShoppingInstructions = Object.keys(instructionFees).length > 0;

  const handleIncrease = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleRemoveItem = () => {
    navigate('/subscriptions');
  };

  

  

  const handleStreetChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


 
  const isNigerianPhoneNumber = (phoneNumber) => {
    // This regex matches phone numbers starting with '070', '080', '090', '081', and '091'
    return /^0[7-9][0-1]\d{8}$/.test(phoneNumber) || /^091\d{8}$/.test(phoneNumber);
  };
 

  const handleEditDelivery = () => {
    setIsEditing(true);
    trackEvent('Edit Delivery Address', {
      clicked_at: new Date().toISOString(),
      'User': userData?.email ,
     
    })
  };
  const handleSaveDelivery = (e) => {
    e.preventDefault();
    
    const validationErrors = {};

    
  if (!formData.phoneNumber) {
    validationErrors.phoneNumber = "Please enter your Phone Number";
  } else if (formData.phoneNumber.length !== 11) {
    validationErrors.phoneNumber =
      "Please enter a valid 11-digit Nigerian phone number";
  }

    

    setError(validationErrors);

    if (Object.keys(validationErrors).length === 0) {

   
    setIsEditing(false)
    
    trackEvent('Add Delivery Details ', {
      'User': userData?.email ,
      'Address': `${formData.streetAddress}, ${formData.LGA}, ${formData.state}`,
      'Phone number': formData.phoneNumber,
      'Delivery date': formData.deliveryDate
     
      
    });
     
    }
  };
  







const handleContinue = () => {

    setShowDeliveryForm(true);
  
}




const handleBack = ()=> {
  setShowDeliveryForm(false);
}

const handleSubmitOrder = async () => {
  
    if(!isLoggedIn){
        navigate('/login')
        
    }
    else{
 
      // const userSubscription = userBoxes?.find(p => p?.boxes?.box?.id === subscription?.id);

      // if (userSubscription && userSubscription?.status?.toLowerCase() === 'active') {
      //   showToast({ toastText: "You have already subscribed for this box", isSuccess: false });
      //   return;
      // }
    


  const selectedState = deliveryStates.find(state => state.name === formData.state);
  const stateId = selectedState ? selectedState.id : null;


  const selectedLGA = lgaOptions.find(option => option.label === formData.LGA);
  const lgaId = selectedLGA ? selectedLGA.id : null;
 
  const selectedInstructions = selectedCategories.length > 0 ? selectedCategories.flatMap(category => {
    const uniqueInstructionIds = new Set();

    instructionFees[category].items.forEach(item => {
      if (selectedItems[category]?.includes(item.productName)) {
        uniqueInstructionIds.add(item.instructionId);
      }
    });

    return [...uniqueInstructionIds].map(instructionId => ({
      instruction: instructionId,
      products: instructionFees[category].items
        .filter(item => item.instructionId === instructionId && selectedItems[category]?.includes(item.productName))
        .map(item => item.productId)
    }));
  }) : [];
  
    const boxes = {
    box:subscription?.id,
    quantity:quantity
    }
    const phoneNumber = formData?.phoneNumber
    const address = {
      streetAddress: formData.streetAddress,
      city: lgaId,
      state: stateId,
      schoolOrWork: formData.isSchoolOrBusinessAddress,
      makeDefault: formData.isDefaultAddress,
  };
  const deliveryDate = new Date(formData.deliveryDate).toISOString();
  const frequency = formData?.deliveryFrequency

  const body = {
      boxes,
      deliveryDate,
      frequency,
      address,
      phoneNumber,
      
      
  };
  


  const orderComment = comment;
  
  if (orderComment) {
    body.orderComment = orderComment;
}




if (selectedCategories.length > 0) {
  body.shoppingInstructions = selectedInstructions;
}

   subscribe(body, subscription, setLoading, showToast);
  }
  



 
    
}





  

  return (
    <>
     {isMobile ? (<div className='px-lg-5 mx-lg-5 py-5'>
      {showDeliveryForm && (<> 
      <div className='pt-5 pb-3'>
              <div className='px-3' style={{borderBottom:'1px solid #D0D9E3'}}> 
              <div className='d-flex gap-3 align-items-center pb-3' > 
              <img src={arrowLeft} onClick={handleBack}/>
       <h6 className='my-auto'style={{color:'#0D111B', fontWeight:'700'}}>Payment & Delivery Information</h6>
      </div>
     
      </div> 
      <Card className='p-3 mb-4 ' style={{border:'none'}}>
          <div className='d-flex gap-3'>
        
      <h6 className='' style={{color:'#0D111B', fontWeight:'700'}}>Delivery Address</h6> 
      {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEditDelivery}>Edit</p>
      )}
    </div>

            <Form className='my-3' onSubmit={handleSaveDelivery}>
          
           

             <Row>
             <Col xs={12}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500', fontSize:'14px'}}>State/Province</p>
          
            <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.state || ''}
        name="state"
        required disabled={!isEditing}
      > <option value="" disabled>Select a state</option>
    
 {deliveryStates.map((state,index) => (
        <option value={state?.name} key={index}>{state?.name}</option>
    ))}
      </Form.Select>

            </Form.Group>
              </Col>
              <Col xs={12}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500',fontSize:'14px'}}>Delivery Area {isEditing && !formData?.LGA && <span>(Please select a state first)</span>}</p>
          
            <Select
         formatOptionLabel={formatOptionLabel}
          options={lgaOptions}
          onInputChange={handleInputChange}
          onChange={handleSelectChange}
         
        required
           value={lgaOptions.find(option => option.value === formData.LGA) || ''}

          name="LGA"
          isDisabled={!isEditing}
          isClearable={true}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 2000, // Ensure the dropdown has a high z-index
            }),
            menuList: (provided) => ({
              ...provided,
              zIndex: 2000, // Ensure the dropdown options have a high z-index
            }),
          }}
          
        />

            </Form.Group>
              </Col>
             </Row>
             <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500',fontSize:'14px'}}>Street Address {isEditing && !formData?.LGA && <span>(Please select a state first)</span>}</p>
            <div className="input-group">
            <Form.Control
  ref={streetAddressAutoCompleteInput}
  name="streetAddress"
  value={formData?.streetAddress}
  placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
  onChange={handleStreetChange}
  style={{ border: '1px solid #D7D7D7' }}
  required
  disabled={!isEditing}
/>

  {isEditing && formData.streetAddress && (
   <Button variant='outline-secondary'  onClick={() => setFormData({ ...formData, streetAddress: '' })}  >
    X</Button>
  )}
</div>
<p className='text-secondary mt-1' style={{fontSize:'12px'}}>Please ensure you enter the correct delivery address. Additional costs associated with a wrong delivery address will be your responsibility. Thank you!</p>


            </Form.Group>

            <Form.Group className='d-flex gap-1 align-items-center mb-3'>
            <Form.Check
    type='checkbox'
    name='isDefaultAddress'
    checked={formData.isDefaultAddress}
    onChange={handleChange} disabled={!isEditing}
  /> <p className='my-auto'style={{color:'#4F4F4F',fontSize:'14px'}}>Set as default address</p>
              </Form.Group>
            
            
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500',fontSize:'14px'}}>Phone</p>
            <Form.Control
  type='text'
  placeholder='Enter phone number'
  style={{border:'1px solid #D7D7D7'}}
  value={formData.phoneNumber}
  onChange={(event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    if (numericValue.length <= 11) {
      setFormData({ ...formData, phoneNumber: numericValue });
    }

    
    if (numericValue.length === 11 && !isNigerianPhoneNumber(numericValue)) {
      setError({...error, phoneNumber: 'Please enter a valid 11-digit Nigerian phone number.'});
    } else {
    
      setError({...error, phoneNumber: ''});
    }
  }}
  readOnly={!isEditing}
/>
{error.phoneNumber && <div className="text-danger" style={{fontSize:'12px'}}>{error.phoneNumber}</div>}

            </Form.Group>
         
            <Form.Group className='d-flex gap-1 align-items-center mb-3'>
              <Form.Check type='checkbox'
    name='isSchoolOrBusinessAddress'
    checked={formData.isSchoolOrBusinessAddress}
    onChange={handleChange} disabled={!isEditing}/> <p className='my-auto'style={{color:'#4F4F4F',fontSize:'14px'}}>Please check if this is either a school or business address</p>
              </Form.Group>
              
              <Form.Group className=' mb-3'>
             
              <p className='mb-1' style={{color:'#5D5D5D', fontWeight:'500',fontSize:'14px'}}>Delivery Frequency</p>
              <p className='mb-1' style={{color:'#000000',fontSize:'13px'}}>How often would you like your deliveries?</p>
   
      <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.deliveryFrequency}
        name="deliveryFrequency"
        required disabled={!isEditing}
      >
        <option value="" disabled>Select frequency</option>
        <option value='once'>Once</option> 
<option value='weekly'>Weekly</option>
    <option value='bi-weekly'>Bi-weekly</option>
    <option value='monthly'>Monthly</option>
</Form.Select>
              </Form.Group>

              <Form.Group className=' mb-3'>
             
              <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500',fontSize:'14px'}}>Delivery Date</p>
              <p className='mb-1' style={{color:'#000000',fontSize:'13px'}}>Select  your first delivery date</p>
    <div className="custom-select-wrapper">
      <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.deliveryDate}
        name="deliveryDate"
        required disabled={!isEditing}
      >
        <option value="" disabled>Select a date</option>
        {selectOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Form.Select>
      <FaRegCalendarAlt className="select-icon" />
    </div>
              </Form.Group>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500',fontSize:'14px'}}>Delivery Comment (optional)</p>
         
            <Form.Control
  type='text' placeholder='Add notes/comments to your order(s)' rows={3} className=' p-2 w-100' style={{border:'1px solid #D7D7D7'}}value={comment} onChange={(e)=> setComment(e.target.value)}
  disabled={!isEditing}
/>



            </Form.Group>

              {isEditing && (
        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>
      )}
            </Form>
            </Card>
          

 
            <Card style={{border:'none'}} className='p-3'>
            <h6 className='mb-4'style={{color:'#0D111B', fontWeight:'700'}}>Payment</h6>
             <div className='d-flex flex-column gap-0' style={{borderBottom:'1px solid #F8F8F9'}}>
            
             <div className='d-flex justify-content-between'>
               <p style={{color:'#667085'}}>Subtotal <span style={{color:'#4F4F4F'}}></span></p>
               <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</p>
             </div>
            
            
             {!isEditing && (
    <div className='d-flex justify-content-between'>
       <p style={{color:'#667085'}}>Shipping Fee <span style={{color:'#4F4F4F'}}></span></p>
    {additionalFee.amount === 0 ?   <p style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit'}}>Free delivery</p>
    :
    
      <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(additionalFee.amount)}</p>
    }
    </div>
  )}




           

             {selectedCategories.length > 0 && (
        <div className='d-flex flex-column gap-0'>
          <p className='mb-2' style={{ color: '#0D111B', fontWeight: '600' }}>Extra Services</p>
          {selectedCategories.map((category, index) => (
            <div className='d-flex justify-content-between' key={index}>
              <p style={{ color: '#667085' }}>{category} <span style={{ color: '#4F4F4F' }}></span></p>
              <p style={{ color: '#4F4F4F', fontWeight: '500', fontFamily: 'Outfit' }}>
                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(instructionFees[category].fee)}
              </p>
            </div>
          ))}
        </div>
      )}



             </div>
             <div className='py-2'>
               <div className='d-flex justify-content-between'>
                 <h6 style={{color:'#353542', fontWeight:'700'}}> Grand Total</h6>

                 <p style={{color:'#353542', fontWeight:'700', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(grandTotal)}</p>
               </div>
             </div>
             <div className='pt-2'>
            
             <div className='d-flex gap-1 align-items-center mt-1'>
              <Form.Check type='checkbox'   onChange={() => setChecked(!checked)}
                value={checked} required
   /> <p className='my-auto'style={{color:'#4F4F4F',fontWeight:'500'}}>Please check to acknowledge our <a href='/terms' style={{color:'#469B12'}}> Terms of Use</a> and <a href='/privacy-policy' style={{color:'#469B12'}}> Privacy Policy</a> </p>
              </div>
              
              <div className='my-3 px-3'>
              <Button   disabled={isPaymentDisabled}  onClick={handleSubmitOrder} className='py-2 w-100' style={{backgroundColor: isPaymentDisabled ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}}>Pay {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(grandTotal)}</Button>
              </div>
              
             </div>
           </Card>
           </div> </>)}
           {!showDeliveryForm && (<>
            <Card style={{backgroundColor:'#FCFCFC', borderRadius:'0px', border:'none'}} className='my-5'>
              <div className='py-3 '>
              <div className='px-3' style={{borderBottom:'1px solid #D0D9E3'}}> 
              <div className='d-flex gap-3 align-items-center pb-3' > 
              <img src={arrowLeft} onClick={()=> navigate(-1)}/>
       <h6 className='my-auto'style={{color:'#0D111B', fontWeight:'700'}}>Subscription Details</h6>
      </div>
     
      </div>
              </div>
          
              <div className='my-3 px-3'>
            
              <Card style={{border:'none',}} className='mb-4'>
                <div className='d-flex gap-3 align-items-center py-2' style={{borderBottom:'1px solid #fcfcfc'}}>
                  <img src={subscription?.boxImage} style={{width:'70px', height:'70px', borderRadius:'20px'}}className='ms-3'/>
                  <div className='me-3 mt-3'>
                    <p className='mb-0 pb-1' style={{fontWeight:'700', color:'#344054'}}>{subscription?.name}</p>
                  <div className='m-0 p-0 d-flex gap-2 align-items-center '>
            <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'700'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subscription?.amount)}</p>

         

         </div>
                  </div>
                </div>
                 <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
                  <div className='d-flex gap-3 align-items-center'>
                  <div onClick={handleRemoveItem} className='d-flex gap-1 align-items-center'> <img src={trash} alt='trash'/><p  style={{color:'#394357'}}className='my-auto'> Delete</p></div>
                 <div className='d-flex gap-1 align-items-center'>
             <i  onClick={() => toggleFill(subscription?.id)}
                 className={`bi ${isHeartFilled ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1.3rem',
                    color: isHeartFilled ? '#469B12' : '#394357', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }} ></i>
              <p className='my-auto' style={{color: isHeartFilled ? '#469B12' : '#394357'}}> {/* Green if in wishlist, black otherwise */}
    {isHeartFilled ? 'Added to Wishlist' : 'Add to Wishlist'}
  </p>
           
             </div>
                   </div>
                  
       <div className="btn-group btn-group-sm gap-1" >
         <Button variant='outline-secondary'  onClick={handleDecrease}  disabled={quantity === 1} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'24px', height:'24px'}}>-</Button>
         <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'24px', height:'24px', fontWeight:'700'}}>{quantity}</Button>
         <Button variant='outline-secondary' onClick={handleIncrease} className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'24px', height:'24px'}}>+</Button>
       </div>
    
     </div>
                
                 
                
              </Card>
      
             
            </div>

            </Card>
          
 {hasShoppingInstructions && (
     <Card style={{ border: 'none' }} className='p-3 mb-4'>
     <h6 className='mb-4' style={{ color: '#0D111B', fontWeight: '700' }}>Shopping Instructions</h6>
     <div className='d-flex flex-column gap-3'>
       {Object.keys(instructionFees).map((category, index) => (
         <div key={index}>
           <div className='d-flex align-items-center gap-4'>
             <Form.Group className='d-flex align-items-center gap-2'>
               <Form.Check
                 type='checkbox'
                 className='d-flex align-items-center gap-2'
                 checked={selectedCategories.includes(category)}
                 onChange={() => {
                   if (selectedItems[category]?.length > 0) {
                     handleCategoryCheckboxChange(category);
                     setShowAlert(prev => ({ ...prev, [category]: '' }));
                   } else {
                     setShowAlert(prev => ({ ...prev, [category]: 'Please select at least one item first.' }));
                   }
                 }}
               />
               <h6 className='my-auto'>{category}</h6>
             </Form.Group>
  
             <div
               className='shopping-container w-100 justify-content-between align-items-center'
               style={{
                 cursor: 'pointer',
                 border: '1px solid #ced4da',
                 borderRadius: '8px',
                 padding: '0.35rem 0.75rem',
                 minHeight: '38px',
                 gap: '5px'
               }}
               onClick={(e) => handleToggleDropdown(category, e.currentTarget)}
             >
               {selectedItems[category]?.length > 0 ? (
                 <div className='item-container w-100'>
                   {selectedItems[category].map((item, index) => (
                     <Button
                       variant='outline-secondary'
                       style={{
                         color: '#11142D',
                         border: '1px solid #E7E8EA',
                         fontWeight: '500',
                         fontSize: '11px',
                         boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'
                       }}
                       key={index}
                       onClick={(e) => {
                         e.stopPropagation();
                         handleRemoveShoppingItem(item, category);
                       }}
                     >
                       {item} &nbsp; X
                     </Button>
                   ))}
                 </div>
               ) : (
                 <span style={{ color: '212529BF', fontWeight: '400', fontSize: '12px' }} className='my-auto align-iems-center'>Select items for {category}</span>
               )}
               <i className='bi bi-chevron-down'></i>
             </div>
  
             <Overlay
               show={showDropdown[category]}
               target={target}
               placement="bottom"
               rootClose={true}
               onHide={() => setShowDropdown(prev => ({ ...prev, [category]: false }))}
             >
               <Popover id={`popover-positioned-bottom-${category}`}>
                 <Popover.Header as="h5">{`Select the item for ${category}`}</Popover.Header>
                 <Popover.Body>
                   {instructionFees[category].items.map((item, index) => (
                     <ListGroup.Item key={index} as='div' className='ms-1 py-2'>
                       <Form.Check
                         type='checkbox'
                         label={item.productName}
                         checked={selectedItems[category]?.includes(item.productName)}
                         onChange={() => handleSelectItem(item, category)}
                         className='d-flex align-items-center gap-2'
                       />
                     </ListGroup.Item>
                   ))}
                 </Popover.Body>
               </Popover>
             </Overlay>
           </div>
  
           {showAlert[category] && (
             <div className="text-danger" style={{ fontSize: '12px' }}>{showAlert[category]}</div>
           )}
         </div>
       ))}
     </div>
   </Card>
)}  
            <div className='mx-5 mb-5 pb-5'>
            <Button
              onClick={handleContinue}
              className='w-100 p-md-3'
            >
              Continue
            </Button>
            </div>
           </>)}
           </div>) :
 
       <div style={{backgroundColor:'#F4F4F5'}} className='px-5 py-5'>
       <div className='px-xxl-5 mx-xxl-5'>
        
        <Row>
          <Col xs={12} md={5}>
            <Card style={{backgroundColor:'#FCFCFC', borderRadius:'0px', border:'none'}}>
              <div className='py-3 '>
              <div className='px-3' style={{borderBottom:'1px solid #D0D9E3'}}> 
              <div className='d-flex gap-3 align-items-center pb-3' > 
              <img src={arrowLeft} onClick={()=> navigate(-1)}/>
       <h6 className='my-auto'style={{color:'#0D111B', fontWeight:'700'}}>Subscription Details</h6>
      </div>
     
      </div>
              </div>
          
              <div className='my-3 px-3'>
            
              <Card style={{border:'none',}} className='mb-4'>
                <div className='d-flex gap-3 align-items-center py-2' style={{borderBottom:'1px solid #fcfcfc'}}>
                  <img src={subscription?.boxImage} style={{width:'70px', height:'70px', borderRadius:'20px'}}className='ms-3'/>
                  <div className='me-3 mt-3'>
                    <p className='mb-0 pb-1' style={{fontWeight:'700', color:'#344054'}}>{subscription?.name}</p>
                  <div className='m-0 p-0 d-flex gap-2 align-items-center '>
            <p style={{ fontFamily:'Outfit', color:'#1D2939', fontWeight:'700'}} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(subscription?.amount)}</p>

         

         </div>
                  </div>
                </div>
                 <div className='px-3 py-2 d-flex justify-content-between align-items-center'>
                  <div className='d-flex gap-3 align-items-center'>
                  <div onClick={handleRemoveItem} className='d-flex gap-1 align-items-center'> <img src={trash} alt='trash'/><p  style={{color:'#394357'}}className='my-auto'> Delete</p></div>
                 <div className='d-flex gap-1 align-items-center'>
             <i  onClick={() => toggleFill(subscription?.id)}
                 className={`bi ${isHeartFilled ? 'bi-heart-fill' : 'bi-heart'}`}

                  style={{
                    fontSize: '1.3rem',
                    color: isHeartFilled ? '#469B12' : '#394357', // Ensures the filled heart is green
                    cursor: 'pointer',
                  }} ></i>
              <p className='my-auto' style={{color: isHeartFilled ? '#469B12' : '#394357'}}> {/* Green if in wishlist, black otherwise */}
    {isHeartFilled ? 'Added to Wishlist' : 'Add to Wishlist'}
  </p>
           
             </div>
                   </div>
                  
       <div className="btn-group btn-group-sm gap-1" >
         <Button variant='outline-secondary'  onClick={handleDecrease}  disabled={quantity === 1} className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'24px', height:'24px'}}>-</Button>
         <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'24px', height:'24px', fontWeight:'700'}}>{quantity}</Button>
         <Button variant='outline-secondary' onClick={handleIncrease} className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'24px', height:'24px'}}>+</Button>
       </div>
    
     </div>
                
                 
                
              </Card>
      
             
            </div>

            </Card>

            
{hasShoppingInstructions && (
   <Card style={{ border: 'none' }} className='p-3 my-4'>
   <h6 className='mb-4' style={{ color: '#0D111B', fontWeight: '700' }}>Shopping Instructions</h6>
   <div className='d-flex flex-column gap-3'>
     {Object.keys(instructionFees).map((category, index) => (
       <div key={index}>
         <div className='d-flex align-items-center gap-4'>
           <Form.Group className='d-flex align-items-center gap-2'>
             <Form.Check
               type='checkbox'
               className='d-flex align-items-center gap-2'
               checked={selectedCategories.includes(category)}
               onChange={() => {
                 if (selectedItems[category]?.length > 0) {
                   handleCategoryCheckboxChange(category);
                   setShowAlert(prev => ({ ...prev, [category]: '' }));
                 } else {
                   setShowAlert(prev => ({ ...prev, [category]: 'Please select at least one item first.' }));
                 }
               }}
             />
             <h6 className='my-auto'>{category}</h6>
           </Form.Group>

           <div
             className='shopping-container w-100 justify-content-between align-items-center'
             style={{
               cursor: 'pointer',
               border: '1px solid #ced4da',
               borderRadius: '8px',
               padding: '0.35rem 0.75rem',
               minHeight: '38px',
               gap: '5px'
             }}
             onClick={(e) => handleToggleDropdown(category, e.currentTarget)}
           >
             {selectedItems[category]?.length > 0 ? (
               <div className='item-container w-100'>
                 {selectedItems[category].map((item, index) => (
                   <Button
                     variant='outline-secondary'
                     style={{
                       color: '#11142D',
                       border: '1px solid #E7E8EA',
                       fontWeight: '500',
                       fontSize: '11px',
                       boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'
                     }}
                     key={index}
                     onClick={(e) => {
                       e.stopPropagation();
                       handleRemoveShoppingItem(item, category);
                     }}
                   >
                     {item} &nbsp; X
                   </Button>
                 ))}
               </div>
             ) : (
               <span style={{ color: '212529BF', fontWeight: '400', fontSize: '12px' }} className='my-auto align-iems-center'>Select items for {category}</span>
             )}
             <i className='bi bi-chevron-down'></i>
           </div>

           <Overlay
             show={showDropdown[category]}
             target={target}
             placement="bottom"
             rootClose={true}
             onHide={() => setShowDropdown(prev => ({ ...prev, [category]: false }))}
           >
             <Popover id={`popover-positioned-bottom-${category}`}>
               <Popover.Header as="h5">{`Select the item for ${category}`}</Popover.Header>
               <Popover.Body>
                 {instructionFees[category].items.map((item, index) => (
                   <ListGroup.Item key={index} as='div' className='ms-1 py-2'>
                     <Form.Check
                       type='checkbox'
                       label={item?.productName}
                       checked={selectedItems[category]?.includes(item.productName)}
                       onChange={() => handleSelectItem(item, category)}
                       className='d-flex align-items-center gap-2'
                     />
                   </ListGroup.Item>
                 ))}
               </Popover.Body>
             </Popover>
           </Overlay>
         </div>

         {showAlert[category] && (
           <div className="text-danger" style={{ fontSize: '12px' }}>{showAlert[category]}</div>
         )}
       </div>
     ))}
   </div>
 </Card>

)} 
           
          </Col>
          <Col xs={12} md={7}>
            <div className='mb-4'>
            <h6 className='' style={{color:'#0D111B', fontWeight:'700'}}>Delivery Details</h6>
           
            </div>
            
          <Card className='p-3 mb-4 ' style={{border:'none'}}>
          <div className='d-flex gap-3'>
        
      <h6 className='' style={{color:'#0D111B', fontWeight:'700'}}>Delivery Address</h6> 
      {!isEditing && (
        <p style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} onClick={handleEditDelivery}>Edit</p>
      )}
    </div>

            <Form className='my-3' onSubmit={handleSaveDelivery}>
          
           

             <Row>
             <Col xs={6}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500'}}>State/Province</p>
          
            <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.state || ''}
        name="state"
        required disabled={!isEditing}
      > <option value="" disabled>Select a state</option>
    
 {deliveryStates.map((state,index) => (
        <option value={state?.name} key={index}>{state?.name}</option>
    ))}
      </Form.Select>

            </Form.Group>
              </Col>
              <Col xs={6}>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500'}}>Delivery Area {isEditing && !formData?.LGA && <span>(Please select a state first)</span>}</p>
          
            <Select
         formatOptionLabel={formatOptionLabel}
          options={lgaOptions}
          onInputChange={handleInputChange}
          onChange={handleSelectChange}
         
        required
           value={lgaOptions.find(option => option.value === formData.LGA) || ''}

          name="LGA"
          isDisabled={!isEditing}
          isClearable={true}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 2000, // Ensure the dropdown has a high z-index
            }),
            menuList: (provided) => ({
              ...provided,
              zIndex: 2000, // Ensure the dropdown options have a high z-index
            }),
          }}
          
        />

            </Form.Group>
              </Col>
             </Row>
             <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500'}}>Street Address {isEditing && !formData?.LGA && <span>(Please select a state first)</span>}</p>
            <div className="input-group">
            <Form.Control
  ref={streetAddressAutoCompleteInput}
  name="streetAddress"
  value={formData?.streetAddress}
  placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
  onChange={handleStreetChange}
  style={{ border: '1px solid #D7D7D7' }}
  required
  disabled={!isEditing}
/>

  {isEditing && formData.streetAddress && (
   <Button variant='outline-secondary'  onClick={() => setFormData({ ...formData, streetAddress: '' })}  style={{ zIndex: 1000 }}>
    X</Button>
  )}
</div>
<p className='text-secondary mt-1' style={{fontSize:'12px'}}>Please ensure you enter the correct delivery address. Additional costs associated with a wrong delivery address will be your responsibility. Thank you!</p>


            </Form.Group>

            <Form.Group className='d-flex gap-1 align-items-center mb-3'>
            <Form.Check
    type='checkbox'
    name='isDefaultAddress'
    checked={formData.isDefaultAddress}
    onChange={handleChange} disabled={!isEditing}
  /> <p className='my-auto'style={{color:'#4F4F4F',}}>Set as default address</p>
              </Form.Group>
            
            
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500'}}>Phone</p>
            <Form.Control
  type='text'
  placeholder='Enter phone number'
  style={{border:'1px solid #D7D7D7'}}
  value={formData.phoneNumber}
  onChange={(event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    if (numericValue.length <= 11) {
      setFormData({ ...formData, phoneNumber: numericValue });
    }

    
    if (numericValue.length === 11 && !isNigerianPhoneNumber(numericValue)) {
      setError({...error, phoneNumber: 'Please enter a valid 11-digit Nigerian phone number.'});
    } else {
    
      setError({...error, phoneNumber: ''});
    }
  }}
  readOnly={!isEditing}
/>
{error.phoneNumber && <div className="text-danger" style={{fontSize:'12px'}}>{error.phoneNumber}</div>}

            </Form.Group>
         
            <Form.Group className='d-flex gap-1 align-items-center mb-3'>
              <Form.Check type='checkbox'
    name='isSchoolOrBusinessAddress'
    checked={formData.isSchoolOrBusinessAddress}
    onChange={handleChange} disabled={!isEditing}/> <p className='my-auto'style={{color:'#4F4F4F',}}>Please check if this is either a school or business address</p>
              </Form.Group>
              
              <Form.Group className=' mb-3'>
             
              <p className='mb-1' style={{color:'#5D5D5D', fontWeight:'500'}}>Delivery Frequency</p>
              <p className='mb-1' style={{color:'#000000', fontSize:'10px'}}>How often would you like your deliveries?</p>
   
      <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.deliveryFrequency}
        name="deliveryFrequency"
        required disabled={!isEditing}
      >
        <option value="" disabled>Select frequency</option>
        <option value='once'>Once</option> 
<option value='weekly'>Weekly</option>
    <option value='bi-weekly'>Bi-weekly</option>
    <option value='monthly'>Monthly</option>
</Form.Select>
              </Form.Group>

              <Form.Group className=' mb-3'>
             
              <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500'}}>Delivery Date</p>
              <p className='mb-1' style={{color:'#000000', fontSize:'10px'}}>Select  your first delivery date</p>
    <div className="custom-select-wrapper">
      <Form.Select
        className="custom-select"
        onChange={handleChange}
        value={formData.deliveryDate}
        name="deliveryDate"
        required disabled={!isEditing}
      >
        <option value="" disabled>Select a date</option>
        {selectOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Form.Select>
      <FaRegCalendarAlt className="select-icon" />
    </div>
              </Form.Group>
              <Form.Group className='mb-3'>
            <p className='mb-1' style={{color:'#5D5D5D',fontWeight:'500'}}>Delivery Comment</p>
         
            <Form.Control
  type='text' placeholder='Add notes/comments to your order(s)' rows={3} className=' p-2 w-100' style={{border:'1px solid #D7D7D7'}}value={comment} onChange={(e)=> setComment(e.target.value)}
  disabled={!isEditing}
/>



            </Form.Group>

              {isEditing && (
        <div className='pt-3 my-3 d-flex justify-content-end'>
          <Button className='px-5 py-2' disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} type='submit'>Save</Button>
        </div>
      )}
            </Form>
            </Card>
          

 
            <Card style={{border:'none'}} className='p-3'>
            <h6 className='mb-4'style={{color:'#0D111B', fontWeight:'700'}}>Payment</h6>
             <div className='d-flex flex-column gap-0' style={{borderBottom:'1px solid #F8F8F9'}}>
            
             <div className='d-flex justify-content-between'>
               <p style={{color:'#667085'}}>Subtotal <span style={{color:'#4F4F4F'}}></span></p>
               <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</p>
             </div>
            
            

{!isEditing && (
    <div className='d-flex justify-content-between'>
       <p style={{color:'#667085'}}>Shipping Fee <span style={{color:'#4F4F4F'}}></span></p>
    {additionalFee.amount === 0 ?   <p style={{color:'#469B12', fontWeight:'500', fontFamily:'Outfit'}}>Free delivery</p>
    :
    
      <p style={{color:'#4F4F4F', fontWeight:'500', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(additionalFee.amount)}</p>
    }
    </div>
  )}





           

             {selectedCategories.length > 0 && (
        <div className='d-flex flex-column gap-0'>
          <p className='mb-2' style={{ color: '#0D111B', fontWeight: '600' }}>Extra Services</p>
          {selectedCategories.map((category, index) => (
            <div className='d-flex justify-content-between' key={index}>
              <p style={{ color: '#667085' }}>{category} <span style={{ color: '#4F4F4F' }}></span></p>
              <p style={{ color: '#4F4F4F', fontWeight: '500', fontFamily: 'Outfit' }}>
                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(instructionFees[category].fee)}
              </p>
            </div>
          ))}
        </div>
      )}



             </div>
             <div className='py-2'>
               <div className='d-flex justify-content-between'>
                 <h6 style={{color:'#353542', fontWeight:'700'}}> Grand Total</h6>

                 <p style={{color:'#353542', fontWeight:'700', fontFamily:'Outfit'}}>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(grandTotal)}</p>
               </div>
             </div>
             <div className='pt-2'>
            
             
             <div className='d-flex gap-1 align-items-center mt-1'>
              <Form.Check type='checkbox'   onChange={() => setChecked(!checked)}
                value={checked} required
   /> <p className='my-auto'style={{color:'#4F4F4F',fontWeight:'500'}}>Please check to acknowledge our <a href='/terms' style={{color:'#469B12'}}> Terms of Use</a> and <a href='/privacy-policy' style={{color:'#469B12'}}> Privacy Policy</a> </p>
              </div>

              <div className='my-3 px-3'>
              <Button  disabled={isPaymentDisabled} onClick={handleSubmitOrder} className='py-2 w-100' style={{backgroundColor: isPaymentDisabled ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}}>Pay {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(grandTotal)}</Button>
              </div>
              
             </div>
           </Card>
           
          </Col>
        </Row>
           

           
       

  
          </div>
    </div>}
   

   <Waitlist showWaitlist={showWaitlist} setShowWaitlist={setShowWaitlist}/>
   
    </>
   
  )
}

export default SubscriptionSummary
