import React, {useState, useEffect}from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import SidebarNavbar from '../../components/Navbar/SideNavbar';
import { Row, Col, Table } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import { trackEvent } from '../../mixpanel';
import { useAppContext } from '../../AppContext';
import orderRoute from '../../services/orderRoute';
import arrowLeft from '../../assets/arrow-left.svg'
import edit from '../../assets/edit-undeline.svg'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar';

const Orders = () => {
  const {showToast} = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {getOrders} = orderRoute()
  const [orderDetails, setOrderDetails] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const userData = getParsedLocalStorageItem('userData');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  
 
    useEffect(() => {
      let timeoutId = null;
      const handleResize = () => {
        clearTimeout(timeoutId); // Clear any previously set timeout
        timeoutId = setTimeout(() => {
          setIsMobile(window.innerWidth < 576);
        }, 150); // Adjust debounce time as needed
      };
    
      window.addEventListener('resize', handleResize);
    
      // Cleanup the event listener on component unmount
      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('resize', handleResize);
      };
    }, []);


  


  useEffect(() => {
    getOrders (
  setLoading,showToast, setOrderDetails    
    )
  }, []);

  function isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  useEffect(() => {
    if (orderDetails.length > 0) {
      const updatedOrderDetails = orderDetails.map(orderDetail => {
        let canModify = false;
  
        if (orderDetail?.cart?.length > 0 && orderDetail?.deliveryDate && orderDetail?.orderInfo?.length > 0) {
          const lastOrderInfo = orderDetail?.orderInfo[orderDetail?.orderInfo?.length - 1];
          if (lastOrderInfo?.orderStatus?.toLowerCase() === 'order confirmed') {
            const deliveryDate = new Date(orderDetail?.deliveryDate);
            deliveryDate.setDate(deliveryDate.getDate() - 1);
            deliveryDate.setHours(23, 0, 0, 0);
            const currentTime = new Date();
            if (currentTime < deliveryDate) {
              canModify = true;
            }
          }
        }
  
        return { ...orderDetail, canModify };
      });
  
      // Check if updatedOrderDetails is different from orderDetails to prevent infinite loop
      if (!isEqual(updatedOrderDetails, orderDetails)) {
        setOrderDetails(updatedOrderDetails);
      }
    }
  }, [orderDetails]);
  

  
  

  const filteredOrders = () => {
    switch (selectedFilter) {
      case 'All':
        return orderDetails;
        // return orderDetails.filter(order =>
        //   Array.isArray(order) && order.orderInfo.length > 0 &&
        //   order?.paymentStatus !== 'pending'
        // );
        case 'Ongoing':
          return orderDetails.filter(order =>
            Array.isArray(order.orderInfo) && order.orderInfo.length > 0 &&
            order.orderInfo.every(info => info.orderStatus.toLowerCase() !== 'order delivered') && order?.paymentStatus !== 'pending'
          );
        
        case 'Delivered':
          return orderDetails.filter(order =>
            Array.isArray(order.orderInfo) && order.orderInfo.length > 0 &&
            order.orderInfo.some(info => info.orderStatus.toLowerCase() === 'order delivered') 
          );
        
        
      default:
        return orderDetails.filter(order =>
          order?.paymentStatus !== 'pending'
        );
    }
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear().toString().substr(-2); // Get last 2 digits of year
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    let formattedTime = `${day}-${month}-${year} `;
    if (hours > 12) {
      formattedTime += `${(hours - 12).toString().padStart(2, '0')}:${minutes} PM`;
    } else if (hours === 12) {
      formattedTime += `12:${minutes} PM`;
    } else if (hours === 0) {
      formattedTime += `12:${minutes} AM`;
    } else {
      formattedTime += `${hours.toString().padStart(2, '0')}:${minutes} AM`;
    }
    return formattedTime;
  };

  const truncateText = (text, maxLength = 20) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };
  const truncateTextMobile = (text, maxLength = 10) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };
  const capitalizeFirstLetter = (string) => {
    return string.replace(/\b(\w)/g, s => s.toUpperCase());
  };
  const getStatusStyle = (status) => {
    switch (status) {
      case 'order confirmed':
        return { backgroundColor: '#19429833', color: '#353542', border:'1px solid #EDFDF8', padding:'6px 16px 6px 16px', borderRadius:'4px' };
        case 'order processing':
          return { backgroundColor: '#FFF8EB', color: '#B25E09', border:'1px solid #FEF1F2', padding:'6px 16px 6px 16px', borderRadius:'4px' };
      case 'order delivered':
        return  { backgroundColor: '#EDFDF8', color: '#08875D', border:'1px solid #EDFDF8', padding:'6px 16px 6px 16px',borderRadius:'4px' };
        case 'order picked up':
          return  { backgroundColor: '#EDFDF8', color: '#08875D', border:'1px solid #EDFDF8', padding:'6px 16px 6px 16px',borderRadius:'4px' };
      case 'out for delivery':
        return  { backgroundColor: '#FFF8EB', color: '#F2994A', border:'1px solid #EDFDF8', padding:'6px 16px 6px 16px',borderRadius:'4px' };
      case 'ready for pickup ':
        return  { backgroundColor: '#FFF8EB', color: '#F2994A', border:'1px solid #EDFDF8', padding:'6px 16px 6px 16px',borderRadius:'4px' };
      // case 'awaiting payment':
      //   return { backgroundColor: '#FD7B1D', color: '#ffffff', padding:'6px 16px 6px 16px',borderRadius:'4px' };
      default:
        return { backgroundColor: '#FFF8EB', color: '#B25E09', border:'1px solid #FEF1F2', padding:'6px 16px 6px 16px',borderRadius:'4px' };
    }
  };

  const determineStatus = (order) => {
    let statusText = 'Status Unknown';
    let statusStyle = { backgroundColor: 'grey', color: 'white' };
  
    if (Array.isArray(order.orderInfo) && order.orderInfo.length > 0) {
      const lastStatusObj = order.orderInfo[order.orderInfo.length - 1]; // Get the last object
      statusText = capitalizeFirstLetter(lastStatusObj.orderStatus); 
    } else if(order?.source === 'voucher'){
      statusText='Order Confirmed'
    }
    
  
    statusStyle = getStatusStyle(statusText.toLowerCase()); // Ensure status style is determined correctly
    return <span style={statusStyle}>{statusText}</span>;
  };
  

  const handleBack = () => {
   
    navigate(-1);
  }

const handleViewOrder=(orderId)=>{
  navigate(`/profile/order-details/${orderId}`)
}
const handleModifyOrderClick = (orderId) => {
  trackEvent('Modify Order Clicked', {
    'User': userData?.email,
    attempted_at: new Date().toISOString(),
   
  });
  navigate(`/profile/order-modification/${orderId}`);
  
 
};
  return (
    <div>
      {isMobile ? ( <div className='py-5 my-5 my-sm-0 '>

      <div className='mb-4' style={{borderBottom:'1px solid #DADADAB2'}}>
          <div className='d-flex d-sm-none align-items-center gap-3 px-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
               <h5 style={{color:'#344054', fontWeight:'500', fontSize:'18px'}} className='my-auto'>Orders</h5>
              
           </div>
           </div>
          <div className='px-3 px-xxl-5 mx-lg-5 mb-5 mb-sm-0'>
         
           <div className='d-flex gap-5' style={{borderBottom:'1px solid #EDEDED'}}>
  {['All', 'Ongoing', 'Delivered'].map((category, index) => (
    <p
      key={index}
      className={`order-item ${selectedFilter === category ? 'active' : ''}`}
      onClick={() => setSelectedFilter(category)}
      style={{cursor: 'pointer', marginBottom:'5px', marginRight:'auto', fontSize:'14px'}}
    >
      {category}
    </p>
  ))}
</div>


           
           {loading ? (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
<div className="spinner-border text-success" role="status">
<span className="visually-hidden">Loading...</span>
</div>
  </div>):filteredOrders()?.length > 0 ?
    <Table hover className='my-3'>
    <thead>
              <tr style={{color:'#B0B0B0', fontWeight:'600'}} className='mb-2'>
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Order ID</th>
               
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Total</th>
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Status</th>
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'></th>
                
              </tr>
            </thead>
            <tbody className='py-2'>
            {filteredOrders()?.map((order) => (
              <tr key={order?.sku ? order?.sku : order?._id} style={{fontSize:'12px', fontWeight:'500',color:'#4F4F4F', paddingTop: '15px', paddingBottom: '15px' }} onClick={() => handleViewOrder(order?._id)}>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{truncateTextMobile(`ID${ order?.sku ||order?._id}`)}</td>
                   <td style={{paddingTop: '15px', paddingBottom: '15px',fontFamily:'Outfit' }}>  {order?.totalCost?.toLocaleString() || order?.amount?.toLocaleString()}</td>
                <td style={{paddingTop: '15px', paddingBottom: '15px', fontSize:'11.5px' }}>{determineStatus(order)}</td>
                <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}}> 
                {order?.canModify && <img src={edit} alt='edit' onClick={(e) =>{e.stopPropagation();  handleModifyOrderClick(order?._id)}} />}
                <a style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} className=''   onClick={() => handleViewOrder( order?._id)}>View</a>
                </td>
              </tr>
            ))}
            </tbody>
    </Table> : <div className='fw-medium ' style={{padding:'50px 0'}}><h4>You have no orders</h4></div>

}
  </div>

 
</div>): ( 
        <div className='py-5 px-3 px-xxl-5 mx-lg-5 mb-5 mb-sm-0'>

<div className="d-flex gap-2 align-items-center mb-3" >  
<Link className='' to='/' ><p style={{color:'#475467'}}>Home &nbsp;  <i className="bi bi-chevron-left" style={{color:'#1D2939'}} ></i></p></Link>

<p className='' style={{color:'#1D2939', fontWeight:'600'}} >              Orders </p>
</div>
<Row>
<Col xs={12} sm={3} className='d-none d-sm-block'>
    <SidebarNavbar/>
</Col>
<Col xs={12} sm={9} className='ps-lg-4 pe-lg-5'>
          <div>
          <div className='mb-4'>
               <h5 style={{color:'#0C0C0C', fontWeight:'500'}} className='mb-2'>Orders</h5>
               <h6 style={{color:'#717171', fontWeight:'400'}}>Keep track of all your purchases. Click on view to see more details about your orders!</h6>
           </div>
        
           <div className='d-flex gap-5' style={{borderBottom:'1px solid #EDEDED'}}>
  {['All', 'Ongoing', 'Delivered'].map((category, index) => (
    <p
      key={index}
      className={`order-item ${selectedFilter === category ? 'active' : ''}`}
      onClick={() => setSelectedFilter(category)}
      style={{cursor: 'pointer', marginBottom:'5px'}}
    >
      {category}
    </p>
  ))}
</div>


           
           {loading ? (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
<div className="spinner-border text-success" role="status">
<span className="visually-hidden">Loading...</span>
</div>
  </div>):filteredOrders()?.length > 0 ?
    <Table hover className='my-3'>
    <thead>
              <tr style={{color:'#B0B0B0', fontWeight:'600'}} className='mb-2'>
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Order ID</th>
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Date</th>
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Delivery Address</th>
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Total</th>
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'>Status</th>
                <th style={{color:'#B0B0B0', fontWeight:'600'}} className='pb-3'></th>
                
              </tr>
            </thead>
            <tbody className='py-2'>
            {filteredOrders()?.map((order) => (
              <tr key={order?.sku ? order?.sku :  truncateText(order?._id)} style={{fontSize:'12px', fontWeight:'500',color:'#4F4F4F', paddingTop: '15px', paddingBottom: '15px' }} onClick={() => handleViewOrder(order?._id)}>
               <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{truncateText(`ID${order?.sku ? order?.sku : order?._id}`)}</td>
                <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{formatDate(order?.updatedAt)}</td>
                {order?.address && Object.keys(order?.address)?.length > 0 ? <td style={{ paddingTop: '15px', paddingBottom: '15px' }}> {truncateText(capitalizeFirstLetter(`${order?.address?.streetAddress}, ${order?.address?.city?.name ? `${order?.address?.city?.name}, ` : ''} ${order?.address?.state}`))}</td> : order?.deliveryType === 'pickup' ?
                <td style={{ paddingTop: '15px', paddingBottom: '15px' }}> In-store Pickup</td> :  <td style={{ paddingTop: '15px', paddingBottom: '15px' }}>Gift Voucher</td>}
 <td style={{paddingTop: '15px', paddingBottom: '15px' }}>
  {order?.address && Object.keys(order?.address)?.length > 0 ? 
    capitalizeFirstLetter(
      `${order?.address?.city?.name ? `${order?.address?.city?.name}, ` : ''}${order?.address?.state?.name}`
    ) : 
    order?.deliveryType === 'pickup' ? 
      'In-store pickup' : 
      'Gift Voucher'
  }
</td>


                <td style={{paddingTop: '15px', paddingBottom: '15px',fontFamily:'Outfit' }}>  {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(order?.totalCost || order?.amount)}</td>
                <td style={{paddingTop: '15px', paddingBottom: '15px' }}>{determineStatus(order)}</td>
                <td style={{paddingTop: '15px', paddingBottom: '15px',cursor:'pointer', textDecoration:'underline', color:'#469B12'}}> 
                {order?.canModify && <img src={edit} alt='edit' onClick={(e) =>{e.stopPropagation();  handleModifyOrderClick(order?._id)}} />} &nbsp;
                <a style={{cursor:'pointer', textDecoration:'underline', color:'#469B12'}} className=''   onClick={() => handleViewOrder(order?.sku || order?.id)}>View</a>
                </td>
              </tr>
            ))}
            </tbody>
    </Table> : <div className='fw-medium ' style={{padding:'50px 0'}}><h4>You have no orders</h4></div>

}
  </div>
</Col>
</Row>
 
</div>)}
      
     
    </div>
  )
}

export default Orders