import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import successIcon from '../../assets/successful-icon.svg'

const VerificationModal = ({showModal, closeModal, modalText, subText, btnText}) => {
  return (
    <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body className='justify-content-center text-center d-flex flex-column gap-5 p-5'>
            <div><img src={successIcon} alt='success' style={{width:'50px', height:'50px'}}/></div>
            
            <div className=''>
            <h2 className='mb-3'>{modalText}</h2>
            <p style={{color:'#5D5D5D', fontWeight:'500'}}>{subText}</p>
            </div>
            <div className=''>
            {/* <Button className='btn-primary w-100'>{btnText}</Button> */}
            </div>
           

          </Modal.Body>
          
            </Modal>
  )
}

export default VerificationModal
