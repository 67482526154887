
import createAxios from './httpCommon';
import Error from './error';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../mixpanel';
// import { trackAddToWishlist, transformPath } from '../App';
import { getParsedLocalStorageItem } from '../components/Navbar/Navbar';
import { localStorageGetItem, localStorageSetItem,localStorageRemoveItem } from '../App';


export default () => {
  const { error } = Error();
  const navigate = useNavigate();
  const userData = getParsedLocalStorageItem('userData');
  
  const removefromWishlist = (
    productId, 
    setLoading, 
    showToast, 
    apiWishlistItems, 
    setApiWishlistItems,
    callback // Add this parameter for the callback function
) => {
    setLoading(true);
    const token = localStorageGetItem
('userToken');
    
    
    createAxios()
      .then(axios => {
        axios.post(`/order/wishlist/${productId}`, {}, {
          headers: {
            'X-Auth-Token': token,
          },
        })
        .then(response => {
          setLoading(false);
          showToast({
            toastText: 'Item removed from Wishlist!',
            isSuccess: true
          });

          // const updatedWishlistItems = apiWishlistItems.filter(item => item?.id !== productId);
           
          setApiWishlistItems(response?.data?.wishlist?.wishlist);
          localStorageSetItem('wishlistItems', JSON.stringify(response?.data?.wishlist?.wishlist));
          const totalWishlistItems = response?.data?.wishlist?.pagination?.totalWishlistItems;
          localStorageSetItem('totalWishlistItems', totalWishlistItems.toString()); // Ensure the number is converted to a string
          
         
          const product = apiWishlistItems.find(p => (p?.product?.id || p?.box?.id)  === productId);
          trackEvent(`${product?.product?.productName || product?.box?.name} Removed from Wishlist`, {
            'Product ID': productId,
            'Product Name': product?.product?.productName || product?.box?.name,
            'User': userData?.email || 'Anonymous',
          });

          if (callback) callback(); // Call the callback function if provided
        })
        .catch(e => {
          // setLoading(false);
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        });
      });
};


 
  const updateWishlist = (
    productId,
    showToast,
    filledHearts,
    setFilledHearts,
    apiWishlistItems,
    setApiWishlistItems,
    products
  ) => {
    const token = localStorageGetItem
('userToken');
  
    createAxios()
      .then(axios => {
        axios.post(`/order/wishlist/${productId}`, {}, {
          headers: {
            'X-Auth-Token': token,
          },
        })
        .then(response => {
          const newState = !filledHearts[productId];
          setFilledHearts(prevState => {
            const newState = { ...prevState, [productId]: !prevState[productId] };
          
            return newState;
          });
  
          showToast({toastText: newState ? 'Item added to Wishlist!' : 'Item removed from Wishlist!', isSuccess: true});
   
          // let updatedWishlist = apiWishlistItems;
          const productToAdd = products.find(product => product?.id === productId);
    
          // if (newState) {
          //   if (!apiWishlistItems.some(item => item?.id === productId) && productToAdd) {
          //     updatedWishlist = [...apiWishlistItems, productToAdd];
          //   }
          //   // trackAddToWishlist(productId, productToAdd.productName);
          // } else {
          //   updatedWishlist = apiWishlistItems.filter(item => item?.id !== productId);
          // }
    
          setApiWishlistItems(response?.data?.wishlist?.wishlist);
          localStorageSetItem('wishlistItems', JSON.stringify(response?.data?.wishlist?.wishlist));
          const totalWishlistItems = response?.data?.wishlist?.pagination?.totalWishlistItems;
          localStorageSetItem('totalWishlistItems', totalWishlistItems);
       
       
  
          if (productToAdd) {
            trackEvent(newState ? `${productToAdd.productName || productToAdd?.name} Added to Wishlist` : `${productToAdd.productName || productToAdd?.name} Removed from Wishlist`, {
              'Product ID': productId,
              'Product Name': productToAdd.productName || productToAdd?.name,
              'User': userData?.email || 'Anonymous',
            });
          }
        })
        .catch(e => {
        
          console.error(e);
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, showToast, retriesDone);
       
          
        });
      });
  };
  

  const updateSingleWishlist = (
    productId, 
    showToast, 
    isHeartFilled,
    setIsHeartFilled, apiWishlistItems,
    setApiWishlistItems,globalProducts
  ) => {
    
    const token = localStorageGetItem
('userToken');
  
    createAxios()
      .then(axios => {
        axios.post(`/order/wishlist/${productId}`, {}, {
          headers: {
            'X-Auth-Token': token,
          },
        })
        .then(response => {
          const newState = !isHeartFilled; 
          setIsHeartFilled(newState); 
  
          let toastText = '';
          if (newState) {
            // Add to wishlist if not already present
            const productToAdd = globalProducts?.find(product => product?.id === productId);
            if (!apiWishlistItems.some(item => (item?.product?.id || item?.box?.id) === productId)) {
              apiWishlistItems.push(productToAdd);
              toastText = 'Item added to Wishlist!';
            }
            // trackAddToWishlist(productId, productToAdd.productName);
          } else {
            // Remove from wishlist if present
            if (apiWishlistItems.some(item =>(item?.product?.id || item?.box?.id) === productId)) {
              apiWishlistItems = apiWishlistItems.filter(item => (item?.product?.id || item?.box?.id) !== productId);
              toastText = 'Item removed from Wishlist!';
            }
          }
  
          // Update local storage and state
          // localStorageSetItem('apiWishlistItems', JSON.stringify(wishlist));
          setApiWishlistItems(response?.data?.wishlist?.wishlist);
          localStorageSetItem('wishlistItems', JSON.stringify(response?.data?.wishlist?.wishlist));
          const totalWishlistItems = response?.data?.wishlist?.pagination?.totalWishlistItems;
          localStorageSetItem('totalWishlistItems', totalWishlistItems);
          const product = globalProducts?.find(p => p?.id === productId);
          trackEvent(newState ? `${product?.productName || product?.name} Added to Wishlist` : `${product?.productName || product?.name} Removed from Wishlist`, {
            'Product ID': productId,
            'Product Name': product?.productName || product?.name,
            'User': userData?.email || 'Anonymous',
         
          });
          // Show the appropriate toast message
          showToast({
            toastText: toastText,
            isSuccess: true
          })
  
        })
        .catch(e => {
          const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, showToast, retriesDone);
          
        });
      });
  };
  

  const getWishlist = (
  setLoading,
  showToast,
  setApiWishlistItems,
  pagination = null, 
  setPagination = null,
  isMobile = false 
) => {
  let currentPage = 1;
  let itemsPerPage = 20;

  if (pagination) {
    currentPage = pagination.currentPage;
    itemsPerPage = pagination.itemsPerPage;
  }

  setLoading(true);
  const token = localStorageGetItem
('userToken');
  
  
    
  createAxios()
    .then(axios => {
      axios.get(`/order/wishlist?page=${currentPage}&limit=${itemsPerPage}`,
       {
        headers: {
          'X-Auth-Token': token,
        },
      })
      .then(response => {
    
      // Fetch the wishlist items from the API
      const apiWishlistItems = response?.data?.wishlist?.wishlist;
     
      localStorageSetItem('wishlistItems', JSON.stringify(apiWishlistItems));
      if (isMobile) {
        // On mobile, concatenate new globalProducts with existing ones
        setApiWishlistItems(prevWishlist => {
          
          const uniqueWishlist = apiWishlistItems.filter(newWishlist =>
            !prevWishlist.some(existingWishlist => existingWishlist?.id === newWishlist?.id)
          );
          return [...prevWishlist, ...uniqueWishlist];
        });
      } else {
        // On larger screens, replace wishlist
    
        setApiWishlistItems(apiWishlistItems);
      }

  

    
      const totalWishlistItems = response?.data?.wishlist?.pagination?.totalWishlistItems;
      localStorageSetItem('totalWishlistItems', totalWishlistItems);
    
      if (setPagination){
        const { totalPages, totalWishlistItems } = response?.data?.wishlist?.pagination;
        setPagination({
         currentPage,
         itemsPerPage,
          totalPages: totalPages,
          totalWishlistItems: totalWishlistItems,
         
        });
       
      }
      setTimeout(() => {
             
        setLoading(false);
    }, 1000); 
        
      })
      .catch(e => {
        // setLoading(false);
           const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
      });
    });
};
const getMoreWishlistItems = (
  setLoadingMore,
  showToast,
  setApiWishlistItems,
  pagination = null, 
  setPagination = null
 
) => {
  let currentPage = 1;
  let itemsPerPage = 20;

  if (pagination) {
    currentPage = pagination.currentPage;
    itemsPerPage = pagination.itemsPerPage;
  }


  setLoadingMore(true);
  const token = localStorageGetItem
('userToken');
  
    
  createAxios()
    .then(axios => {
      axios.get(`/order/wishlist?page=${currentPage}&limit=${itemsPerPage}`,
       {
        headers: {
          'X-Auth-Token': token,
        },
      })
      .then(response => {
      
     
    
      // Fetch the wishlist items from the API
      const apiWishlistItems = response?.data?.wishlist?.wishlist;
    
    
      
      setApiWishlistItems(prevWishlist => {
        // Combine previous globalProducts with new ones
        const combinedWishlist = [...prevWishlist, ...apiWishlistItems];
        
        return combinedWishlist.reduce((acc, current) => {
          const x = acc.find(item => item?.id === current?.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
      });
     
      const totalWishlistItems = response?.data?.wishlist?.pagination?.totalWishlistItems;
      localStorageSetItem('totalWishlistItems', totalWishlistItems);
     
      
      // Update pagination state with details from the response
      if (setPagination){
        const { totalPages, totalWishlistItems } = response?.data?.wishlist?.pagination;
        setPagination({
         currentPage,
         itemsPerPage,
          totalPages: totalPages,
          totalWishlistItems: totalWishlistItems,
         
        });
      
      }
      setTimeout(() => {
             
        setLoadingMore(false);
    }, 1000); 
        
      })
      .catch(e => {
        
        const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
        error(e, showToast, retriesDone);
      });
    });
};

const applyPromotion = (
  body, setDiscount,setDiscountDescription,setDiscountType, setDiscountPercentage, setMinOrderAmount,
  setLoading,setIsSuccessful, showToast
  
  
) => {
  
  setLoading(true);
  const token = localStorageGetItem
('userToken');
  const userData = getParsedLocalStorageItem('userData');
  createAxios()
    .then((axios) => {
      axios
      .post('/order/promotions/value', body
      , {
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then(async (response) => {
          setLoading(false);
          trackEvent('Valid Promo Code ', {
            'User': userData?.email,
            attempted_at: new Date().toISOString(),
            
          })
       if(response?.data?.data?.discountType === 'percentage'){
        setDiscountPercentage(response?.data?.data?.percentage)
        setDiscountType(response?.data?.data?.discountType)
        const discountPercentage = response?.data?.data?.percentage;
        const calculatedDiscount = body.totalCostAmount * discountPercentage / 100;
        setDiscount(calculatedDiscount);
       }
       else{
        setDiscount(response?.data?.data?.value)
        setDiscountType(response?.data?.data?.discountType)
       }
          
          setDiscountDescription(response?.data?.data?.promotionType)
          if (response?.data?.data?.minOrderAmount !== 0){
          setMinOrderAmount(response?.data?.data?.minOrderAmount)}
          setIsSuccessful(true)
          showToast({
            toastText: 'Code valid',
            isSuccess: true
          });
         
        
         
        })
       
        .catch((e) => {
          setIsSuccessful(false)
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        
        
          trackEvent('Invalid Promo Code', {
            'User': userData?.email,
            failure_reason: e.response?.data?.message || e.message || 'An unexpected error occurred',
            attempted_at: new Date().toISOString(),
           
          });
      
       
          
        });
    });
};

const applyVoucher = (
  body, setVoucherDiscount,
  setLoading,setIsSuccessful, showToast
  
  
) => {
  
  setLoading(true);
  const token = localStorageGetItem
('userToken');
  const userData = getParsedLocalStorageItem('userData');
  createAxios()
    .then((axios) => {
      axios
      .post('/vouchers/value', body
      , {
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then(async (response) => {
          setLoading(false);
      
          trackEvent('Valid Voucher Code ', {
            'User': userData?.email,
            attempted_at: new Date().toISOString(),
            
          })
          setVoucherDiscount(response?.data?.amount)
          setIsSuccessful(true)
          showToast({
            toastText: 'Code valid',
            isSuccess: true
          });
         
        
         
        })
       
        .catch((e) => {
          setIsSuccessful(false)
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        
        
          trackEvent('Invalid Voucher Code', {
            'User': userData?.email,
            failure_reason: e.response?.data?.message || e.message || 'An unexpected error occurred',
            attempted_at: new Date().toISOString(),
           
          });
      
       
          
        });
    });
};

const checkout = (
  body,
  setLoading,showToast
  
  
) => {
  
  setLoading(true);
  const carts = body.carts.map(item => ({
    productId: item.productId,
    quantity: item.quantity,
    productName: item.productName
  }));
  const token = localStorageGetItem
('userToken');
  const userData = getParsedLocalStorageItem('userData');
  createAxios()
    .then((axios) => {
      axios
      .post('/order/checkout', body
      , {
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then(async (response) => {
          setLoading(false);
      
        
          if (response?.status === 200 && response?.data?.paymentUrl) {

      

            // trackInitiateCheckout('success', carts.length, carts);

            trackEvent('Successful Payment Attempt', {
              'User': userData?.email,
              'Order-Items': body.carts.map(item => ({
                'productId': item.productId,
                'quantity': item.quantity,
                'productName':item.productName,
              })),
        
              'Delivery Type': body.deliveryType
            })
          
            window.location.href = response?.data?.paymentUrl?.paymentUrl;
            localStorageSetItem("transactionReference", response?.data?.paymentUrl?.transaction_reference);
            
           
          } else {
            
            
            showToast({
              toastText: 'Payment URL failed',
              isSuccess: false
            });


            // trackInitiateCheckout('failure', carts.length, carts);

            trackEvent('Failed Paystack Redirect', {
              'User': userData?.email,
              // failure_reason: e.response?.data?.message || e.message || 'An unexpected error occurred',
              failure_reason: 'An unexpected error occurred',
              attempted_at: new Date().toISOString(),
             
            });
          }
         
        })
       
        .catch((e) => {
          
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
          
        
          trackEvent('Unsuccessful Payment Attempt', {
            'User': userData?.email,
            failure_reason: e?.response?.data?.message || e?.message || 'An unexpected error occurred',
            attempted_at: new Date().toISOString(),
           
          });
          

          // trackInitiateCheckout('failure', carts.length, carts);
       
          
        });
    });
};



const getOrders = (
  
  setLoading,showToast, setOrderDetails
  
  
) => {
  
  setLoading(true);
  const token = localStorageGetItem
('userToken');
 
  createAxios()
    .then((axios) => {
      axios
      .get('/order', {
          headers: {
            'X-Auth-Token': token,
          },
          
        })
        .then(async (response) => {
      
         setOrderDetails(response?.data?.data)
         setTimeout(() => {
             
          setLoading(false);
      }, 1000);
        })
       
        .catch((e) => {
          // setLoading(false);
         
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
        
        
      
       
          
        });
    });
};


const getSingleOrder = (
  
  orderId, setOrderLoading,showToast, setOrderDetail
  
  
) => {
  
  setOrderLoading(true);
  const token = localStorageGetItem
('userToken');
 
  createAxios()
    .then((axios) => {
      axios
      .get(`/order/${orderId}`, {
          headers: {
            'X-Auth-Token': token,
          },
          
        })
        .then(async (response) => {
         
       
         setOrderDetail(response?.data?.data)
         setTimeout(() => {
             
          setOrderLoading(false);
      }, 1000);
        })
       
        .catch((e) => {
          // setOrderLoading(false);
         
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setOrderLoading, showToast, retriesDone);
        
        
      
       
          
        });
    });
};


const getOrderByRef = (
  transactionRef, setLoading, showToast, setOrderDetail, setShowPaymentModal, setCartItems
) => {
  setLoading(true);


  const userData = getParsedLocalStorageItem('userData');

  createAxios()
    .then((axios) => {
      const pollInterval = 5000; // 5 seconds
      let retryCount = 0;
      const maxRetries = 3;
      let polling;

      const pollOrderStatus = async () => {
        try {
          const response = await axios.get(`/order/confirm/${transactionRef}`);

          if (response.status === 200) {
            setLoading(false);


          

            if (response?.data?.order?.orderMod && Object.keys(response?.data?.order?.orderMod)?.length > 0) {
              trackEvent('Successful Order Modification', {
                'User': userData?.email,
                attempted_at: new Date().toISOString(),
              });
              setOrderDetail(response?.data?.order);
            } else if (response?.data?.order?.orderMod?.paymentStatus === 'failed') {
              trackEvent('Unsuccessful Order Modification', {
                'User': userData?.email,
                failure_reason: 'An unexpected error occurred',
                attempted_at: new Date().toISOString(),
              });
              setShowPaymentModal(true);
            }

            if (response?.data?.order?.boxes && Object.keys(response?.data?.order?.boxes).length > 0) {
              trackEvent('Successful Subscription', {
                'User': userData?.email,
                attempted_at: new Date().toISOString(),
              });
              setOrderDetail(response?.data?.order);
            } else {
              trackEvent('Unsuccessful Subscription', {
                'User': userData?.email,
                failure_reason: 'An unexpected error occurred',
                attempted_at: new Date().toISOString(),
              });
              setShowPaymentModal(true);
            }

            if (response?.data?.order?.cart && response?.data?.order?.cart?.length > 0) {
              if (response?.data?.order?.paymentStatus === 'success') {
                trackEvent('Successful Checkout', {
                  'User': userData?.email,
                  attempted_at: new Date().toISOString(),
                });
                setCartItems([]);
                localStorageRemoveItem('cartItems');
                setOrderDetail(response?.data?.order);
              }
            } else if (response?.data?.order?.paymentStatus === 'failed') {
              trackEvent('Unsuccessful Checkout', {
                'User': userData?.email,
                failure_reason: 'An unexpected error occurred',
                attempted_at: new Date().toISOString(),
              });
              setShowPaymentModal(true);
            }

            if (response?.data?.order?.purchasedVoucher && Object.keys(response?.data?.order?.purchasedVoucher)?.length > 0) {
              trackEvent('Successful Voucher Purchase Modification', {
                'User': userData?.email,
                attempted_at: new Date().toISOString(),
              });
              setOrderDetail(response?.data?.order);
            } else {
              trackEvent('Unsuccessful Voucher Purchase', {
                'User': userData?.email,
                failure_reason: 'An unexpected error occurred',
                attempted_at: new Date().toISOString(),
              });
              setShowPaymentModal(true);
            }

            // Clear interval if the order is successfully fetched
            clearInterval(polling);
          }
        } catch (e) {
          retryCount++;
      
          if (e.response?.status === 400 || retryCount >= maxRetries){
            clearInterval(polling); 
            
            error(e, setLoading, showToast, retryCount >= maxRetries);
          }
      
        }
      };

      polling = setInterval(pollOrderStatus, pollInterval);  // Start polling
    })
    .catch((e) => {
     
      
      const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
      error(e, setLoading, showToast, retriesDone);
    });
};




const getDeliveryStates = (
  
  showToast, setDeliveryStates
  
  
) => {
  
  // setLoading(true);

 
  createAxios()
    .then((axios) => {
      axios
      .get('/location/state')
        .then(async (response) => {
    
         setDeliveryStates(response?.data)
     
         })
       
        .catch((e) => {
          // setLoading(false);
         
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone);
        
        
      
       
          
        });
    });
};
const getDeliveryLGAs = ( stateId, showToast, setLgaOptions,setOriginalLgaOptions) => { 
  createAxios()
    .then((axios) => {
      axios
      .get(`/location/state/${stateId}/lga`)
        .then(async (response) => {

     const lgas = response?.data.map(lga => ({
      value: lga.name,
      label: lga.name,
      id:lga.id,
      deliveryFee: lga.deliveryFee, 
      nextDayDeliveryFee: lga.nextDayDeliveryFee,
      isFreeDelivery: lga.isFreeDelivery,
    }));

    setLgaOptions(lgas);
    setOriginalLgaOptions(lgas);
         }).catch((e) => {
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, showToast, retriesDone);  
        });
    });
};

const orderModify = (
  orderId,body,
  setLoading,showToast
  
  
) => {
  
  setLoading(true);

  const token = localStorageGetItem
('userToken');
  const userData = getParsedLocalStorageItem('userData');
  createAxios()
    .then((axios) => {
      axios
      .post(`/order/${orderId}/modify`, body
      , {
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then(async (response) => {
          setLoading(false);
       
          if (response?.status === 200 && response?.data?.paymentUrl) {

      
            trackEvent('Successful Order Modification Payment Attempt', {
              'User': userData?.email,
              'Order-Items': body.cart.map(item => ({
                'productId': item.productId.id,
                'quantity': item.quantity,
                'productName':item.productId?.productName,
              })),
        
            })
          
            window.location.href = response?.data?.paymentUrl
            localStorageSetItem
("transactionReference", response?.data?.transaction_reference);
            
           
          } else {
            
            
            showToast({
              toastText: 'Payment URL failed',
              isSuccess: false
            });


            // trackInitiateCheckout('failure', carts.length, carts);

            trackEvent('Failed Paystack Redirect', {
              'User': userData?.email,
              // failure_reason: e.response?.data?.message || e.message || 'An unexpected error occurred',
              failure_reason: 'An unexpected error occurred',
              attempted_at: new Date().toISOString(),
             
            });
          }
         
        })
       
        .catch((e) => {
          
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
          
        
          trackEvent('Unsuccessful Order Modification Payment Attempt', {
            'User': userData?.email,
            failure_reason: e?.response?.data?.message || e?.message || 'An unexpected error occurred',
            attempted_at: new Date().toISOString(),
           
          });
          

       
          
        });
    });
};

const subscribe = (
  body, subscription,
  setLoading,showToast
  
  
) => {
  
  setLoading(true);
 
  const token = localStorageGetItem
('userToken');
  const userData = getParsedLocalStorageItem('userData');
  createAxios()
    .then((axios) => {
      axios
      .post('/subscription/subscribe', body
      , {
        headers: {
          'X-Auth-Token': token,
        },
        
      })
        .then(async (response) => {
          setLoading(false);
       
          if (response?.status === 200 && response?.data?.data?.paymentUrl) {

            trackEvent('Successful Subscription Attempt', {
              'User': userData?.email,
              'Order-Items':{
                'boxName':subscription.name,
                'boxId': subscription.id,
                'quantity': body?.boxes?.quantity,
                
              },
        
            })
          
            window.location.href = response?.data?.data?.paymentUrl;
            localStorageSetItem
("transactionReference", response?.data?.data?.transaction_reference);
            
           
          } else {
            
            
            showToast({
              toastText: 'Payment URL failed',
              isSuccess: false
            });


            trackEvent('Failed Paystack Redirect', {
              'User': userData?.email,
             
              failure_reason: 'An unexpected error occurred',
              attempted_at: new Date().toISOString(),
             
            });
          }
         
        })
       
        .catch((e) => {
         
          
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
          
        
          trackEvent('Unsuccessful Subscription Attempt', {
            'User': userData?.email,
            failure_reason: e?.response?.data?.message || e?.message || 'An unexpected error occurred',
            attempted_at: new Date().toISOString(),
           
          });
          
       
          
        });
    });
};

const deleteSubscription = (
  subscriptionId,
  subscription,
  body,
  setLoading,
  showToast
) => {
  const token = localStorageGetItem
  ('userToken');
    const userData = getParsedLocalStorageItem('userData');
  setLoading(true);
  createAxios().then((axios) => {
    axios.post(`/subscription/subscribe/${subscriptionId}`, body
    , {
      headers: {
        'X-Auth-Token': token,
      },
      
    })
      .then((response) => {
       
        setLoading(false);
       showToast({
        toastText: 'Subscription cancelled',
        isSuccess: true
      });

      trackEvent(' Subscription Cancelled', {
        'User': userData?.email,
        'Box Name': subscription?.boxes?.box?.name,

          
        
  
      })
        setTimeout(() => {
           
         
          navigate('/profile/subscriptions')
      }, 2000);

      }).catch((e) => {
        // setLoading(false);
        trackEvent('Unsuccessful Subscription Cancellation', {
          'User': userData?.email,
          failure_reason: e?.response?.data?.message || e?.message || 'An unexpected error occurred',
          attempted_at: new Date().toISOString(),
         
        });
         const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
          error(e, setLoading, showToast, retriesDone); 
      });
  });
};
const purchaseVoucher = (
  formData, voucher,
  setLoading,showToast
  
  
) => {
  
  setLoading(true);
 
  const token = localStorageGetItem
('userToken');
  const userData = getParsedLocalStorageItem('userData');
  createAxios()
    .then((axios) => {
      axios
      .post(`/vouchers/${voucher?.id}/purchase`, formData
      , {
        headers: {
          'X-Auth-Token': token,
          "Content-Type": "multipart/form-data", 
        },
        
      })
        .then(async (response) => {
          setLoading(false);
        
         
          if (response?.status === 200 && response?.data?.paymentUrl) {

            trackEvent('Successful Voucher Purchase Attempt', {
              'User': userData?.email,
              'Order-Items':{
                'voucherName':voucher.name,
                'voucherId': voucher.id,
                
              },
        
            })
          
            window.location.href = response?.data?.paymentUrl;
            localStorageSetItem
("transactionReference", response?.data?.tx_ref);
            
           
          } else {
            
            
            showToast({
              toastText: 'Payment URL failed',
              isSuccess: false
            });


            trackEvent('Failed Paystack Redirect', {
              'User': userData?.email,
             
              failure_reason: 'An unexpected error occurred',
              attempted_at: new Date().toISOString(),
             
            });
          }
         
        })
       
        .catch((e) => {
         
          
             const retriesDone = e.config['axios-retry'] ? e.config['axios-retry'].retryCount >= 1 : true;
            error(e, setLoading, showToast, retriesDone);
          
        
          trackEvent('Unsuccessful Voucher Purchase Attempt', {
            'User': userData?.email,
            failure_reason: e?.response?.data?.message || e?.message || 'An unexpected error occurred',
            attempted_at: new Date().toISOString(),
           
          });
          
       
          
        });
    });
};
  return {
    
    checkout, getWishlist, updateWishlist, removefromWishlist, updateSingleWishlist, getMoreWishlistItems, getOrders,getSingleOrder, getOrderByRef, applyPromotion, getDeliveryStates, getDeliveryLGAs, subscribe, orderModify, deleteSubscription,purchaseVoucher,applyVoucher
    };
};