
import { Card, Modal, Row, Col } from 'react-bootstrap'

const SubscriptionModal = ({ selectedSubscription, showModal, handleCloseModal}) => {

  

if (!selectedSubscription) return null;
   ;
  
    return (
      <>
      <Modal show={showModal} onHide={handleCloseModal} centered  size='lg' className="custom-modal p-0 m-0" >
        <Modal.Header closeButton className='d-flex px-3 px-md-5' style={{backgroundColor:'#469B1233'}}>
            <div className='mb-0 pb-0'><h3 style={{color: '#000000', fontWeight: '600' }}>{selectedSubscription?.name}</h3> <p style={{ fontFamily: 'Outfit', color: '#4F4F4F', fontWeight: '700' }} className='mb-0 pb-0'>
          {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(selectedSubscription?.amount)}
          </p></div>
        </Modal.Header>

        <Modal.Body className='py-3'>
        <Row className='mt-4'>
          {selectedSubscription?.items.map((product, index)=> (
 <Col xs={12} lg={4} className='mb-4' key={index}>
 <Card style={{border:'none', backgroundColor:'white'}} className='h-100 p-1'>
  <Row>
    <Col xs={7} className='h-100'>
    <Card.Img src={product?.productId?.productImage} alt='product-image' style={{minHeight:'70px',maxHeight:'120px', width:"100%", objectFit:'cover'}} className='d-none d-md-block'/>
    <Card.Img src={product?.productId?.productImage} alt='product-image' style={{minHeight:'70px',maxHeight:'120px', width:"155px"}} className='d-block d-md-none'/>
    </Col>
    <Col xs={5} className='h-100'>
    <h5 style={{color:'#353542', fontWeight:'500'}}>{product?.productId?.productName}</h5>
   <p className='mb-0 pb-0' style={{color:'#4F4F4F', fontWeight:'300', fontSize:'12px'}}>QTY: {product.quantity}</p>
    </Col>
  </Row>
  
  
 </Card>


</Col>
          ))}
               
            </Row>
         
         
        </Modal.Body>
      
      </Modal>
     
     </>
    );
  };
  

export default SubscriptionModal