import React, { useState, useEffect, useRef } from "react";
import GuestNavbar from "../../components/Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarNavbar from "../../components/Navbar/SideNavbar";
import { Button, Offcanvas, Card, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { trackEvent } from "../../mixpanel";
import { useAppContext } from "../../AppContext";
import userRoute from "../../services/userRoute";
import arrowLeft from "../../assets/arrow-left.svg";
import { initialLgaOptions, supportedLGAs  } from "../Website/OrderSummary";
import LGAModal from '../../components/Modal/LGAModal';
import Select from 'react-select';
import Waitlist from '../../components/Modal/Waitlist';
import useGoogleLoader from '../../hooks/useGoogleLoader';
import orderRoute from "../../services/orderRoute";
import authRoute from "../../services/authRoute";
import VerificationModal from "../../components/Modal/VerificationModal";



const PersonalData = () => {
  const { showToast, setShowSidebar, setActiveNavItem } = useAppContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const { resendVerification } = authRoute();
  const { getUser, updateUser } = userRoute();
  const {getWishlist,getDeliveryStates, getDeliveryLGAs}= orderRoute();
  const [originalLgaOptions, setOriginalLgaOptions] = useState([]);
  const [showVerificationModal,setShowVerificationModal] = useState(false)
  const [details, setDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    isVerified:'',
    phoneNumber: '',
    address: {
      streetAddress: '',
      LGA: '',
      state: '',
    },
    profilePicture:''
  });
  const [personalDetails, setPersonalDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    isVerified:'',
    phoneNumber: '',
    address: {
      streetAddress: '',
      LGA: '',
      state: '',
    },
    profilePicture:''
  });
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [inputFocused, setInputFocused] = useState(false);
  // Adjusting the initial error state to handle multiple errors
const [error, setError] = useState({
  streetAddress:'',
  phoneNumber: '',
  state: '',
  LGA: '',
});
const googleLoader = useGoogleLoader();
const streetAddressAutoCompleteInput = useRef(null);
const autocompleteRef = useRef(null);
  const [isProfileEditing, setIsProfileEditing] = useState(false);
  const [lgaOptions, setLgaOptions] = useState([]);

  const [deliveryStates, setDeliveryStates] = useState([]);
  const location = useLocation();
 

  useEffect(() => {
    getUser(setLoading, showToast, setDetails, setPersonalDetails);
    
  }, []);

  useEffect(()=>{
    getDeliveryStates(
      showToast, setDeliveryStates 
    )
  },[])

  let autocompleteStreetAddress;

  const handlePlaceSelect = (autocomplete) => {
    const place = autocomplete.getPlace();
    const formattedAddress = place.formatted_address || '';
    const addressComponents = place.address_components;

    // Function to normalize state names
    const normalizeStateName = (stateName) => {
      const stateMap = {
        'Lagos': ['Lagos', 'Ìpínlẹ̀ Èkó', 'Lagos State'],
        'Ogun': ['Ogun', 'Ìpínlẹ̀ Ògùn', 'Ogun State']
      };

      for (const [key, values] of Object.entries(stateMap)) {
        if (values.includes(stateName)) {
          return key;
        }
      }
      return stateName;
    };

    // Check if the address belongs to the specified state
    const stateLongName = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name;
    const normalizedStateName = normalizeStateName(stateLongName);
  
    const isInSpecifiedState = details.address.state === normalizedStateName;

    if (isInSpecifiedState) {
      setDetails((prevDetails) => ({
        ...prevDetails,
        address: {
          ...prevDetails.address,
          streetAddress: formattedAddress,
        }
      }));
     
    } else {
      setDetails((prevDetails) => ({
        ...prevDetails,
        address: {
          ...prevDetails.address,
          streetAddress: '',
        }
      }));
      showToast({ toastText: `Selected place is not in ${details.address.state} state.`, isSuccess: false });

     
    }
  };
  const setAutocompleteBounds = (google, autocomplete, state) => {
    // Define bounds for Lagos
    const lagosBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(6.3933, 3.1473),
      new google.maps.LatLng(6.7009, 3.4860)
    );

    // Define bounds for Ogun
    const ogunBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(6.3445, 2.5993),
      new google.maps.LatLng(7.2948, 3.7181)
    );

    if (state === 'Lagos') {
      autocomplete.setBounds(lagosBounds);
    } else if (state === 'Ogun') {
      autocomplete.setBounds(ogunBounds);
    } else {
      autocomplete.setBounds(null); // Clear bounds if state is not Lagos or Ogun
    }
  };

  useEffect(() => {
    if (isProfileEditing) {
      googleLoader.load().then((google) => {
        const options = {
          componentRestrictions: { country: "NG" }, // Restrict search to Nigeria
        };

        if (streetAddressAutoCompleteInput.current) {
          autocompleteStreetAddress = new google.maps.places.Autocomplete(
            streetAddressAutoCompleteInput.current,
            options
          );

          autocompleteStreetAddress.addListener("place_changed", () => {
            handlePlaceSelect(autocompleteStreetAddress);
          });

          setAutocompleteBounds(google, autocompleteStreetAddress, details.address.state); // Pass the state here
        }
      });

      return () => {
        if (autocompleteStreetAddress) {
          window.google.maps.event.clearInstanceListeners(autocompleteStreetAddress);
        }
      };
    }
  }, [googleLoader, details.address.state, isProfileEditing]);
  

  useEffect(() => {
 
    if (details?.address?.state) {
      const selectedState = deliveryStates.find(state => state.name === details?.address?.state);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
      }
    }
  }, [details?.address?.state, deliveryStates]);
 


  const handleBack = () => {
   
    navigate(-1);
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "state") {
      const selectedState = deliveryStates.find(state => state.name === value);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions, setOriginalLgaOptions);
      }
  
  
      setDetails(prev => ({
        ...prev,
        address: { ...prev.address, [name]: value }
      }));
      setDetails(prevDetails => ({
        ...prevDetails,
        address: {
          ...prevDetails.address,
          streetAddress: ''
        }
      }));
    } else if (name === "streetAddress") {
     
      setDetails(prev => ({
        ...prev,
        address: { ...prev.address, [name]: value }
      }));
    } else {
      // Handles all other fields outside of the 'address' object and special cases
      setDetails(prev => ({ ...prev, [name]: value }));
    }
  };
  
  
  const formatOptionLabel = ({ label, value }) => {
    if (value === "comingSoon") {
      // Split the label to insert styling on a specific part
      const firstPart = "We are coming to ";
      const secondPart = label.split('soon! ')[0].replace(firstPart, '');
      const thirdPart = "soon! ";
      const actionPart = "Join our waitlist and be the first to know when we do.";
     
  
      return (
        <div>
          {firstPart}
          <span>{secondPart}</span>
          {thirdPart}
          <span style={{ textDecoration: "underline", color:'#469B12 ' }}>{actionPart}</span>
        
        </div>
      );
    }
    // Return the label as is for other options
    return label;
  };

  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === "input-change") {
      const filteredLgas = originalLgaOptions.filter(lga => lga.label.toLowerCase().includes(inputValue.toLowerCase()));
      if (filteredLgas.length === 0 && inputValue.trim() !== "") {
        setLgaOptions([{ value: "comingSoon", label: `We are coming to ${inputValue} soon! Join our waitlist and be the first to know when we do.` }]);
      } else {
        setLgaOptions(filteredLgas);
      }
    }
  };

  
 

  const handleSelectChange = (selectedOption) => {
    if (!selectedOption) {
    
      setDetails((prevDetails) => ({
        ...prevDetails,
        address:{ ...prevDetails.address,LGA: ''} 
      }));
      setLgaOptions(originalLgaOptions)
    } else if (selectedOption.value === "comingSoon") {
    
      setDetails((prevDetails) => ({
        ...prevDetails,
        address:{ ...prevDetails.address,LGA: ''}
      }));
      setShowWaitlist(true); 
      const selectedState = deliveryStates.find(state => state.name === details?.address?.state);
      if (selectedState) {
        getDeliveryLGAs(selectedState.id, showToast, setLgaOptions,setOriginalLgaOptions);
      }
     
    } else {
     
      setDetails((prevDetails) => ({
        ...prevDetails,
        address:{ ...prevDetails.address,LGA: selectedOption.value}
      }));
    }
  };
  const isFormValid = () => {
  
    return details?.address?.streetAddress && details?.address?.state && details?.address?.LGA && details?.phoneNumber 
  };

  const isNigerianPhoneNumber = (phoneNumber) => {
   
    const localFormat = /^0[7-9][0-1]\d{8}$/;
    
    const internationalFormat = /^\+234[7-9][0-1]\d{8}$/;
    
    return localFormat.test(phoneNumber) || internationalFormat.test(phoneNumber);
  };
  
  
  const handleEdit = () => {
    setIsProfileEditing(true);
  
  };

  const resendVerify = () => {
    resendVerification(setLoading, showToast,setShowVerificationModal) 
  }

  const handleSave = (e) => {
    e.preventDefault();

    let hasError = false;

  
  setError({
    streetAddress:'',
    phoneNumber: '',
    state: '',
    LGA: '',
  });

  if (!details.address.streetAddress) {
    setError(prev => ({ ...prev, streetAddress: 'Please provide an address.' }));
    hasError = true;
  }

  // Validate State
  if (!details.address.state) {
    setError(prev => ({ ...prev, state: 'Please select a state.' }));
    hasError = true;
  }

  // Validate LGA
  if (!details.address.LGA) {
    setError(prev => ({ ...prev, LGA: 'Please select an LGA.' }));
    hasError = true;
  }

  // Validate Phone Number
  if (!isNigerianPhoneNumber(details.phoneNumber)) {
    setError(prev => ({ ...prev, phoneNumber: 'Please enter a valid Nigerian phone number.' }));
    hasError = true;
  }

  if (hasError) return;

  setIsProfileEditing(false);
  const selectedState = deliveryStates.find(state => state.name === details?.address?.state);
  const stateId = selectedState ? selectedState.id : null;

  // Extract LGA ID
  const selectedLGA = lgaOptions.find(option => option.label === details?.address?.LGA);
  const lgaId = selectedLGA ? selectedLGA.id : null;

    const body = {
      phoneNumber: details.phoneNumber,
      address: details.address?.streetAddress
        ? {
            streetAddress: details.address?.streetAddress,
            state: stateId, 
            city: lgaId,
          }
        : {},
      file: file || details?.profilePicture,
    };

 

  
    const updateData = {};
    for (const key in body) {
      if (
        body[key] !== "" &&
        body[key] !== "string" &&
        !(typeof body[key] === "object")
      ) {
        updateData[key] = body[key];
      } else if (
        key === "address" &&
        typeof body[key] === "object" &&
        Object.keys(body[key]).length > 0
      ) {
        const addressData = { ...body[key] };
        delete addressData._id;
        updateData[key] = addressData;
      } 
    }
    updateData["profilePicture"] = file;
   
    updateUser(setLoading, updateData, showToast, setDetails);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return; // Early return if no file was selected
  
    // Check if the file type is an image but not SVG
    if (file.type.startsWith("image/") && file.type !== "image/svg+xml") {
      setImagePreview(URL.createObjectURL(file));
      setFile(file);
    } else {
      // Reset the file input
      e.target.value = "";
      setImagePreview(null);
      setFile(null);
  
      // Alert the user - assuming showToast is a function for showing alerts or messages
      showToast({toastText:"SVG files are not supported. Please upload a different image format.",
    isSuccess:false});
    }
  };
  
  let displayContent;
  if (imagePreview) {
    displayContent = `url(${imagePreview})`; // If imagePreview exists, this will be used.
  } else if (details?.profilePicture) {
    displayContent = `url(${details.profilePicture})`; // If profilePicture exists, this will be used.
  } else if (details?.firstName) {
    displayContent = details.firstName[0]?.toUpperCase(); // If firstName exists, use its first letter.
  } else {
    displayContent = ''; // Default fallback if none of the above is available.
  }
  return (
    <div>
      <div className="py-5 my-5 my-sm-0">
        <div
          className="d-block d-sm-none mb-4"
          style={{ borderBottom: "1px solid #DADADAB2" }}
        >
          <div className="d-flex align-items-center gap-3 px-3 mb-3">
            <img src={arrowLeft} onClick={handleBack} className="" />
            <h5
              style={{ color: "#344054", fontWeight: "500", fontSize: "18px" }}
              className="my-auto"
            >
              Personal Data
            </h5>
          </div>
        </div>

        <div className=" px-3 px-xxl-5 mx-lg-5">
          {!isProfileEditing && (
            <p
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "#469B12",
              }}
              onClick={handleEdit}
              className="d-flex d-sm-none justify-content-end"
            >
              Edit Profile
            </p>
          )}

          <div className="gap-2 align-items-center mb-3 d-none d-sm-flex">
            <Link className="" to="/">
              <p style={{ color: "#475467" }}>
                Home &nbsp;{" "}
                <i
                  className="bi bi-chevron-left"
                  style={{ color: "#1D2939" }}
                ></i>
              </p>
            </Link>

            <p className="" style={{ color: "#1D2939", fontWeight: "600" }}>
              {" "}
              Personal Data{" "}
            </p>
          </div>
          <Row>
            <Col xs={12} sm={3} className="d-none d-sm-block">
              <SidebarNavbar/>
            </Col>
            <Col xs={12} sm={9} className=" ps-3 ps-lg-4 pe-3 pe-lg-5">
              <div className="pe-lg-5 me-lg-5">
                <div className="d-none d-sm-flex justify-content-between align-items-center pe-xl-5 me-xl-5">
                  <div className="mb-3">
                    <h5 style={{ color: "#0C0C0C", fontWeight: "500" }}>
                      Personal Data
                    </h5>
                    <h6 style={{ color: "#717171", fontWeight: "400" }}>
                      Your personal information
                    </h6>
                  </div>
                  {!isProfileEditing && (
                    <p
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#469B12",
                      }}
                      onClick={handleEdit}
                    >
                      Edit Profile
                    </p>
                  )}
                </div>

                {loading ? (
                  <div
                    className="justify-content-center align-items-center text-center"
                    style={{ padding: "100px" }}
                  >
                    <div className="spinner-border text-success" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <Form className="my-md-3 pe-xl-5 me-xl-5">
                    <div
                      className="position-relative"
                      style={{ width: "100px" }}
                    >
                     
                {isProfileEditing && (
  <div
    style={{
      cursor: "pointer",
      position: "absolute",
      bottom: 0,
      right: "25%",
      backgroundColor: "#353542",
      borderRadius: "50%",
      padding: "2px 5px",
      zIndex: 1,
    }}
    onClick={() =>
      document.getElementById("imageInput").click()
    }
  >
    <i
      className="bi bi-camera"
      style={{ fontSize: "16px", color: "white" }}
    ></i>
    <input
      type="file"
      id="imageInput"
      style={{ display: "none" }}
      accept="image/*"
      onChange={handleImageChange}
    />
  </div>
)}
<div
  style={{
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    backgroundColor: imagePreview || details?.profilePicture ? "" : "rgba(70, 155, 18, 0.4)",
    color: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "600",
    fontSize: "28px",
    backgroundImage: imagePreview ? `url(${imagePreview})` : (details?.profilePicture ? `url(${details.profilePicture})` : ""),
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundPosition: "center",
  }}
  className="mb-3 position-relative"
>
  {!imagePreview && !details?.profilePicture && details?.firstName ? displayContent : ""}
</div>



                    </div>
                 
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                          <p
                            className="mb-1"
                            style={{ color: "#5D5D5D", fontSize: "14px" }}
                          >
                            First name
                          </p>
                          <Form.Control
                            type="text"
                            name="firstName"
                            id="firstName"
                            className=""
                            placeholder="First name"
                            value={details?.firstName}
                           
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                          <p
                            className="mb-1"
                            style={{ color: "#5D5D5D", fontSize: "14px" }}
                          >
                            Last name
                          </p>
                          <Form.Control
                            type="text"
                            name="lastName"
                            id="lastName"
                            className=""
                            placeholder="Last name"
                            value={details?.lastName}
                           
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12}>

                        <Form.Group className="mb-3">
                          <p
                            className="mb-1"
                            style={{ color: "#5D5D5D", fontSize: "14px" }}
                          >
                            Email Address
                          </p>
                          <InputGroup>
                          <Form.Control
                            type="email"
                            name="email"
                            id="email"
                            className=""
                            placeholder="e.g.user@email.com"
                            value={details?.email}
                           
                            disabled
                          />
                          {!details?.isVerified && <Button onClick={resendVerify} className="justify-content-center align-items-center text-center text-danger" style={{backgroundColor:'#E9ECEF', color:'#CE371C80',fontWeight:'400', textDecoration:'underline', fontSize:'12px'}}>Verify email address</Button>}
                          </InputGroup>
                        
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12}>
                      <h6 className="mb-3 mt-4">Default address</h6>
                      </Col>
                     
                      <Col xs={12} md={6}>
                    
                        <Form.Group className="mb-3">
                          <p
                            className="mb-1"
                            style={{ color: "#5D5D5D", fontSize: "14px" }}
                          >
                            State/Province
                          </p>
                         

<Form.Select
  className="custom-select"
  onChange={handleChange}
  name="state"
  value={details?.address?.state || ''}
  required
  disabled={!isProfileEditing}
>
  <option value="" disabled>Select a state</option>
  {deliveryStates.map((state,index) => (
        <option value={state?.name} key={index}>{state?.name}</option>
    ))}
</Form.Select>

{error.state && (
  <div className="text-danger" style={{ fontSize: "12px" }}>
    {error.state}
  </div>
)}
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group className="mb-3">
                          <p
                            className="mb-1"
                            style={{ color: "#5D5D5D", fontSize: "14px" }}
                          >
                            Delivery Area {isProfileEditing && !details?.address?.LGA && <span>Please select a state first</span>}
                          </p>
                         
                              <Select
         formatOptionLabel={formatOptionLabel}
         options={lgaOptions}
         onInputChange={handleInputChange}
         onChange={handleSelectChange}
        required
       
          value={lgaOptions.find(option => option.value === details?.address?.LGA) || ''}

         name="LGA"
         isDisabled={!isProfileEditing}
         isClearable={true}
         styles={{
          menuList: (provided) => ({
            ...provided,
            maxHeight: '150px', // Adjust the dropdown's max height
            overflowY: 'auto'    // Add scroll to the dropdown menu
          })
        }}
       />
       {error.LGA && (
  <div className="text-danger" style={{ fontSize: "12px" }}>
    {error.LGA}
  </div>
)}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                       
                        <Form.Group className="mb-3">
                          <p
                            className="mb-1"
                            style={{ color: "#5D5D5D", fontSize: "14px" }}
                          >
                            Street Address {isProfileEditing && !details?.address?.LGA && <span>(Please select a state first)</span>}
                          </p>
                         
                          <div className="input-group">
                          <Form.Control
                          ref={streetAddressAutoCompleteInput}
                            type="text"
                            placeholder="e.g. 1 Virtue shop, Freshness Street, Lagos"
                            style={{ border: "1px solid #D7D7D7" }}
                            disabled={!isProfileEditing}
                            onChange={handleChange}
                            name="streetAddress"
                            value={details?.address?.streetAddress}
                          />
                          {isProfileEditing && details?.address?.streetAddress && (
    
        <Button variant='light'  onClick={() => setDetails(prevDetails => ({ 
        ...prevDetails, 
        address: { ...prevDetails.address, streetAddress: '' } 
      }))} 
  >X</Button>
     
  )}
  </div>{error.streetAddress && (
  <div className="text-danger" style={{ fontSize: "12px" }}>
    {error.streetAddress}
  </div>
)}
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group className="mb-3">
                          <p
                            className="mb-1"
                            style={{ color: "#5D5D5D", fontSize: "14px" }}
                          >
                            Phone Number
                          </p>
                        
<Form.Control
  type="text"
  placeholder="Enter phone number"
  style={{ border: "1px solid #D7D7D7" }}
  value={details.phoneNumber}
  onChange={(event) => {
    let input = event.target.value;
    // Allow numbers and optionally a leading '+'
    let numericInput = input.startsWith('+') ? '+' + input.substr(1).replace(/\D/g, "") : input.replace(/\D/g, "");
  
    // Determine if the input is in international format
    const isInternationalFormat = numericInput.startsWith("+234");
  
    // Limit input length based on the format
    if (isInternationalFormat && numericInput.length > 14) { // +234 and 10 digits
      numericInput = numericInput.slice(0, 14);
    } else if (!isInternationalFormat && numericInput.length > 11) { // Local format, 11 digits
      numericInput = numericInput.slice(0, 11);
    }
  
    // Update state with the sanitized input
    setDetails((prevDetails) => ({
      ...prevDetails,
      phoneNumber: numericInput,
    }));
  
    // Validate the phone number
    if ((isInternationalFormat && numericInput.length === 14) || (!isInternationalFormat && numericInput.length === 11)) {
      if (!isNigerianPhoneNumber(numericInput)) {
        setError((prevError) => ({
          ...prevError,
          phoneNumber: "Please enter a valid Nigerian phone number.",
        }));
      } else {
        // Clear the error if the phone number is valid
        setError((prevError) => ({
          ...prevError,
          phoneNumber: "",
        }));
      }
    } else if (numericInput.length < 11 || (isInternationalFormat && numericInput.length < 14)) {
      // Handle the case where the phone number is too short
      setError((prevError) => ({
        ...prevError,
        phoneNumber: 'Please enter a valid Nigerian phone number'
      }));
    }
  }}
  
  
  disabled={!isProfileEditing}
/>


{error.phoneNumber && (
  <div className="text-danger" style={{ fontSize: "12px" }}>
    {error.phoneNumber}
  </div>
)}

                        </Form.Group>
                      </Col>
                    </Row>

                    {isProfileEditing && (
                      <div className="pt-3 my-3 d-flex gap-3 justify-content-end">
                          <Button variant='danger' className="px-3 py-2" onClick={()=> {setIsProfileEditing(false); setDetails(personalDetails)}}>
                          Cancel
                        </Button>
                        <Button className="px-3 py-2" disabled={!isFormValid()} style={{backgroundColor: isFormValid() ? ' #469B12': 'rgba(70, 155, 18, 0.2)', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}} onClick={handleSave}>
                          Save
                        </Button>
                      </div>
                    )}
                  </Form>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Waitlist showWaitlist={showWaitlist} setShowWaitlist={setShowWaitlist}/>
      <VerificationModal
        showModal={showVerificationModal} setShowVerificationModal={setShowVerificationModal}
        closeModal={() => setShowVerificationModal(false)}
        modalText='Verification Mail Sent'
        subText='The verification mail has been sent to your email address. Kindly check your mail.'
        btnText='Check Mail'
      />
    </div>
  );
};

export default PersonalData;