import React,{useState, useEffect, useRef} from 'react'
import {  Col, Row, Table, Button, Form, Popover, Overlay, ListGroup } from 'react-bootstrap'
import SidebarNavbar from '../../components/Navbar/SideNavbar'
import {Link, useParams} from 'react-router-dom'
import arrowLeft from '../../assets/arrow-left.svg'
import orderRoute from '../../services/orderRoute'
import { useAppContext } from '../../AppContext'

import { trackEvent } from '../../mixpanel'
import { getParsedLocalStorageItem } from '../../components/Navbar/Navbar'
import productRoute from '../../services/productRoute'
import { debounce } from 'lodash'

import trash from '../../assets/trash-2.svg'


const OrderModification = () => {
  const { showToast } = useAppContext();
  const { orderId } = useParams();
  const [orderDetail, setOrderDetail] = useState({});
  const [globalProducts, setGlobalProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newlyAddedItems, setNewlyAddedItems] = useState([]);
  const [initialQuantities, setInitialQuantities] = useState({});
  const [orderLoading, setOrderLoading] = useState(false);
  const targetRef = useRef(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { getSingleOrder, orderModify } = orderRoute();
  const { getGlobalProducts } = productRoute();
  const [totalAmount, setTotalAmount] = useState(0);
  const [hasModifications, setHasModifications] = useState(false);
  const userData = getParsedLocalStorageItem('userData')

  
  useEffect(() => {
    getSingleOrder(orderId, setOrderLoading, showToast, (order) => {
      setOrderDetail(order);
      const quantities = {};
      order.cart.forEach(item => {
        quantities[item.productId.id] = item.quantity;
      });
      setInitialQuantities(quantities);
    });
  }, []);

  useEffect(() => {
    // Define a debounced version of the getProducts function
    const debouncedGetProducts = debounce((searchProduct) => {
      getGlobalProducts(
        setLoading,
        showToast,
        setGlobalProducts,
        searchProduct,
    );
    }, 500);

    if (searchProduct !== '') {
      setSearchPerformed(true)
        debouncedGetProducts(searchProduct);
    } else {
      
        getGlobalProducts(
            setLoading,
            showToast,
            setGlobalProducts,
        );
    }

    // Cleanup function to cancel the debounced function
    return () => {
        debouncedGetProducts.cancel();
    };
}, [searchProduct]);

useEffect(() => {
  // Calculate total amount whenever newlyAddedItems or orderDetail.cart changes
  let total = 0;

  // Calculate total from newlyAddedItems
  newlyAddedItems.forEach(item => {
    total += item.quantity * item.productId.price; // Use item.productId.price instead of item.price
  });

  // Calculate total from orderDetail.cart
  orderDetail.cart?.forEach(item => {
    const initialQuantity = initialQuantities[item.productId.id];
    if (item.quantity > initialQuantity) {
      total += (item.quantity - initialQuantity) * item.productId.price; // Use item.productId.price instead of item.price
    }
  });

  setTotalAmount(total);
  const hasChanges = newlyAddedItems.length > 0 || orderDetail.cart?.some(item => item.quantity > initialQuantities[item.productId.id]);
  setHasModifications(hasChanges);
}, [newlyAddedItems, orderDetail.cart, initialQuantities]);


  const togglePopover = () => {
    setIsPopoverVisible(prev => !prev);
  };

  const hidePopover = () => setIsPopoverVisible(false);

  const isProductChecked = (productId) => {
    return newlyAddedItems.some(item => item.productId.id === productId) ||
           orderDetail.cart?.some(item => item.productId.id === productId);
  };

  const getProductQuantity = (productId) => {
    const product = newlyAddedItems.find(item => item.productId.id === productId) ||
                    orderDetail.cart?.find(item => item.productId.id === productId);
    return product ? product.quantity : 0;
  };

  const handleToggleItem = (productId) => {
    const product = globalProducts.find(p => p.id === productId);
    if (!product) {
      showToast({ toastText: 'Product not found.', isSuccess: false });
      return;
    }

    if (product.stockLevel === 'out_of_stock' || product.quantityAvailable === 0) {
      showToast({ toastText: 'This product is out of stock.', isSuccess: false });
      return;
    }

    const existingItemIndex = orderDetail.cart?.findIndex(item => item.productId.id === productId);
    const newItemIndex = newlyAddedItems.findIndex(item => item.productId.id === productId);

    if (newItemIndex !== -1) {
      const updatedNewItems = [...newlyAddedItems];
      updatedNewItems.splice(newItemIndex, 1);
      setNewlyAddedItems(updatedNewItems);
      trackEvent(`New item removed on 0rder Modification`, {
        'Product ID': product.id,
        'Product Name': product.productName,
        'Category Name': product.category,
        'User': userData?.email || 'Anonymous',
      });
    } else if (existingItemIndex !== -1) {
      const updatedOrderCart = [...orderDetail.cart];
      updatedOrderCart.splice(existingItemIndex, 1);
      setOrderDetail(prevDetail => ({ ...prevDetail, cart: updatedOrderCart }));
    } else {
      const newItem = {
        productId: product,
        quantity: 1,
        price: product.price
      };
      setNewlyAddedItems([...newlyAddedItems, newItem]);
      trackEvent(`New item added on Order Modification`, {
        'Product ID': product.id,
        'Product Name': product.productName,
        'Category Name': product.category,
        'Quantity': 1,
        'User': userData?.email || 'Anonymous',
      });
    }
  };

  const handleChangeQuantity = (productId, delta) => {
    const product = globalProducts.find(p => p.id === productId);
    if (!product) {
      showToast({ toastText: 'Product not found.', isSuccess: false });
      return;
    }

    const existingItemIndex = orderDetail.cart?.findIndex(item => item.productId.id === productId);
    const newItemIndex = newlyAddedItems.findIndex(item => item.productId.id === productId);

    if (existingItemIndex !== -1) {
      const item = orderDetail.cart[existingItemIndex];
      const initialQuantity = initialQuantities[item.productId.id];
      const newQuantity = item.quantity + delta;

      if (newQuantity < initialQuantity) {
        showToast({ toastText: 'Modified quantity cannot be less than initial quantity.', isSuccess: false });
        return;
      }

      if (newQuantity > product.quantityAvailable) {
        if( product.quantityAvailable === 0){
          showToast({ toastText: 'This product is out of stock.', isSuccess: false });
        }else{
        showToast({ toastText: `We only have ${product.quantityAvailable} items in stock.`, isSuccess: false });}
        return;
      }

      const updatedOrderCart = [...orderDetail.cart];
      updatedOrderCart[existingItemIndex] = { ...item, quantity: newQuantity };
      setOrderDetail(prevDetail => ({ ...prevDetail, cart: updatedOrderCart }));
      const eventAction = delta > 0 ? 'increased' : 'decreased';
      trackEvent(`Existing item ${eventAction} on Order Modification`, {
        'Product ID': product.id,
        'Product Name': product.productName,
        'Category Name': product.category,
        'New Quantity': newQuantity,
        'User': userData?.email || 'Anonymous',
      });
    } else if (newItemIndex !== -1) {
      const item = newlyAddedItems[newItemIndex];
      const newQuantity = item.quantity + delta;

      if (newQuantity <= 0) {
        const updatedNewItems = [...newlyAddedItems];
        updatedNewItems.splice(newItemIndex, 1);
        setNewlyAddedItems(updatedNewItems);
        trackEvent(`New item removed on Order Modification`, {
          'Product ID': product.id,
          'Product Name': product.productName,
          'Category Name': product.category,
          'User': userData?.email || 'Anonymous',
        });
  
        return;
      }

      if (newQuantity > product.quantityAvailable) {
        if( product.quantityAvailable === 0){
          showToast({ toastText: 'This product is out of stock.', isSuccess: false });
        }else{
        showToast({ toastText: `We only have ${product.quantityAvailable} items in stock.`, isSuccess: false });}
        return;
      }

      const updatedNewItems = [...newlyAddedItems];
      updatedNewItems[newItemIndex] = { ...item, quantity: newQuantity };
      setNewlyAddedItems(updatedNewItems);
      const eventAction = delta > 0 ? 'increased' : 'decreased';
      trackEvent(`New item  ${eventAction} on Order Modification`, {
        'Product ID': product.id,
        'Product Name': product.productName,
        'Category Name': product.category,
        'New Quantity': newQuantity,
        'User': userData?.email || 'Anonymous',
      });
    }
  };

  const handlePay = () => {
 
    const modifiedCart = orderDetail.cart
  .filter(item => item.quantity > initialQuantities[item.productId.id])
  .map(item => ({
    productId: item.productId.id,
    quantity: item.quantity - initialQuantities[item.productId.id],
    productName: item.productId.productName
  }));

  
   
    const newItems = newlyAddedItems.map(item => ({
      productId: item.productId.id,
      quantity: item.quantity,
      productName: item.productId.productName // If needed
    }));
  
    const finalCart = [...modifiedCart, ...newItems];
  
    // Prepare the body object to pass to orderModify function
    const body = {
      paymentMethod: 'paystack',
      cart: finalCart,
      
    };
  
    orderModify(orderId, body, setLoading, showToast);
  };
  
  

return (
  <div className='py-5 my-5 my-sm-0'>
    <div className='d-block d-sm-none mb-4' style={{ borderBottom: '1px solid #DADADAB2' }}>
      <div className='d-flex d-sm-none align-items-center gap-3 px-3 mb-3'>
        <Link to='/profile/orders'><img src={arrowLeft} className='' alt="back" /></Link>
        <h5 style={{ color: '#344054', fontWeight: '500', fontSize: '18px' }} className='my-auto'>Order Details</h5>
      </div>
    </div>
    <div className='px-3 px-xxl-5 mx-lg-5 mb-5 mb-sm-0'>
      <div className="d-none d-sm-flex gap-2 align-items-center mb-3">
        <Link className='' to='/'><p style={{ color: '#475467' }}>Home &nbsp; <i className="bi bi-chevron-left" style={{ color: '#1D2939' }}></i></p></Link>
        <Link className='' to='/profile/orders'><p style={{ color: '#475467' }}>Orders &nbsp; <i className="bi bi-chevron-left" style={{ color: '#1D2939' }}></i></p></Link>
        <p style={{ color: '#1D2939', fontWeight: '600' }}>Order Details</p>
      </div>
      <Row>
        <Col xs={12} sm={3} className='d-none d-sm-block'>
          <SidebarNavbar />
        </Col>
        <Col xs={12} sm={9} className='ps-lg-4 pe-lg-3'>
          <div className=''>
            <div className='d-none d-sm-block mb-4'>
              <div className='d-flex gap-2 align-items-center mb-2'>
                <Link to={`/profile/order-details/${orderId}`}><img src={arrowLeft} className='' alt="back" /></Link>
                <h5 style={{ color: '#0C0C0C', fontWeight: '500' }} className='my-auto'>Modify Order</h5>
              </div>
              <h6 style={{ color: '#717171', fontWeight: '400' }}>Add more items to your order and increase the quantities of existing ones.</h6>
            </div>
            {/* {loading ? (
              <div className='justify-content-center align-items-center text-center' style={{ padding: '100px' }}>
                <div className="spinner-border text-success" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : ( */}
              <div>
                <div>
                  <Row>
                    <Col xs={7} md={6}>
                      <h6 style={{ color: '#B0B0B0', fontWeight: '600' }} className='pb-3'>Item Purchased</h6>
                    </Col>
                    <Col xs={1} md={1}>
                      <h6 style={{ color: '#B0B0B0', fontWeight: '600' }} className='pb-3'>Qty</h6>
                    </Col>
                  </Row>
                  {orderLoading ? (
                          <div className='justify-content-center align-items-center text-center' style={{ padding: '150px' }}>
                            <div className="spinner-border text-success" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) :(<>{orderDetail?.cart?.map((item) => (
                          <Row key={item?.id} className='d-flex align-items-center'>
                            <Col xs={7} md={6}>
                              <div className='d-flex gap-3 align-items-center'>
                                <div className=''><img src={item?.productId?.productImage} style={{ width: '70px', height: '70px', borderRadius: '12px' }} className='' alt={item?.productId?.productName} /></div>
                                <div className='mt-3'>
                                  <p className='mb-0 pb-1' style={{ fontWeight: '500', color: '#344054' }}>{item?.productId?.productName}</p>
                                  <div className='m-0 p-0 d-flex gap-2 align-items-center'>
                                    <p style={{ fontFamily: 'Outfit', color: '#1D2939', fontWeight: '500' }} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price)}</p>
                                    {item?.OldPrice && (<p className='' style={{ color: '#dfdcdc', textDecoration: 'line-through', fontFamily: 'Outfit', fontWeight: '500' }}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.OldPrice)}</p>)}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs={1} md={1}>
                              <h5 style={{ color: '#344054', fontWeight: '600' }} className='my-auto ms-2'>{initialQuantities[item.productId.id]}</h5>
                            </Col>
                            <Col xs={4} md={2}>
                            <div className="btn-group btn-group-sm gap-1" >
      <Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id, -1)}>-</Button>
      <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'20px', height:'30px', fontWeight:'600'}}>{item.quantity}</Button>
      <Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id, 1)}>+</Button>
      
      </div>
      
                            </Col>
                          </Row>
                        ))}</>)}
                  
                   {newlyAddedItems.map((item) => (
                    <Row key={item?.id} className='d-flex align-items-center'>
                      <Col xs={7} md={6}>
                        <div className='d-flex gap-3 align-items-center'>
                          <div className=''><img src={item?.productId?.productImage} style={{ width: '70px', height: '70px', borderRadius: '12px' }} className='' alt={item?.productId?.productName} /></div>
                          <div className='mt-3'>
                            <p className='mb-0 pb-1' style={{ fontWeight: '500', color: '#344054' }}>{item?.productId?.productName}</p>
                            <div className='m-0 p-0 d-flex gap-2 align-items-center'>
                              <p style={{ fontFamily: 'Outfit', color: '#1D2939', fontWeight: '500' }} className=''> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.productId?.price)}</p>
                              {item?.OldPrice && (<p className='' style={{ color: '#dfdcdc', textDecoration: 'line-through', fontFamily: 'Outfit', fontWeight: '500' }}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item?.OldPrice)}</p>)}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={1} md={1}>
                        <h5 style={{ color: '#344054', fontWeight: '600' }} className='my-auto ms-2'>{item?.quantity}</h5>
                      </Col>
                      <Col xs={4} md={2} className='d-flex align-items-center gap-2 gap-md-3'>
                      <div className="btn-group btn-group-sm gap-1" >
<Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id, -1)}>-</Button>
<Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'20px', height:'30px', fontWeight:'600'}}>{item.quantity}</Button>
<Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id, 1)}>+</Button>

</div>
<img src={trash} alt='trash' onClick={() => handleToggleItem(item.productId.id)} style={{cursor:'pointer'}}/>

                      </Col>
                    </Row>
                  ))}
                  <div className='my-5'>
                  <p style={{fontWeight:'500'}}>Add More Items</p>
                  <Col xs={12} md={7}>
                  <div onClick={togglePopover} ref={targetRef} style={{ cursor: 'pointer', border: '1px solid #ced4da', borderRadius: '8px', padding: '0.35rem 0.75rem', minHeight: '38px', gap:'5px',  }} className='d-flex align-items-center justify-content-between'>
                
   <div className='item-container'>
        {newlyAddedItems?.length > 0 ? (
          newlyAddedItems?.map((item) => (
            <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'500',fontSize:'11px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}} key={item.productId.id} onClick={(e) => {
              e.stopPropagation(); // Prevent popover from toggling
              handleToggleItem(item.productId.id);
            }}>
              {item.productId?.productName} ({item.quantity}) &nbsp; X
            </Button>
          ))
        ) : (
          <span className="text-muted">Select additional items to add to your order.</span>
        )}
        </div>
        <div className='d-flex justify-content-end'>
        <i className="bi bi-chevron-down"></i>
        </div>
                  </div>
                  </Col>
                  <Overlay
    show={isPopoverVisible}
    target={targetRef.current}
    placement="bottom"
    rootClose={true}
    onHide={hidePopover}
  >
 
                    <Popover className='custom-popover' style={{ width: '430px' }}>
                      <Popover.Body>
                        <div className='d-flex align-items-center position-relative py-1 px-1 mb-3'>
                          {searchProduct !== '' && (
                            <i className='bi bi-x' style={{ top: '5%', right: '1.5%', position: 'absolute', fontSize: '1.5rem', color: '#98A2B3' }} onClick={() => setSearchProduct('')}></i>
                          )}
                          <Form.Control
                            style={{ border: '1px solid #E1E6EF', backgroundColor: '#E1E6EF', borderRadius: '3px', fontWeight: '500' }}
                            placeholder='Search by product name'
                            value={searchProduct}
                            onChange={(e) => setSearchProduct(e.target.value)}
                            autoFocus
                          />
                        </div>
                        {loading ? (
                          <div className='justify-content-center align-items-center text-center' style={{ padding: '150px' }}>
                            <div className="spinner-border text-success" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          globalProducts.length > 0 ? (
                            <>
                              <div style={{ borderTop: '1px solid #E1E6EF' }} className='pb-2 pt-3'>
                                <h5 className='' style={{ fontWeight: '500' }}>Items</h5>
                              </div>
                              <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll', border: 'none' }} className='no-border-listgroup'>
                                {globalProducts.map((product) => (
                                  <ListGroup.Item key={product.id} action className='d-flex justify-content-between'>
                                    <Form.Check
                                      type="checkbox"
                                      label={`${product.productName} - ₦${product.price.toLocaleString()}`}
                                      checked={isProductChecked(product.id)}
                                    onChange={() => handleToggleItem(product.id)}
                                    />
                                    {isProductChecked(product?.id) && (
                                      <div className="btn-group btn-group-sm">
                                        <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }} onClick={() => handleChangeQuantity(product.id, -1)}>-</Button>
                                        <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}>{getProductQuantity(product.id)}</Button>
                                        <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}  onClick={() => handleChangeQuantity(product.id, 1)}>+</Button>
                                      </div>
                                    )}
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </>
                          ) : (
                            searchPerformed && globalProducts.length === 0 ? (
                              <div className='fw-medium ' style={{ padding: '50px 0' }}>
                                <h4 className='mb-2'>No products found</h4>
                                <Button onClick={() => setSearchProduct('')}>View all products</Button>
                              </div>
                            ) : (
                              <div className='fw-medium ' style={{ padding: '50px 0' }}>
                                <h4>You have no products</h4>
                              </div>
                            )
                          )
                        )}
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                  </div>
                </div>
                <Col xs={12} md={9} className='py-3' >
                <Button className='w-100'onClick={handlePay} disabled={!hasModifications} style={{backgroundColor: !hasModifications ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40'
}}>Pay {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</Button>
                </Col>
              </div>
            {/* )} */}
          </div>
        </Col>
      </Row>
    </div>
  </div>
);
};


export default OrderModification;


