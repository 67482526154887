import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import logo from '../../assets/new-logo.png'
import arrowLeft from '../../assets/arrow-left.svg'
import {Link, useLocation, useNavigate} from 'react-router-dom'

const RefundPolicy = () => {
 
  const navigate = useNavigate();
 
  const handleBack = () => {
      navigate(-1);
    }
  return (
 
      <div style={{ position: 'relative' }} className='py-5 py-sm-0'> 
      <Card className=' terms-card' style={{ border: 'none', borderRadius: '0px', position: 'relative', zIndex: 1 }}>
      
      <div className='pt-5 px-3 px-md-5 mx-lg-5 ' style={{zIndex:'99'}}>
      <div className='d-block d-sm-none mb-4'>
          <div className='d-flex align-items-center gap-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className=''/>
          <h3 className='my-auto' style={{fontWeight:'600',color:'#469B12'}} >RETURN AND REFUND POLICY</h3>
              
           </div>
           </div>
           <div className='d-none d-sm-flex my-3 justify-content-between align-items-center'>
        <h2 className='' style={{fontWeight:'600',color:'#469B12'}} >RETURN AND REFUND POLICY</h2>
        <Link className='' to='/' ><h6 style={{color:'#000000', fontWeight:'600', textDecoration:'underline'}}>Back to Home  </h6></Link>
        </div>
           
        <div className='mb-4'>
      <h6 className=''  style={{fontWeight:'400'}}>We appreciate you choosing Virtue's Shop for your fresh groceries! We want you to be completely satisfied with your order. However, we understand that sometimes things don't go as planned. This policy outlines how to return an item and receive a refund if needed.
</h6>
      </div>
      <div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >What can be returned?</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>We happily accept returns for most unopened, undamaged, and non-perishable items within 24 hours of delivery. Here are some things to keep in mind:
     
          <ol type='1'>
              <li className='mb-2'><b>Freshness matters: </b> Due to food safety concerns, we cannot accept returns of fresh produce, meat, seafood, and dairy products.</li>
              <li className='mb-2'><b>Opened items: </b> We can only accept returns of opened items if they are damaged or defective.</li>
              <li className='mb-2'><b>Great deals: </b> Discounted or clearance items are considered final sale.</li>

          </ol>
      
</h6>
      </div>
      <div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Easy Return Process</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'21px'}}>We want to make returns as smooth as possible. Here's what to do:

     
          <ol type='1'>
              <li className='mb-2'><b>Reach out: </b> Contact our friendly customer service team within 24 hours of receiving your order. You can reach us by email at <a className='text-decoration-underline fw-medium' href='mailto:support@virtuesshop.co'>support@virtuesshop.co</a> or by phone at <a className="text-dark text-decoration-none fw-medium" style={{cursor:'pointer'}} href="tel:+2348166641019">
            +2348166641019
          </a></li>
              <li className='mb-2'><b>Get your RAN: </b>Our team will issue a return authorization number (RAN) and provide clear instructions on how to return your item.
</li>
              <li className='mb-2'><b>Pack it up: </b> Securely pack the item in its original packaging, if possible. Write the RAN on a piece of paper and add it to your item(s).</li>
              <li className='mb-2'><b>Return it: </b> Ship the items to be returned using the address provided in the instructions. Please note that you'll be responsible for the return shipping cost.</li>

          </ol>
      
</h6>
      </div>
      <div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Refunds</h3>
      <h6 className=''  style={{fontWeight:'400'}}>Once we receive your returned item(s) and confirm it meets the criteria, we'll issue a full refund to your original payment method within 7 business days. Shipping charges will only be refunded if the return is due to our error.

</h6>
<h6 className=''  style={{fontWeight:'400'}}>We issue refunds when items ordered are not delivered within the time frame set. We aim to deliver all orders and ensure we pack each order specially, but in the rare instance when an item is missing from your order, we will contact you for a refund. This will be processed within 5-14 hours.

</h6>
      </div>
      <div className='mb-4'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Exchanges</h3>
      <h6 className=''  style={{fontWeight:'400', lineHeight:'29px'}}>Currently, we don't offer exchanges. However, if you'd like a different item, you can always return your original item(s) if it meets our return policy requirements and make a new order.
     
          
</h6>
      </div>
      <div className='mb-4 pb-5'>
      <h3 className='mb-2' style={{fontWeight:'500'}} >Still have questions?</h3>
      <h6 className='mb-3'  style={{fontWeight:'400'}}>Our customer service team is happy to help. Don't hesitate to contact them if you have any questions about our return and refund policy.

</h6>
<h6>Thank you for your understanding and for choosing Virtue’s Shop!</h6>
      </div>
      
    </div>
   
    </Card>
    </div>
   


  )
}

export default RefundPolicy
