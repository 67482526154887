import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Card,Modal } from "react-bootstrap";
import SidebarNavbar from "../../components/Navbar/SideNavbar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppContext } from '../../AppContext'
import arrowLeft from '../../assets/arrow-left.svg'
import { getParsedLocalStorageItem } from "../../components/Navbar/Navbar";
import { localStorageGetItem } from "../../App";
import info from '../../assets/info.svg'
import share from '../../assets/share.svg'
import handshake from '../../assets/handshake.svg'
import fb from '../../assets/fb-share.svg'
import twitter from '../../assets/twitter-share.svg'
import instagram from '../../assets/instagram-share.svg'
import whatsapp from '../../assets/whatsapp-share.svg'
import copy from '../../assets/copy-link.svg'
import { CloseButton, InputGroup } from "react-bootstrap/esm";
import userRoute from "../../services/userRoute";


const Refer = () => {
  const {showToast} = useAppContext();
  const [referralCode,setReferralCode] = useState()
  const [referData,setReferData] = useState({})
  const [loading, setLoading] = useState(false);
  const {getReferral}=userRoute()
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem('userToken');
  const isLoggedIn = !!userData && !!token;
const location = useLocation();
const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(()=> {
    if(isLoggedIn){
        getReferral(setLoading, showToast, setReferData)
    }

  },[])

  const handleBack = () => {
   
    navigate(-1);
  }

  const handleShare = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const shareMessage = `Hey there! Get groceries delivered to you with LOVE & CARE on Virtue's Shop! Use my code ${referData?.referralCode} at signup to get 10% off your first order. Shop now at https://app.virtuesshop.co/`;

  const handleCopy = () => {
    navigator.clipboard.writeText(referData?.referralCode);
    alert('Referral code copied to clipboard!');
  };
    
  return (
    <div>
       <div className='py-5 my-5 my-sm-0'>
       <div className='d-block d-sm-none mb-4' style={{borderBottom:'1px solid #DADADAB2'}}>
          <div className='d-flex align-items-center gap-3 px-3 mb-3' >
          <img src={arrowLeft} onClick={handleBack} className='' alt='arrow icon'/>
               <h5 style={{color:'#344054', fontWeight:'500', fontSize:'18px'}} className='my-auto'>Refer & Earn</h5>
              
           </div>
           </div>
           
         
  
    

        <div className="px-3 px-xxl-5 ms-lg-5 me-lg-2">
          <div className="d-none d-sm-flex gap-2 align-items-center mb-3">
            <Link className="" to="/">
              <p style={{ color: "#475467" }}>
                Home &nbsp;{" "}
                <i
                  className="bi bi-chevron-left"
                  style={{ color: "#1D2939" }}
                ></i>
              </p>
            </Link>

            <p className="" style={{ color: "#1D2939", fontWeight: "600" }}>
             Refer & Earn
            </p>
          </div>
          <Row>
            <Col xs={12} sm={3} className="d-none d-sm-block">
              <SidebarNavbar />
            </Col>
            <Col xs={12} sm={9} className="ps-lg-3 ps-lg-4">
              <div className="">
                <div className="mb-4">
                  <h4
                    style={{ color: "#0C0C0C", fontWeight: "700" }}
                    className="d-none d-sm-block"
                  >
                  Refer & Earn
                  </h4>
                  <div className="d-flex d-sm-none justify-content-center mb-3">
                    <img src={handshake} alt='handshake'/>
                  </div>
                  <h6 style={{ color: "#717171", fontWeight: "400" }}>
                  Share your unique Virtue's Shop referral code with your friends and family!!!
                  </h6>
                </div>
                <Card className="mb-4 d-block d-sm-none p-2" >
                  <h6
                    style={{ color: "#0C0C0C", fontWeight: "700" }}
                    className=""
                  >
                 You Get:
                  </h6>
                  <h6 style={{ color: "#717171", fontWeight: "400" }}>
                  10% off your next order after your friends & family make their first order on the application successfully. The more your code is used, the more you earn.
                  </h6>
                </Card>
                <div className="mb-4 d-none d-sm-block">
                  <h6
                    style={{ color: "#0C0C0C", fontWeight: "700" }}
                    className=""
                  >
                 You Get
                  </h6>
                  <h6 style={{ color: "#717171", fontWeight: "400" }}>
                  10% off your next order after your friends & family make their first order on the application successfully. The more your code is used, the more you earn.
                  </h6>
                </div>
                <Card className="mb-4 d-block d-sm-none p-2">
                  <h6
                    style={{ color: "#0C0C0C", fontWeight: "700" }}
                    className=""
                  >
                 Your Friends & Family Get:
                  </h6>
                  <h6 style={{ color: "#717171", fontWeight: "400" }}>
                  10% off their first order. Start sharing the LOVE NOW!!!
                  </h6>
                </Card>
                <div className="mb-4 d-none d-sm-block">
                  <h6
                    style={{ color: "#0C0C0C", fontWeight: "700" }}
                    className=""
                  >
                 Your Friends & Family Get
                  </h6>
                  <h6 style={{ color: "#717171", fontWeight: "400" }}>
                  10% off their first order. Start sharing the LOVE NOW!!!
                  </h6>
                </div>
                <div className="mb-4">
                <h6 style={{color:'#191919', fontWeight:'500'}} className="mb-0 pb- mt-2">Referral Code</h6>
                {loading ? (<div className='justify-content-center align-items-center text-center py-2' >
<div className="spinner-border text-success" role="status">
<span className="visually-hidden">Loading...</span>
</div>
  </div>):
  <> 
  <div className="d-flex gap-3 mb-4">
  <div className="position-relative col-md-7 col-10">
 
  <Form.Control value={referData?.referralCode} className="w-100" readOnly/>
  <a className="" style={{textDecoration:'underline', color:'#469B12',position:'absolute',top: "20%", right:'5%', zIndex: 100, fontWeight:'500'}} onClick={handleCopy}>Copy</a>
  </div>
   <Button variant='outline-secondary'    onClick={handleShare} className='d-flex text-center justify-content-center align-items-center' style={{borderRadius:'8px', borderColor:'#B2BCCA', backgroundColor:'#fff', width:'40px'}}><img src={share}  alt='share icon' /></Button>
   </div>
               
               
               <div className=" ">
               <h5 className="my-3" style={{textDecoration:'underline', color:'#469B12',fontWeight:'700'}}>Referral History</h5>
               <Row className="d-flex d-sm-none">
                <Col xs={6} md={3} className="mb-3">
                <p style={{color:'#5D5D5D',fontSize:"12px"}} className="mb-0 pb-0">Total Referral Discounts</p>
                    <Form.Control readOnly value={referData?.totalReferralDiscounts} className="w-100" style={{backgroundColor:'#469B1233', color:'#344054'}}/>

                </Col>
                <Col xs={6} md={3} className="mb-3">
                <p style={{color:'#5D5D5D',fontSize:"12px"}} className="mb-0 pb-0">Total Referral Sign Ups</p>
                    <Form.Control readOnly value={referData.totalReferralSignups} className="w-100" style={{backgroundColor:'#469B1233', color:'#344054'}}/>

                </Col>
                <Col xs={6} md={3} className="mb-3">
                <p style={{color:'#5D5D5D',fontSize:"12px"}} className="mb-0 pb-0">Total Referral Orders</p>
                    <Form.Control readOnly value={referData.totalReferralOrders} className="w-100" style={{backgroundColor:'#469B1233', color:'#344054'}}/>

                </Col>
                <Col xs={6} md={3} className="mb-3">
                    <p style={{color:'#5D5D5D',fontSize:"12px"}} className="mb-0 pb-0">Total Redeemed Discounts</p>
                    <Form.Control readOnly value={referData.totalRedeemedDiscount} className="w-100" style={{backgroundColor:'#469B1233', color:'#344054'}}/>

                </Col>
               </Row>
               <Row className="d-none d-sm-flex">
                <Col xs={6} md={3} className="mb-3">
                <p style={{color:'#5D5D5D',fontSize:"13px"}} className="mb-0 pb-0">TOTAL REFERRAL DISCOUNTS</p>
                    <Form.Control readOnly value={referData?.totalReferralDiscounts} className="w-100" style={{backgroundColor:'#469B1233', color:'#344054'}}/>

                </Col>
                <Col xs={6} md={3} className="mb-3">
                <p style={{color:'#5D5D5D',fontSize:"13px"}} className="mb-0 pb-0">TOTAL REFERRAL SIGN UPS</p>
                    <Form.Control readOnly value={referData.totalReferralSignups} className="w-100" style={{backgroundColor:'#469B1233', color:'#344054'}}/>

                </Col>
                <Col xs={6} md={3} className="mb-3">
                <p style={{color:'#5D5D5D',fontSize:"13px"}} className="mb-0 pb-0">TOTAL REFERRAL ORDERS</p>
                    <Form.Control readOnly value={referData.totalReferralOrders} className="w-100" style={{backgroundColor:'#469B1233', color:'#344054'}}/>

                </Col>
                <Col xs={6} md={3} className="mb-3">
                    <p style={{color:'#5D5D5D',fontSize:"13px"}} className="mb-0 pb-0">TOTAL REDEEMED DISCOUNTS</p>
                    <Form.Control readOnly value={referData.totalRedeemedDiscount} className="w-100" style={{backgroundColor:'#469B1233', color:'#344054'}}/>

                </Col>
               </Row>
               </div>
               </>}
               <div className="d-flex gap-2 mb-4">
               
    <img src={info} alt='info'/>
<h6 style={{fontWeight:'400',color:'#717171'}} className='my-auto'>There's {referData?.totalReferralDiscounts}% of referral discount(s) left to redeem. {referData?.totalReferralDiscounts > 0 ? 'You can only use 10% of this discount at a time.' : ''}</h6> 
               </div>
              </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}centered >
    
      <Modal.Body>
        <div style={{borderBottom:'1px solid #EAECF0'}} className='mb-3 d-flex justify-content-between align-items-center'>
    <h4 style={{color:'#344054', fontWeight:'600'}}>Share via</h4>
    <CloseButton onClick={handleClose}/>
        </div>
        <div className='d-flex align-items-center gap-3 gap-md-4' style={{}}>

        <div className="d-flex flex-column text-center text-align-center align-items-center justify-content-center me-2" style={{border:'0px'}}><a onClick={() => {
    navigator.clipboard.writeText(shareMessage);
    alert("Message copied! You will be redirected to Instagram where you can paste the message in your story or post.");
    window.open('https://www.instagram.com/', '_blank'); // Opens Instagram in a new tab
  }}><img src={instagram} className='share-icon'  alt='social icon'/><p style={{color:'#667085'}}>Instagram</p></a></div>

        <div className="d-flex flex-column text-center justify-content-center text-align-center align-items-center me-2" style={{border:'0px'}}> <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage)}`}target="_blank"><img src={twitter} className='share-icon'alt='social icon'/><p style={{color:'#667085'}}>Twitter</p></a></div>

        <div  className="d-flex flex-column text-center justify-content-center text-align-center align-items-center me-1" style={{border:'0px'}}><a href={`whatsapp://send?text=${encodeURIComponent(shareMessage)}`}target="_blank"><img src={whatsapp} className='share-icon' alt='social icon'/><p style={{color:'#667085'}}>WhatsApp</p> </a></div>

        
        <div className="d-flex flex-column text-center justify-content-center text-align-center align-items-center me-1" style={{border:'0px'}}><a href={`https://www.facebook.com/sharer/sharer.php?u=https://app.virtuesshop.co/&quote=${encodeURIComponent(shareMessage)}`} target="_blank" ><img src={fb} className='share-icon' alt='social icon'/><p style={{color:'#667085'}}>Facebook</p></a></div>

        <div className="d-flex flex-column text-center justify-content-center text-align-center align-items-center "> <a   href={`mailto:?subject=Virtue's Shop Referral&body=${encodeURIComponent(shareMessage)}`} target="_blank"><div className="email-container" ><i className="bi bi-envelope-fill" style={{color:'#98A2B3', fontSize:'1.3rem'}}></i></div><p style={{color:'#667085'}}className="mt-lg-1">Email</p></a></div>
        </div>
      </Modal.Body>
    </Modal>
    </div>
  );
};

export default Refer
