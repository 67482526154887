import { useState, useEffect } from 'react'
import logo from '../../assets/virtue-logo-2.png'
import { Navbar,Nav, Form, Button, Image, Offcanvas} from 'react-bootstrap'
import { useNavigate, useLocation, NavLink,useMatch, Link } from 'react-router-dom'
import search from '../../assets/search-black.svg'
import searchGrey from '../../assets/search.svg'
import love from '../../assets/love.svg'
import basket from '../../assets/basket.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from '../../AppContext'
import user from '../../assets/user.svg'
import { trackEvent } from '../../mixpanel'
import { transformPath } from '../../App'
import home from '../../assets/home-black.svg'
import homeGreen from '../../assets/home.svg'
import { getParsedLocalStorageItem } from './Navbar'
import userRoute from "../../services/userRoute";
import { localStorageGetItem } from '../../App';



const MobileNavbar =  () => {
  const {cartItems}= useAppContext()
  const { getUser, updateUser } = userRoute();
  const navigate = useNavigate()
  const userData = getParsedLocalStorageItem('userData');
  const token = localStorageGetItem
('userToken');
  const isLoggedIn = !!userData && !!token;

  const profileImage = userData?.profilePicture ;
  
  
  const [expanded, setExpanded] = useState(false); 
  const location = useLocation();
  
  const isActive = (path) => {
    return location.pathname === path;
  };


 
  const handleSelect = (eventKey, event) => {
    setExpanded(false); 
    
  };
  const calculateTotalItems = () => {
    return cartItems?.reduce((total, item) => total + item.quantity, 0);
  };
  const totalItems = calculateTotalItems()

  
  return (
    
       
       <Navbar fixed="bottom" className='d-flex d-sm-none justify-content-between px-5 py-4 bg-white' style={{ boxShadow: '0px 4px 4px 0px #00000040', zIndex: '9999' }}>
      <NavLink to="/search" className={isActive('/search') ? 'active' : ''}>
        <img src={isActive('/search') ? searchGrey : search} alt='search icon'style={{ width: '24px' }} />
      </NavLink>

      <NavLink to="/" className={isActive('/') ? 'active' : ''}>
        <img src={isActive('/') ? homeGreen : home} alt="home icon" />
      </NavLink>

      <NavLink to="/cart" className={isActive('/cart') ? 'active' : ''}>
        <i className="bi bi-cart2" style={{ color: isActive('/cart') ? '#469B12' : '#000000', fontSize: '1.5rem' }}></i>
        {totalItems > 0 && (
          <span className="position-absolute translate-middle badge rounded-circle" style={{ padding: '3px 5px', border: '1.5px solid #FFFFFF', backgroundColor: '#CE371C',  fontSize: '9px' }}>
            {totalItems}
          </span>
        )}
      </NavLink>

      {isLoggedIn && (
        <NavLink to="/dashboard" className={isActive('/dashboard') ? 'active' : ''}>
          {profileImage ? (
            <Image
              roundedCircle
              src={profileImage}
              style={{
                width: "35px",
                height: "35px", // Adjusted to match the avatar size
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                backgroundColor: isActive('/dashboard') ? '#469B12' : 'rgba(70, 155, 18, 0.4)',
                color: '#000000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: '500',
                fontSize: '12px',
              }}
            >
              {userData?.firstName ? userData.firstName[0].toUpperCase() : userData.email[0].toUpperCase()}
            </div>
          )}
        </NavLink>
      )}

      {!isLoggedIn && (
        <NavLink to="/dashboard" className={isActive('/dashboard') ? 'active' : ''}>
          <i className="bi bi-person" style={{ color: isActive('/dashboard') ? '#469B12' : '#000000', fontSize: '1.5rem' }}></i>
        </NavLink>
      )}
    </Navbar>
      
   
  )
}

export default MobileNavbar

